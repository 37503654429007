<template>
  <!-- BEGIN: Top Bar -->
  <div class="p-5" style="background-color: #332601; position: fixed; width: 100%; z-index: 51" v-if="showTemplate">
    <Dialog v-model:visible="fileDialog" :style="{ width: '1700px' }" :modal="true" :draggable="false" class="p-fluid"
      :closeOnEscape="false">
      <template #header>
        <div>
          <ul class="nav nav-tabs" role="tablist">
            <li id="images" class="nav-item flex-2" :class="{ active: tabActive == 'image' }" role="presentation">
              <button class="nav-link w-full" :class="{ active: tabActive == 'image' }" data-tw-toggle="pill"
                data-tw-target="#images" type="button" role="tab" aria-controls="images" aria-selected="false"
                @click="GetFile('image')">
                <i class="bi bi-card-image" style="margin-right: 10px"></i>
                Images
              </button>
            </li>

            <li id="item" class="nav-item flex-2" :class="{ active: tabActive == 'item' }" role="presentation">
              <button class="nav-link w-full" data-tw-toggle="pill" :class="{ active: tabActive == 'item' }"
                data-tw-target="#items" type="button" role="tab" aria-controls="items" aria-selected="false"
                @click="GetFile('item')">
                <i class="bi bi-card-image" style="margin-right: 10px"></i>
                Items
              </button>
            </li>
            <li id="item" class="nav-item flex-2" :class="{ active: tabActive == 'video' }" role="presentation">
              <button class="nav-link w-full" data-tw-toggle="pill" :class="{ active: tabActive == 'video' }"
                data-tw-target="#videos" type="button" role="tab" aria-controls="videos" aria-selected="false"
                @click="GetFile('video')">
                <i class="bi bi-card-image" style="margin-right: 10px"></i>
                Videos
              </button>
            </li>
            <li id="Upload" class="nav-item flex-2" style="margin-top: 1px" role="presentation"
              :class="{ active: tabActive == 'upload' }">
              <FileUpload id="file_name" :customUpload="true" :multiple="true" :showCancelButton="false"
                :showUploadButton="false" chooseLabel="Upload" @uploader="UploadImage" :auto="true" mode="basic"
                accept="image/*,.mp4,.mov,.webm">
              </FileUpload>
            </li>
          </ul>
        </div>
      </template>
      <div class="intro-y grid grid-cols-12 gap-3 sm:gap-6 p-5" v-if="tabActive != 'upload'">
        <div class="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2" v-for="file in files" :key="file.id">
          <div class="
              file
              box
              rounded-md
              px-5
              pt-8
              pb-5
              px-3
              sm:px-5
              relative
              zoom-in
              image
            ">
            <a href="javascript:;" class="w-3/5 file__icon file__icon--image mx-auto" @click="SetFile(file, $event)">
              <div class="file__icon--image__preview image-fit">
                <img v-if="file.type == 'image'" alt="Midone - HTML Admin Template" :src="link + file.file_name" />
                <video autoplay="false" muted="true" loop="false" v-else-if="file.type == 'video'">
                  <source :src="link + file.file_name" />
                </video>
              </div>
            </a>
            <a href="javascript:;" class="block font-medium mt-4 text-center truncate name">{{ file.title }}</a>
            <div class="text-slate-500 text-xs text-center mt-0.5"></div>
            <div class="absolute top-0 right-0 mr-2 mt-3 ml-auto">
              <a href="javascript:;" @click="DeleteFile(file.id)" v-if="file.state == null">
                <i class="w-4 h-4 bi bi-trash3-fill"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
    <Dialog v-model:visible="introDialog" :style="{ width: '1700px' }" :modal="true" :draggable="false" class="p-fluid"
      :closeOnEscape="false">

      <template #header>
        <div>
          <ul class="nav nav-tabs" role="tablist">
            <li id="images" class="nav-item flex-2" :class="{ active: tabActive == 'image' }" role="presentation">
              <button class="nav-link w-full" :class="{ active: tabActive == 'image' }" data-tw-toggle="pill"
                data-tw-target="#images" type="button" role="tab" aria-controls="images" aria-selected="false"
                @click="GetFile('image')">
                <i class="bi bi-card-image" style="margin-right: 10px"></i>
                Images
              </button>
            </li>

            <!-- <li id="item" class="nav-item flex-2" :class="{ active: tabActive == 'video' }" role="presentation">
              <button class="nav-link w-full" data-tw-toggle="pill" :class="{ active: tabActive == 'video' }"
                data-tw-target="#videos" type="button" role="tab" aria-controls="videos" aria-selected="false"
                @click="GetFile('video')">
                <i class="bi bi-card-image" style="margin-right: 10px"></i>
                Videos
              </button>
            </li> -->
            <li id="Upload" class="nav-item flex-2" style="margin-top: 1px" role="presentation"
              :class="{ active: tabActive == 'upload' }">
              <FileUpload id="file_name" :customUpload="true" :multiple="true" :showCancelButton="false"
                :showUploadButton="false" chooseLabel="Upload" @uploader="UploadImage" :auto="true" mode="basic"
                accept="image/*,.mp4,.mov,.webm">
              </FileUpload>
            </li>
          </ul>
        </div>
      </template>
      <div class="intro-y grid grid-cols-12 gap-3 sm:gap-6 p-5" v-if="tabActive != 'upload'">
        <div class="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2" v-for="file in files" :key="file.id">
          <div class="
              file
              box
              rounded-md
              px-5
              pt-8
              pb-5
              px-3
              sm:px-5
              relative
              zoom-in
              image
            ">
            <a href="javascript:;" class="w-3/5 file__icon file__icon--image mx-auto"
              @click="SetIntroFile(file, $event)">
              <div class="file__icon--image__preview image-fit">
                <img v-if="file.type == 'image'" alt="Midone - HTML Admin Template" :src="link + file.file_name" />
                <video autoplay="false" muted="true" loop="false" v-else-if="file.type == 'video'">
                  <source :src="link + file.file_name" />
                </video>
              </div>
            </a>
            <a href="javascript:;" class="block font-medium mt-4 text-center truncate name">{{ file.title }}</a>
            <div class="text-slate-500 text-xs text-center mt-0.5"></div>
            <div class="absolute top-0 right-0 mr-2 mt-3 ml-auto">
              <a href="javascript:;" @click="DeleteFile(file.id)" v-if="file.state == null">
                <i class="w-4 h-4 bi bi-trash3-fill"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
    <div class="h-full flex items-center">
      <!-- BEGIN: Logo -->
      <div class="logo intro-x hidden md:flex xl:w-[180px] block">

        <img alt="Midone - HTML Admin Template" class="cursor-pointer" style="width:1.5rem"
          src="../assets/../assets/dist/images/bars-solid.svg" @click="ToggleSideBar" />
        <span class="logo__text text-white text-lg ml-3">
          <input type="text" v-model="layerName" style="
              background: transparent;
              border: none;
              border-bottom: 1px solid white;width: 9rem;
            " placeholder="Overlay Name" />
        </span>
      </div>
      <!-- END: Logo -->
      <!-- BEGIN: Breadcrumb -->
      <nav aria-label="breadcrumb" class="-intro-x h-[45px] mr-auto m">
      </nav>
      <div class="field-checkbox">
        <Checkbox inputId="binary" v-model="focus" :binary="true" />
        <label style="margin-right: 5px; padding: 5px; color: white;">Live</label>
      </div>
      <div class="intro-x" style="margin-right:10px" v-if="focus == false">
        <Dropdown v-model="overlayType" :options="OverlayList" optionLabel="title" optionValue="id" dataKey="value"
          aria-labelledby="custom" :unselectable="false">
        </Dropdown>
        <!-- <SelectButton v-model="overlayType" :options="OverlayList" optionLabel="title" optionValue="id" dataKey="value"
          aria-labelledby="custom" :unselectable="false">
        </SelectButton> -->
      </div>
      <!-- END: Breadcrumb -->
      <!-- BEGIN: Account Menu -->
      <div class="intro-x">
        <!-- <button class="btn btn-warning mr-1" v-if="overlayType == 4 && focus == true" @click="BtnPick('l1')">
          L1
        </button>
        <button class="btn btn-warning mr-1" v-if="overlayType == 4 && focus == true" @click="BtnPick('l2')">
          L2
        </button>
        <button class="btn btn-warning mr-1" v-if="overlayType == 4 && focus == true" @click="BtnPick('l3')">
          L3
        </button>
        <button class="btn btn-warning mr-1" v-if="overlayType == 4 && focus == true" @click="BtnPick('r1')">
          R1
        </button>
        <button class="btn btn-warning mr-1" v-if="overlayType == 4 && focus == true" @click="BtnPick('r2')">
          R2
        </button>
        <button class="btn btn-warning mr-1" v-if="overlayType == 4 && focus == true" @click="BtnPick('r3')">
          R3
        </button>
        <button class="btn btn-warning mr-1" v-if="overlayType == 4 && focus == true" @click="BtnPick('r4')">
          R4
        </button>-->
        <button class="btn btn-warning mr-1" v-if="overlayType == 4 && focus == true" @click="BtnPick('pl')">
          Pick-Left
        </button>
        <button class="btn btn-warning mr-1" v-if="overlayType == 4 && focus == true" @click="BtnPick('pr')">
          Pick-Right
        </button>
        <button class="btn btn-warning mr-1" v-if="overlayType == 4 && focus == true" @click="BtnPick('bl')">
          Ban-Left
        </button>
        <button class="btn btn-warning mr-1" v-if="overlayType == 4 && focus == true" @click="BtnPick('br')">
          Ban-Right
        </button>
        <button class="btn btn-warning mr-1" v-if="overlayType == 4 && focus == true" @click="BtnPick('ad')">
          Adjust
        </button>
        <!-- <button class="btn btn-warning mr-1" v-if="overlayType == 4 && focus == true" @click="BtnPick('cd')">
          Countdown
        </button> -->
        <button class="btn btn-warning mr-5" v-if="overlayType == 4 && focus == true" @click="BtnPick('ha')">
          Hide All
        </button>
        <button class="btn btn-warning mr-1" v-if="overlayType == 1 && focus == true" @click="BtnGold(0)">
          Gold
        </button>
        <button class="btn btn-warning mr-5" v-if="overlayType == 1 && focus == true" @click="BtnGold(1)">
          Up Gold
        </button>
        <button class="btn btn-warning mr-3" v-if="overlayType == 1 && focus == false" @click="ClearItem">
          Clear
        </button>
        <button class="btn btn-success mr-1" style="width: 80px" @click="AutoUpdateCompApi(layerName)"
          v-if="overlayType == 2 || overlayType == 3 || overlayType == 5 && getApi == false">
          Get
        </button>
        <button class="btn btn-success mr-1" style="width: 80px" @click="StopApiData"
          v-if="overlayType == 2 || overlayType == 3 || overlayType == 5 && getApi == true">
          Stop
        </button>
        <button class="btn btn-success mr-1" style="width: 90px" @click="Separate()" v-if="focus == false">
          Copy Link
        </button>
        <button class="btn btn-success mr-1" style="width: 80px" @click="Save('show')"
          v-if="status == 0 && focus == false">
          Show
        </button>
        <button class="btn btn-success mr-1" style="width: 80px" @click="Save('hide')"
          v-if="status == 1 && focus == false">
          Hide
        </button>
        <button class="btn btn-success mr-1" style="width: 80px" @click="Save('save')" :disabled="disSave">
          Save
        </button>
      </div>
      <!-- END: Account Menu -->
    </div>
  </div>
  <!-- <sidebar-com  :showAll="showAll" :inputs="inputs" :showSidebar="showSidebar" :showUp="showUp" :showDown="showDown"
    :showGroup="showGroup" :showUngroup="showUngroup" :showCopy="showCopy" :showDelete="showDelete" :focus="focus"
    :overlay="overlay" :link="link" :imageList="imageList" :imageHeadSquareList="imageHeadSquareList"
    :imageHeadWideList="imageHeadWideList" :imageHeadTallList="imageHeadTallList" :imageHead3x2List="imageHead3x2List"
    :imageItemList="imageItemList" :otherList="otherList" :heroTypeList="heroTypeList" :heroLinkList="heroLinkList"
    :status="status"></sidebar-com> -->
  <div style="width: 400px; height: 100%; position: fixed; user-select: none;" class="side-nav" v-if="showSidebar">
    <ul>
      <li>
        <div class="side-menu side-menu--active" id="layers" @click="Toggle('layers')">
          <div class="side-menu__icon"><i class="bi bi-stack"></i></div>
          <div class="side-menu__title">
            Layers

            <div class="side-menu__sub-icon transform rotate-180">
              <i class="bi-chevron-down"></i>
            </div>
          </div>
        </div>

        <ul class="side-menu__sub-open">
          <li>
            <div style="
                height: 38px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
              ">
              <div>
                <button class="" @click="showAll = false" v-tooltip.bottom="'Show Less'" v-if="showAll == true">
                  <i class="bi bi-funnel" style="font-size: 1.5rem; padding: 5px"></i>
                </button>
                <button class="" @click="showAll = true" v-tooltip.bottom="'Show All'" v-else>
                  <i class="bi bi-funnel-fill" style="font-size: 1.5rem; padding: 5px"></i>
                </button>
                <button class="" @click="LockAll" v-tooltip.bottom="'Lock All'">
                  <i class="bi bi-lock-fill" style="font-size: 1.5rem; padding: 5px"></i>
                </button>
                <button class="" @click="UpCom" v-tooltip.bottom="'Move Up'" :disabled="showUp == false"
                  v-if="focus == false">
                  <i class="bi bi-arrow-up-circle" style="font-size: 1.5rem; padding: 5px"></i>
                </button>
                <button class="" @click="DownCom" v-tooltip.bottom="'Move Down'" :disabled="showDown == false"
                  v-if="focus == false">
                  <i class="bi bi-arrow-down-circle" style="font-size: 1.5rem; padding: 5px"></i>
                </button>
                <button class="" @click="copyCom" v-tooltip.bottom="'Duplicate'" :disabled="showCopy == false"
                  v-if="focus == false">
                  <i class="bi bi-clipboard2" style="font-size: 1.5rem; padding: 5px"></i></button>
                <button class="" @click="deleteCom" v-tooltip.bottom="'Delete'" :disabled="showDelete == false"
                  v-if="focus == false">
                  <i class="bi bi-trash" style="font-size: 1.5rem; padding: 5px"></i>
                </button>
              </div>
            </div>
          </li>
          <li v-if="showAll == true">
            <div v-for="element in inputs" :key="element.id">
          <li :key="element.id" v-if="element.type != 'group'">
            <div class="side-menu" :class="{ 'side-menu--active': element.active }" :id="element.id"
              @click.prevent="clicked(element.id, $event, element.group, '', '')">
              <div class="side-menu__icon">
                <!-- to="#" -->
                <i class="bi bi-type" v-if="element.type == 'text'"></i>
                <i class="pi pi-image" v-else-if="element.type == 'image'"></i>
                <i class="pi pi-video" v-else-if="element.type == 'video'"></i>
                <i class="pi pi-clock" v-else-if="element.type == 'counter'"></i>
                <i class="pi pi-chart-bar" v-else-if="element.type == 'graph'"></i>
              </div>
              <div class="side-menu__title" :class="{ line: element.hide }">
                <p @dblclick="ChangeTitle($event, element, 'main')">
                  {{ element.title }}
                </p>
              </div>
              <button style="margin-right: 20px" @click="element.filter = !element.filter">
                <i class="bi bi-funnel" v-if="element.filter == false" v-tooltip.bottom="'Show'">
                </i>
                <i class="bi bi-funnel-fill" v-else v-tooltip.bottom="'Hide'"></i>
              </button>
              <button style="margin-right: 20px" @click="Grid(element.id, '')">
                <i class="bi bi-grid" v-if="element.grid == false" v-tooltip.bottom="'Snapping on'">
                </i>
                <i class="bi bi-grid-fill" v-else v-tooltip.bottom="'Snapping off'"></i>
              </button>
              <button style="margin-right: 15px" @click="Hide(element.id, '')">
                <i class="bi bi-eye-fill" v-if="element.hide == false" v-tooltip.bottom="'Hide'">
                </i>
                <i class="bi bi-eye-slash-fill" v-else v-tooltip.bottom="'Show'"></i>
              </button>
              <button style="margin-right: 20px" @click="Lock(element.id, '')">
                <i class="bi bi-lock-fill" v-if="element.state == false && status == 0"
                  v-tooltip.bottom="'Unlock Layer'">
                </i>
                <i class="bi bi-unlock-fill" v-else-if="element.state == true && status == 0"
                  v-tooltip.bottom="'Lock Layer'"></i>
              </button>
            </div>
          </li>
          <li v-else>
            <div class="side-menu" :id="element.id" :class="{ 'side-menu--active': element.active }" @click.prevent>
              <div class="side-menu__icon" @click.prevent="clicked(element.id, $event, element.id, '', '')">
                <i class="bi bi-folder" style="font-size: 1.2rem" v-if="element.type == 'group'"></i>
              </div>
              <div class="side-menu__title" :class="{ line: element.hide }">
                <p @dblclick="ChangeTitle($event, element, 'main')">
                  {{ element.title }}
                </p>
                <div class="side-menu__sub-icon transform rotate-180">
                  <i class="bi-chevron-up bi" @click="Toggle(element.id)"></i>
                </div>
                <button style="margin-right: 20px" @click="element.filter = !element.filter">
                  <i class="bi bi-funnel" v-if="element.filter == false" v-tooltip.bottom="'Show'">
                  </i>
                  <i class="bi bi-funnel-fill" v-else v-tooltip.bottom="'Hide'"></i>
                </button>
                <button style="margin-right: 15px" @click="Hide(element.id, element.id)">
                  <i class="bi bi-eye-fill" v-if="element.hide == false" v-tooltip.bottom="'Hide'">
                  </i>
                  <i class="bi bi-eye-slash-fill" v-else v-tooltip.bottom="'Show'"></i>
                </button>
                <button style="margin-right: 20px" @click="Lock(element.id, element.id)">
                  <i class="bi bi-lock-fill" v-if="element.state == false" v-tooltip.bottom="'Unlock Layer'">
                  </i>
                  <i class="bi bi-unlock-fill" v-else v-tooltip.bottom="'Lock Layer'"></i>
                </button>
              </div>
            </div>
            <ul>
              <li style="margin-left: 30px" v-for="el in element.element" :key="el['id']">
                <div class="side-menu" :class="{ 'side-menu--active': el['active'] }" :id="el['id']"
                  @click.prevent="clicked(el['id'], $event, el['group'], '', '')">
                  <div class="side-menu__icon">
                    <i class="bi bi-type" v-if="el['type'] == 'text'"></i>
                    <i class="pi pi-image" v-else-if="el['type'] == 'image'"></i>
                    <i class="pi pi-video" v-else-if="el['type'] == 'video'"></i>
                    <i class="pi pi-clock" v-else-if="el['type'] == 'counter'"></i>
                  </div>
                  <div class="side-menu__title" :class="{ line: el['hide'] }">
                    <p @dblclick="ChangeTitle($event, el, 'child')">
                      {{ el["title"] }}
                    </p>
                  </div>
                  <button style="margin-right: 20px" @click="Grid(el['id'], element.id)">
                    <i class="bi bi-grid" v-if="el['grid'] == false" v-tooltip.bottom="'Snapping on'">
                    </i>
                    <i class="bi bi-grid-fill" v-else v-tooltip.bottom="'Snapping off'"></i>
                  </button>
                  <button style="margin-right: 15px" @click="Hide(el['id'], element.id)">
                    <i class="bi bi-eye-fill" v-if="el['hide'] == false" v-tooltip.bottom="'Hide'">
                    </i>
                    <i class="bi bi-eye-slash-fill" v-else v-tooltip.bottom="'Show'"></i>
                  </button>
                  <button style="margin-right: 20px" @click="Lock(el['id'], element.id)">
                    <i class="bi bi-lock-fill" v-if="el['state'] == false" v-tooltip.bottom="'Unlock Layer'">
                    </i>
                    <i class="bi bi-unlock-fill" v-else v-tooltip.bottom="'Lock Layer'"></i>
                  </button>
                </div>
              </li>
            </ul>
          </li>
  </div>
  </li>
  <li v-else>
    <div v-for="element in inputs" :key="element.id">
  <li :key="element.id" v-if="element.type != 'group' && element.filter == true">
    <div class="side-menu" :class="{ 'side-menu--active': element.active }" :id="element.id"
      @click.prevent="clicked(element.id, $event, element.group, '', '')">
      <div class="side-menu__icon">
        <i class="bi bi-type" v-if="element.type == 'text'"></i>
        <i class="pi pi-image" v-else-if="element.type == 'image'"></i>
        <i class="pi pi-video" v-else-if="element.type == 'video'"></i>
        <i class="pi pi-clock" v-else-if="element.type == 'counter'"></i>
      </div>
      <div class="side-menu__title" :class="{ line: element.hide }">
        <p @dblclick="ChangeTitle($event, element, 'main')">
          {{ element.title }}
        </p>
      </div>
      <button style="margin-right: 20px" @click="element.filter = !element.filter">
        <i class="bi bi-funnel" v-if="element.filter == false" v-tooltip.bottom="'Show'">
        </i>
        <i class="bi bi-funnel-fill" v-else v-tooltip.bottom="'Hide'"></i>
      </button>
      <button style="margin-right: 20px" @click="Grid(element.id, '')">
        <i class="bi bi-grid" v-if="element.grid == false" v-tooltip.bottom="'Snapping on'">
        </i>
        <i class="bi bi-grid-fill" v-else v-tooltip.bottom="'Snapping off'"></i>
      </button>
      <button style="margin-right: 15px" @click="Hide(element.id, '')">
        <i class="bi bi-eye-fill" v-if="element.hide == false" v-tooltip.bottom="'Hide'">
        </i>
        <i class="bi bi-eye-slash-fill" v-else v-tooltip.bottom="'Show'"></i>
      </button>
      <button style="margin-right: 20px" @click="Lock(element.id, '')">
        <i class="bi bi-lock-fill" v-if="element.state == false" v-tooltip.bottom="'Unlock Layer'">
        </i>
        <i class="bi bi-unlock-fill" v-else v-tooltip.bottom="'Lock Layer'"></i>
      </button>
    </div>
  </li>
  <li v-else-if="element.filter == true">
    <div class="side-menu" :id="element.id" :class="{ 'side-menu--active': element.active }" @click.prevent>
      <div class="side-menu__icon" @click.prevent="clicked(element.id, $event, element.id, '', '')">
        <i class="bi bi-folder" style="font-size: 1.2rem" v-if="element.type == 'group'"></i>
      </div>
      <div class="side-menu__title" :class="{ line: element.hide }">
        <p @dblclick="ChangeTitle($event, element, 'main')">
          {{ element.title }}
        </p>
        <div class="side-menu__sub-icon transform rotate-180">
          <i class="bi-chevron-up bi" @click="Toggle(element.id)"></i>
        </div>
        <button style="margin-right: 15px" @click="element.filter = !element.filter">
          <i class="bi bi-funnel" v-if="element.filter == false" v-tooltip.bottom="'Show'">
          </i>
          <i class="bi bi-funnel-fill" v-else v-tooltip.bottom="'Hide'"></i>
        </button>
        <button style="margin-right: 15px" @click="Hide(element.id, element.id)">
          <i class="bi bi-eye-fill" v-if="element.hide == false" v-tooltip.bottom="'Hide'">
          </i>
          <i class="bi bi-eye-slash-fill" v-else v-tooltip.bottom="'Show'"></i>
        </button>
        <button style="margin-right: 20px" @click="Lock(element.id, element.id)">
          <i class="bi bi-lock-fill" v-if="element.state == false" v-tooltip.bottom="'Unlock Layer'">
          </i>
          <i class="bi bi-unlock-fill" v-else v-tooltip.bottom="'Lock Layer'"></i>
        </button>
      </div>
    </div>
    <ul>
      <li style="margin-left: 30px" v-for="el in element.element" :key="el['id']">
        <div class="side-menu" :class="{ 'side-menu--active': el['active'] }" :id="el['id']"
          @click.prevent="clicked(el['id'], $event, el['group'], '', '')">
          <div class="side-menu__icon">
            <i class="bi bi-type" v-if="el['type'] == 'text'"></i>
            <i class="pi pi-image" v-else-if="el['type'] == 'image'"></i>
            <i class="pi pi-video" v-else-if="el['type'] == 'video'"></i>
            <i class="pi pi-clock" v-else-if="el['type'] == 'counter'"></i>
          </div>
          <div class="side-menu__title" :class="{ line: el['hide'] }">
            <p @dblclick="ChangeTitle($event, el, 'child')">
              {{ el["title"] }}
            </p>
          </div>
          <button style="margin-right: 20px" @click="Grid(el['id'], element.id)">
            <i class="bi bi-grid" v-if="el['grid'] == false" v-tooltip.bottom="'Snapping on'">
            </i>
            <i class="bi bi-grid-fill" v-else v-tooltip.bottom="'Snapping off'"></i>
          </button>
          <button style="margin-right: 15px" @click="Hide(el['id'], element.id)">
            <i class="bi bi-eye-fill" v-if="el['hide'] == false" v-tooltip.bottom="'Hide'">
            </i>
            <i class="bi bi-eye-slash-fill" v-else v-tooltip.bottom="'Show'"></i>
          </button>
          <button style="margin-right: 20px" @click="Lock(el['id'], element.id)">
            <i class="bi bi-lock-fill" v-if="el['state'] == false" v-tooltip.bottom="'Unlock Layer'">
            </i>
            <i class="bi bi-unlock-fill" v-else v-tooltip.bottom="'Lock Layer'"></i>
          </button>
        </div>
      </li>
    </ul>
  </li>
  </div>
  </li>
  </ul>
  </li>

  <li v-if="overlay != ''">
    <div id="settings" class="side-menu" @click="Toggle('settings')">
      <div class="side-menu__icon"><i class="bi bi-gear-fill"></i></div>
      <div class="side-menu__title">
        Settings
        <div class="side-menu__sub-icon transform rotate-180">
          <i class="bi-chevron-up bi"></i>
        </div>
      </div>
    </div>

    <ul>
      <li style="padding: 20px" v-if="overlay.type == 'text'">
        <span style="margin-left: 10px">
          <label for="inputtext">Message</label>
          <Textarea id="textarea" v-model="overlay.source" rows="3" style="
                  width: 100%;
                  background: transparent;
                  border: none;
                  border-bottom: 1px solid black;
                " @keyup="ChangeText(overlay.id)" />
        </span>
      </li>
      <li v-else-if="overlay.type == 'image'" style="padding: 10px">
        <div style="padding: 10px; width: 100%">
          <h1 style="margin-bottom: 10px">Image Type</h1>
          <SelectButton v-model="overlay.style.type" :options="heroTypeList" aria-labelledby="single"
            optionLabel="title" optionValue="id" :unselectable="false" @change="ChangeImageType(overlay.id, $event)">
          </SelectButton>
        </div>
        <div style="display: flex; margin-bottom: 10px">
          <div style="flex: 100%; padding: 5px">
            <Dropdown v-if="overlay.style.type == 0 && focus == false" v-model="overlay.source" :options="imageList"
              optionLabel="title" optionValue="file_name" placeholder="Select a image" :filter="true"
              style="width: 100%" :autoFilterFocus="true" :resetFilterOnHide="true"
              @change="ChangeImage(overlay.id, $event)" ref="dropdown">
            </Dropdown>
            <Dropdown v-else-if="overlay.style.type == 1 && focus == false" v-model="overlay.source"
              :options="imageHeadSquareList" optionLabel="title" optionValue="file_name" placeholder="Select a image"
              :filter="true" style="width: 100%" :autoFilterFocus="true" :resetFilterOnHide="true"
              @change="ChangeImage(overlay.id, $event)" ref="dropdown">
            </Dropdown>
            <Dropdown v-else-if="overlay.style.type == 2 && focus == false" v-model="overlay.source"
              :options="imageHeadWideList" optionLabel="title" optionValue="file_name" placeholder="Select a image"
              :filter="true" style="width: 100%" :autoFilterFocus="true" :resetFilterOnHide="true"
              @change="ChangeImage(overlay.id, $event)" ref="dropdown">
            </Dropdown>
            <Dropdown v-else-if="overlay.style.type == 3 && focus == false" v-model="overlay.source"
              :options="imageHeadTallList" optionLabel="title" optionValue="file_name" placeholder="Select a image"
              :filter="true" style="width: 100%" :autoFilterFocus="true" :resetFilterOnHide="true"
              @change="ChangeImage(overlay.id, $event)" ref="dropdown">
            </Dropdown>
            <Dropdown v-else-if="overlay.style.type == 4 && focus == false" v-model="overlay.source"
              :options="imageHead3x2List" optionLabel="title" optionValue="file_name" placeholder="Select a image"
              :filter="true" style="width: 100%" :autoFilterFocus="true" :resetFilterOnHide="true"
              @change="ChangeImage(overlay.id, $event)" ref="dropdown">
            </Dropdown>
            <Dropdown v-else-if="overlay.style.type == 5 && focus == false" v-model="overlay.source"
              :options="imageItemList" optionLabel="title" optionValue="file_name" placeholder="Select a image"
              :filter="true" style="width: 100%" :autoFilterFocus="true" :resetFilterOnHide="true"
              @change="ChangeImage(overlay.id, $event)" ref="dropdown">
            </Dropdown>
            <Dropdown v-else v-show="focus == false" v-model="overlay.source" :options="otherList" optionLabel="title"
              optionValue="file_name" placeholder="Select a image" :filter="true" style="width: 100%"
              :autoFilterFocus="true" :resetFilterOnHide="true" @change="ChangeImage(overlay.id, $event)"
              ref="dropdown">
            </Dropdown>
          </div>
        </div>
        <div style="display: flex; height: 100px; overflow: hidden;margin-bottom: 10px">
          <div style="flex: 50%; padding: 5px">
            <img :src="link + overlay.source" alt="" v-if="overlay.source != '' && overlay.source != null" style="" />
            <img src="/default-image.jpg" alt="" style="border-radius: 5px" v-else />
          </div>
          <div style="flex: 50%; padding: 5px">
            <button class="btn btn-primary" @click="ShowDialog('image')">
              <span>Set Image</span>
            </button>
            <button class="btn btn-secondary" @click="ClearImage(overlay.id);">
              <span>Clear</span>
            </button>
          </div>
        </div>
        <div class="field-checkbox" style="flex: 50%; padding: 5px">
          <Checkbox inputId="binary" v-model="overlay.style.swap" :binary="true"
            @input="ChangeSwap(overlay.id, $event)" />
          <label style="margin-left: 5px; padding: 5px;">Swappable</label>

        </div>
        <!-- <div style="flex: 50%; padding: 5px">
          <Dropdown v-model="overlay.link" :options="heroLinkList" :showClear="true" optionLabel="title"
            optionValue="id" placeholder="Select a link" @change="DisabledLink(overlay.link)">
          </Dropdown>
        </div> -->
      </li>
      <li v-else-if="overlay.type == 'video'">
        <div style="display: flex; height: 100px; overflow: hidden">
          <div style="flex: 50%; padding: 5px">
            <video autoplay="true" :muted="overlay.muted" :loop="overlay.loop" v-if="overlay.source != ''"
              style="border-radius: 5px">
              <source :src="link + overlay.source" />
            </video>
            <img src="/default-image.jpg" style="border-radius: 5px" alt="" v-else />
            <button style="position: absolute; top: 10px; right: 53%">
              <i class="bi bi-x-lg"></i>
            </button>
          </div>
          <div style="flex: 50%; padding: 5px">
            <button class="btn" @click="ShowDialog('video')">
              <i class="bi bi-camera-video" style="margin-right: 5px"></i><span>Set Video</span>
            </button>
          </div>
        </div>
        <div style="
                display: flex;
                height: 100px;
                overflow: hidden;
                align-items: center;
                justify-content: center;
              ">
          <div class="field-checkbox" style="flex: 50%; padding: 5px">
            <Checkbox inputId="binary" v-model="overlay.muted" :binary="true" @input="ChangeCheckbox('mute')" />
            <label style="margin-left: 10px; padding: 5px">Mute the audio</label>
          </div>
          <div class="field-checkbox" style="flex: 50%; padding: 5px">
            <Checkbox inputId="binary" v-model="overlay.loop" :binary="true" @input="ChangeCheckbox('loop')" />
            <label style="margin-left: 10px; padding: 5px">Loop</label>
          </div>
        </div>
      </li>
      <li style="padding: 10px" v-else-if="overlay.type == 'counter'">
        <span style="margin-left: 10px">
          <div style="display: flex" v-if="overlay.clock == false">
            <!-- <div style="flex: 25%">
              <label for="input">Days</label>
              <input type="number" style="
                      width: 100%;
                      background: transparent;
                      border: none;
                      border-bottom: 1px solid black;
                    " v-model="overlay.days" @keyup="
                      ChangeTimer($event.target.value, 'days', overlay.id)
                      " min="0" oninput="this.value = 
 !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" />
            </div> -->
            <div style="flex: 25%">
              <label for="input">Hours</label>
              <input type="number" style="
                      width: 100%;
                      background: transparent;
                      border: none;
                      border-bottom: 1px solid black;
                    " v-model="overlay.hours" min="0" @keyup="
                      ChangeTimer($event.target.value, 'hours', overlay.id)
                      " oninput="this.value = 
 !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" />
            </div>
            <div style="flex: 25%">
              <label for="input">Minutes</label>
              <input type="number" style="
                      width: 100%;
                      background: transparent;
                      border: none;
                      border-bottom: 1px solid black;
                    " min="0" v-model="overlay.minutes" @keyup="
                      ChangeTimer($event.target.value, 'minutes', overlay.id)
                      " oninput="this.value = 
 !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" />
            </div>
            <div style="flex: 25%">
              <label for="input">Seconds</label>
              <input type="number" style="
                      width: 100%;
                      background: transparent;
                      border: none;
                      border-bottom: 1px solid black;
                    " v-model="overlay.seconds" min="0" @keyup="
                      ChangeTimer($event.target.value, 'seconds', overlay.id)
                      " oninput="this.value = 
 !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" />
            </div>
          </div>
          <div style="margin-top: 20px;margin-bottom: 10px;" v-if="focus == false">
            <label for="input">Message</label>
            <input id="inputtext" type="text" v-model="overlay.message" rows="3" style="
                    width: 100%;
                    background: transparent;
                    border: none;
                    border-bottom: 1px solid black;
                  " @keyup="ChangeMessage(overlay.id)" />
          </div>
          <div class="field-checkbox" style="flex: 50%; padding: 5px">
            <Checkbox inputId="binary" v-model="overlay.clock" :binary="true" @input="ChangeClock" />
            <label style="margin-left: 10px; padding: 5px">Clock</label>
          </div>
          <div class="field-checkbox" style="flex: 50%; padding: 5px" v-if="overlay.clock == true">
            <button class="btn btn-primary mr-2" :disabled="overlay.op == 'start' || overlay.op == ''"
              @click="StartClock(overlay.id)">start</button>
            <button class="btn btn-danger mr-2" :disabled="overlay.op == 'stop' || overlay.op == 'reset'"
              @click="StopClock(overlay.id)">stop</button>
            <button class="btn btn-success mr-2" :disabled="overlay.op == 'reset'"
              @click="ResetClock(overlay.id)">reset</button>
          </div>
        </span>
      </li>
      <li v-else-if="overlay.type == 'graph'" style="padding: 20px">
        <div>
          <h1 style="margin-bottom: 10px">
            Percent: {{ overlay.style.percent }}%
          </h1>
          <Slider v-model="overlay.style.percent" :min="0" :max="100" @change="ChangePercent(overlay.id)" />
        </div>
      </li>
    </ul>
  </li>
  <li v-if="overlay != ''">
    <div class="side-menu" id="positions" @click="Toggle('positions')">
      <div class="side-menu__icon">
        <i class="bi bi-border-style"></i>
      </div>
      <div class="side-menu__title">
        Position, size and style
        <div class="side-menu__sub-icon transform rotate-180">
          <i class="bi-chevron-up bi"></i>
        </div>
      </div>
    </div>
    <ul>
      <li style="padding: 10px">
        <div style="display: flex; margin-bottom: 20px">
          <div style="flex: 50%; padding: 5px">
            <div>
              <label>Width</label>
              <input type="number" v-model="w" style="
                      width: 100%;
                      background: transparent;
                      border: none;
                      border-bottom: 1px solid black;
                    " class="inputs" @keyup="ChangePos(overlay.id, 'w')" />
            </div>
          </div>
          <div style="flex: 50%; padding: 5px">
            <div>
              <label>Height</label>
              <input type="number" v-model="h" style="
                      width: 100%;
                      background: transparent;
                      border: none;
                      border-bottom: 1px solid black;
                    " class="inputs" @keyup="ChangePos(overlay.id, 'h')" />
            </div>
          </div>
        </div>
        <div style="display: flex; margin-bottom: 10px" v-if="status == 0">
          <div style="flex: 50%; padding: 5px">
            <div>
              <label>Left</label>
              <input type="number" v-model="x" style="
                      width: 100%;
                      background: transparent;
                      border: none;
                      border-bottom: 1px solid black;
                    " class="inputs" @keyup="ChangePos(overlay.id, 'x')" />
            </div>
          </div>
          <div style="flex: 50%; padding: 5px" v-if="status == 0">
            <div>
              <label>Top</label>
              <input type="number" v-model="y" style="
                      width: 100%;
                      background: transparent;
                      border: none;
                      border-bottom: 1px solid black;
                    " class="inputs" @keyup="ChangePos(overlay.id, 'y')" />
            </div>
          </div>
        </div>
        <!-- <div style="display: flex; margin-bottom: 10px" v-if="status == 0">
          <div class="field-checkbox" style="flex: 50%; padding: 5px">
            <Checkbox inputId="binary" v-model="overlay.style.auto" :binary="true" @input="ChangeAuto" />
            <label style="margin-left: 10px; padding: 5px">Auto</label>
          </div>
        </div> -->

        <div style="padding: 10px">
          <h1 style="margin-bottom: 10px">
            Opacity: {{ overlay.style.opacity }}%
          </h1>
          <Slider v-model="overlay.style.opacity" :min="10" :max="100" @change="ChangeOpacity(overlay.id)" />
        </div>
        <div style="padding: 10px" v-if="overlay.type == 'image'">
          <h1 style="margin-bottom: 10px">
            Radius: {{ overlay.style.radius }}%
          </h1>
          <Slider v-model="overlay.style.radius" :min="0" :max="50" />
        </div>
        <div style="padding: 10px; width: 100%">
          <h1 style="margin-bottom: 10px">Rotation</h1>
          <SelectButton v-model="overlay.style.rotate" :options="rotationList" aria-labelledby="single"
            style="width: 100%" :unselectable="false" @change="ChangeRotate(overlay.id, $event)">
          </SelectButton>
        </div>
        <div style="padding: 10px; width: 100%" v-if="overlay.type == 'counter' || overlay.type == 'text'">
          <h1 style="margin-bottom: 10px">Text Align</h1>
          <SelectButton v-model="overlay.style.align" :options="alignList" aria-labelledby="single" style="width: 100%"
            :unselectable="false" @change="ChangeAlign(overlay.id, $event)">
          </SelectButton>
        </div>
        <div v-if="overlay.type === 'image' || overlay.type === 'video'" style="
                display: flex;
                height: 70px;
                overflow: hidden;
                align-items: center;
                justify-content: center;
              ">
          <div class="field-checkbox" style="flex: 50%; padding: 5px">
            <Checkbox inputId="binary" v-model="overlay.style.blink" :binary="true" @input="ChangeBlink" />
            <label style="margin-left: 10px; padding: 5px">Blinking</label>
          </div>
          <div class="field-checkbox" style="flex: 50%; padding: 5px">
            <Checkbox inputId="binary" v-model="overlay.style.gray" :binary="true" @input="ChangeGray" />
            <label style="margin-left: 10px; padding: 5px">Gray</label>
          </div>
        </div>
        <!-- <Dropdown v-if="focus == false" v-model="overlay.style.group" :options="groupList"
          placeholder="Select Group Style" style="width: 50%" @change="ChangeGroupStyle(overlay.id, $event)"
          ref="dropdown">
        </Dropdown> -->
      </li>
    </ul>
  </li>
  <li v-if="overlay.type == 'text' || overlay.type == 'counter'">
    <div class="side-menu" id="font" @click="Toggle('font')">
      <div class="side-menu__icon"><i class="bi bi-type"></i></div>
      <div class="side-menu__title">
        Font settings
        <div class="side-menu__sub-icon transform rotate-180">
          <i class="bi-chevron-up bi"></i>
        </div>
      </div>
    </div>
    <ul>
      <li style="padding: 20px">
        <div style="display: flex; margin-bottom: 20px">
          <div style="flex: 100%; padding: 5px">
            <h1 style="margin-bottom: 10px">Pick a font</h1>
            <select class="form-select" aria-label="Default select example" v-model="overlay.style.font"
              @change="ChangeFont(overlay.id)">
              <option v-for="font in fontList" :key="font" :style="{ 'font-family': font }">
                {{ font }}
              </option>
            </select>
          </div>
        </div>
        <div style="display: flex; margin-bottom: 20px">
          <div style="flex: 50%; padding: 5px">
            <h1 style="margin-bottom: 10px">Color</h1>
            <div style="display: flex; align-items: center">
              <div style="flex: 5%">
                <input type="color" v-model="overlay.style.color" @change="ChangeColor(overlay.id)" id="color1" />
              </div>
              <div style="flex: 95%">
                <input type="text" v-model="overlay.style.color" @keyup="ChangeColor(overlay.id)" style="
                        width: 100%;
                        background: transparent;
                        border: none;
                        border-bottom: 1px solid black;
                      " />
              </div>
            </div>
          </div>
          <div style="flex: 50%; padding: 5px">
            <h1 style="margin-bottom: 11px">Size</h1>
            <input type="number" v-model="overlay.style.size" @keyup="ChangeFontSize(overlay.id)" style="
                    width: 100%;
                    background: transparent;
                    border: none;
                    border-bottom: 1px solid black;
                  " />
          </div>
        </div>
      </li>
    </ul>
  </li>
  <li v-if="overlay.type == 'graph'">
    <div class="side-menu" id="font" @click="Toggle('font')">
      <div class="side-menu__icon"><i class="bi bi-bar-chart-steps"></i></div>
      <div class="side-menu__title">
        Graph settings
        <div class="side-menu__sub-icon transform rotate-180">
          <i class="bi-chevron-up bi"></i>
        </div>
      </div>
    </div>
    <ul style="padding: 20px">
      <li>
        <div style="display: flex; margin-bottom: 20px">
          <div style="flex: 50%; padding: 5px">
            <h1 style="margin-bottom: 10px">Graph Align</h1>
            <SelectButton v-model="overlay.style.align" :options="positionList" aria-labelledby="single"
              style="width: 100%" :unselectable="false" @change="ChangeAlign(overlay.id, $event)">
            </SelectButton>
          </div>
          <div style="flex: 50%; padding: 5px">
            <h1 style="margin-bottom: 10px">Bar Color</h1>
            <div style="display: flex; align-items: center">
              <div style="flex: 5%">
                <input type="color" v-model="overlay.style.color" @change="ChangeColor(overlay.id)" id="color1" />
              </div>
              <div style="flex: 95%">
                <input type="text" v-model="overlay.style.color" @keyup="ChangeColor(overlay.id)" style="
                        width: 100%;
                        background: transparent;
                        border: none;
                        border-bottom: 1px solid black;
                      " />
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <!-- <div>
          <h1 style="margin-bottom: 10px">Graph Align</h1>
          <SelectButton v-model="overlay.style.align" :options="positionList" aria-labelledby="single" style="width: 100%"
            :unselectable="false" @change="ChangeAlign(overlay.id, $event)">
          </SelectButton>
        </div> -->

      </li>
    </ul>
  </li>
  <li v-if="overlay.type == 'image' || overlay.type == 'video'">
    <div class="side-menu" id="transition" @click="Toggle('transition')">
      <div class="side-menu__icon">
        <i class="bi bi-exclude"></i>
      </div>
      <div class="side-menu__title">
        Transition Effect
        <div class="side-menu__sub-icon transform rotate-180">
          <i class="bi-chevron-up bi"></i>
        </div>
      </div>
    </div>
    <ul>
      <li style="padding: 10px">
        <div>
          <h1 style="margin-bottom: 11px">Transition In</h1>
          <button class="btn" v-for="transitionIn in transitionInList" :class="{
            'btn-primary': overlay.style.transitionIn == transitionIn.value,
          }" :key="transitionIn" style="margin: 2px">
            <img :src="'/icon/' + transitionIn.icon" alt=""
              @click="ChangeTransition(overlay.id, transitionIn.value, 'in')" />
          </button>
        </div>

        <div style="margin-top: 10px;">
          <h1 style="margin-bottom: 11px">Transition Out</h1>
          <button class="btn" v-for="transitionOut in transitionOutList" :class="{
            'btn-primary': overlay.style.transitionOut == transitionOut.value,
          }" :key="transitionOut" style="margin: 2px">
            <img :src="'/icon/' + transitionOut.icon" alt=""
              @click="ChangeTransition(overlay.id, transitionOut.value, 'out')" />
          </button>
        </div>
        <div style="margin-top: 10px;">
          <h1 style="margin-bottom: 11px">Delay (In)</h1>
          <input type="number" v-model="overlay.style.delayIn" style="
                    width: 100%;
                    background: transparent;
                    border: none;
                    border-bottom: 1px solid black;
                  " @keyup="ChangeDelay(overlay.id)" />
        </div>
        <div style="margin-top: 10px;">
          <div v-if="overlay.type === 'image' || overlay.type === 'video'" style="
                display: flex;
                height: 70px;
                overflow: hidden;
                align-items: center;
                justify-content: center;
              ">
            <div class="field-checkbox" style="flex: 50%; padding: 5px">
              <Checkbox inputId="binary" v-model="overlay.style.animation" :binary="true" @input="ChangeAnimation" />
              <label style="margin-left: 10px; padding: 5px">Intro Animation</label>
            </div>
            <!-- <div class="field-checkbox" style="flex: 50%; padding: 5px">
            <div style="flex: 50%; padding: 5px">
            <button class="btn" @click="ShowDialog('video')">
              <i class="bi bi-image" style="margin-right: 5px"></i><span>Set Intro Image</span>
            </button>
          </div>
          </div> -->
          </div>
        </div>

        <div v-if="overlay.style.animation" style="display: flex; height: 100px; overflow: hidden;margin-bottom: 10px">
          <div style="flex: 50%; padding: 5px">
            <img :src="link + overlay.style.introSource" alt=""
              v-if="overlay.style.introSource != '' && overlay.style.introSource != null" style="" />
            <img src="/default-image.jpg" alt="" style="border-radius: 5px" v-else />
          </div>
          <div style="flex: 50%; padding: 1px">
            <button class="btn btn-success" @click="ShowIntroDialog('image')">
              <span>Intro Image</span>
            </button>
            <button class="btn btn-secondary" @click="ClearIntroImage(overlay.id);">
              <span>Clear</span>
            </button>
          </div>
        </div>
        <div style="margin-top: 10px;">
          <div v-if="overlay.type === 'image' || overlay.type === 'video'" style="
                display: flex;
                height: 70px;
                overflow: hidden;
                align-items: center;
                justify-content: center;
              ">
            <div class="field-checkbox" style="flex: 50%; padding: 5px">
              <Checkbox inputId="binary" v-model="overlay.style.state" :binary="true" @input="ChangeAnimation" />
              <label style="margin-left: 10px; padding: 5px">Hero State</label>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </li>
  <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" class="mb-5" />
  <div style="position: fixed; bottom: 50px; left: 480px" v-if="focus == false">
    <button class="btn btn-rounded-primary btn-primary w-20 h-20 mr-1 mb-2 btn-lg" aria-haspopup="true"
      aria-controls="overlay_menu" type="button" @click="toggleBtn">
      <i class="bi bi-plus-lg"></i>
    </button>
  </div>
  </ul>
  </div>
  <!-- BEGIN: Content -->

  <div style="
      top: 85px;
      position: absolute;
      background-color: oldlace;
      overflow: hidden;
      width: 1920px;
      height: 1080px;
    " class="background" :style="{ 'left': sidebarLeft + 'px' }" id="editor" @click.self="deact">

    <VueDragResize v-for="input in inputsAll" :key="input.style.time" :isActive="input.active" :x="input.x" :y="input.y"
      :w="input.w" :h="input.h" :for="input.id" :id="input.id" v-show="input.type != ''"
      :sticks="['tl', 'tm', 'tr', 'mr', 'br', 'bm', 'bl', 'ml']" :parentLimitation="true" :snapToGrid="input.grid"
      :gridX="5" :gridY="5" :minw="10" :minh="10" :isResizable="input.state" :isDraggable="input.state" style="
        overflow: hidden;
        border: 1px solid black;
        user-select: none;
        position: absolute;
        
      " :style="{ opacity: input.style.opacity + '%', }"
      :class="{ hide: input.hide, ['rotate' + input.style.rotate]: true, blinking: input.style.blink, comActive: input.active }"
      @resizestop="(val) => resize(input.id, input.group, val)"
      @dragstop="(val) => dragstop(input.id, input.group, val)"
      @click.prevent="clicked(input.id, $event, input.group, input.source, input.style.type, input.style.swap, input.type), move(input.id)"
      @dblclick.prevent="ShowPopup">

      <video :autoplay="false" :muted="input.muted" :loop="input.loop" :style="{ '--delay': input.style.delay + 's' }"
        v-if="input.type === 'video' && showElement" id="video" :transition-style="input.style.transition">
        <source :src="link + input.source + '?' + input.style.time" type="video/mp4" v-if="input.source != ''" />
        <source :src="input.source" type="video/mp4" v-else />
      </video>
      <img class="logo overrides" :src="link + input.source + '?' + input.style.time" alt=""
        v-if="input.type === 'image' && input.source != '' && input.source != null"
        :transition-style="input.style.transition"
        :style="{ '--delay': input.style.delay + 's', 'border-radius': input.style.radius + '%' }"
        :class="{ grayClass: input.style.gray }" />
      <div class="overrides" alt="" v-else-if="input.type === 'image'" :transition-style="input.style.transition"
        :style="{ '--delay': input.style.delay + 's' }"></div>
      <pre v-if="input.type === 'text' && showElement" :style="{
        'font-family': input.style.font,
        color: input.style.color,
        'font-size': input.style.size + 'px',
        'text-align': input.style.align,
        'line-height': input.h + 'px'
      }">{{ input.source }}
    </pre>
      <p style="
          position: absolute;
          bottom: 0;
          left: 0;
          width: auto;
          text-align: left;
        " v-show="input.source == '' || input.source === null">
        {{ input.type }}
      </p>
      <p v-if="input.type === 'counter' && showElement == true" :style="{
        'font-family': input.style.font,
        color: input.style.color,
        'font-size': input.style.size + 'px',
        'text-align': input.style.align,
        'line-height': input.h + 'px'
      }">
        {{ input.source }}
      </p>
      <div v-if="input.type === 'graph' && showElement" :style="{
        'height': input.h + 'px',
        '--graph': input.style.color,
        '--percents': input.style.percent + '%',
        'float': input.style.align,
      }" class="percentage percentage-30"></div>
      <!-- :class="{ ['percentage-30']: true }"   -->
      <!-- <Graph></Graph> -->
    </VueDragResize>
    <OverlayPanel ref="op" :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '250px' }"
      v-if="overlay != ''">
      <ul>
        <li style="" v-if="overlay.type == 'text'">
          <button style="margin-right: 15px" @click="Hide(overlay.id, '')">
            <i class="bi bi-eye-fill" v-if="overlay.hide == false" v-tooltip.bottom="'Hide'">
            </i>
            <i class="bi bi-eye-slash-fill" v-else v-tooltip.bottom="'Show'"></i>
          </button>
          <span style="">
            <label for="inputtext">Message</label>

            <Textarea id="textarea" v-model="overlay.source" rows="3" style="
                  width: 100%;
                  margin-top: 5px;
                  border-bottom: 1px solid black;
                " @keyup="ChangeText(overlay.id)" />

          </span>
          <div style="flex: 50%; padding: 5px">

            <Dropdown v-if="focus == false" v-model="overlay.link" :options="heroLinkList" :showClear="true"
              optionLabel="title" optionValue="id" placeholder="Select Link" style="width:100%"
              @change="ChangeHeroLink(overlay.id, $event)">
            </Dropdown>
          </div>

          <div style="flex: 50%; margin-top: 5px;" v-if="overlayType == 2 && focus == false">
            <p style="margin-bottom:5px" for="inputtext">Data for Team Fight</p>

            <Dropdown v-model="overlay.data" :options="dataList" :showClear="true" optionLabel="title"
              optionValue="title" placeholder="Team Fight" style="width:100%"
              @change="ChangeDataList(overlay.id, $event)">
            </Dropdown>
          </div>
          <div style="flex: 50%; margin-top: 5px;" v-if="overlayType == 3 && focus == false">
            <p style="margin-bottom:5px" for="inputtext">Data for Satistic</p>

            <Dropdown v-model="overlay.data" :options="staticList" :showClear="true" optionLabel="title"
              optionValue="title" placeholder="Satistic" style="width:100%"
              @change="ChangeDataList(overlay.id, $event)">
            </Dropdown>
          </div>
          <div style="flex: 50%; margin-top: 5px;" v-if="overlayType == 4 && focus == false">
            <p style="margin-bottom:5px" for="inputtext">Data for Ban Pick</p>

            <Dropdown v-model="overlay.data" :options="draftPickList" :showClear="true" optionLabel="title"
              optionValue="title" placeholder="Ban Pick" style="width:100%"
              @change="ChangeDataList(overlay.id, $event)">
            </Dropdown>
          </div>
          <div style="flex: 50%; margin-top: 5px;" v-if="overlayType == 1 && focus == false">
            <p style="margin-bottom:5px" for="inputtext">Data for GamePlay</p>

            <Dropdown v-model="overlay.data" :options="gamePlayList" :showClear="true" optionLabel="title"
              optionValue="title" placeholder="Game Play" style="width:100%"
              @change="ChangeDataList(overlay.id, $event)">
            </Dropdown>
          </div>
          <div style="flex: 50%; margin-top: 5px;" v-if="overlayType == 5 && focus == false">
            <p style="margin-bottom:5px" for="inputtext">Data for Api</p>

            <Dropdown v-model="overlay.data" :options="apiList" :showClear="true" optionLabel="title"
              optionValue="title" placeholder="Api" style="width:100%" @change="ChangeDataList(overlay.id, $event)">
            </Dropdown>
          </div>
        </li>
        <li v-else-if="overlay.type == 'image'">
          <div class="field-checkbox" style="flex: 50%; padding: 5px">
            <div style="display: flex;">
              <button style="margin-right: 15px; margin-bottom: 5px" @click="Hide(overlay.id, '')">
                <i class="bi bi-eye-fill" v-if="overlay.hide == false" v-tooltip.bottom="'Hide'">
                </i>
                <i class="bi bi-eye-slash-fill" v-else v-tooltip.bottom="'Show'"></i>
              </button>
              <h1 style="margin-bottom: 10px">Image Type</h1>
            </div>
            <Dropdown v-if="focus == false" v-model="overlay.style.type" :options="heroTypeList"
              placeholder="Image Type" optionLabel="title" optionValue="id" style="width:100%"
              @change="ChangeImageType(overlay.id, $event)">
            </Dropdown>
          </div>
          <div style="display: flex; ">
            <div style="flex: 100%; padding: 5px">
              <vue3-simple-typeahead v-if="overlay.style.type == 0" ref="inputRef" placeholder="Select hero ..."
                :items="imageList" :minInputLength="1" @selectItem="ChangeImg(overlay.id, $event)" :itemProjection="(item) => {
                  return item.title
                }">
              </vue3-simple-typeahead>
              <vue3-simple-typeahead v-else-if="overlay.style.type == 1" ref="inputRef"
                placeholder="Select hero square ..." :items="imageHeadSquareList" :minInputLength="1"
                @selectItem="ChangeImg(overlay.id, $event)" :itemProjection="(item) => {
                  return item.title
                }">
              </vue3-simple-typeahead>
              <vue3-simple-typeahead v-else-if="overlay.style.type == 2" ref="inputRef"
                placeholder="Select hero wide ..." :items="imageHeadWideList" :minInputLength="1"
                @selectItem="ChangeImg(overlay.id, $event)" :itemProjection="(item) => {
                  return item.title
                }">
              </vue3-simple-typeahead>
              <vue3-simple-typeahead v-else-if="overlay.style.type == 3" ref="inputRef"
                placeholder="Select hero tall ..." :items="imageHeadTallList" :minInputLength="1"
                @selectItem="ChangeImg(overlay.id, $event)" :itemProjection="(item) => {
                  return item.title
                }">
              </vue3-simple-typeahead>
              <vue3-simple-typeahead v-else-if="overlay.style.type == 4" ref="inputRef"
                placeholder="Select hero 3x2 ..." :items="imageHead3x2List" :minInputLength="1"
                @selectItem="ChangeImg(overlay.id, $event)" :itemProjection="(item) => {
                  return item.title
                }">
              </vue3-simple-typeahead>
              <vue3-simple-typeahead v-else-if="overlay.style.type == 5" ref="inputRef" placeholder="Select Item ..."
                :items="imageItemList" :minInputLength="1" @selectItem="ChangeImg(overlay.id, $event)" :itemProjection="(item) => {
                  return item.title
                }">
              </vue3-simple-typeahead>
              <vue3-simple-typeahead v-else ref="inputRef" placeholder="Select image ..." :items="otherList"
                :minInputLength="1" @selectItem="ChangeImg(overlay.id, $event)" :itemProjection="(item) => {
                  return item.title
                }">
              </vue3-simple-typeahead>
            </div>
          </div>
          <div style=" overflow: hidden;margin-bottom: 10px;width: 100%;">
            <div style=" padding: 5px">
              <button class="btn btn-primary" @click="ShowDialog('image')" style=" margin-right: 5px;width: 31%;">
                <span>Set</span>
              </button>
              <button class="btn btn-secondary" @click="ClearImage(overlay.id)" style=" margin-right: 5px;width: 31%;">
                <span>Clear</span>
              </button>
              <button class="btn btn-success" @click="SwapItem(overlay.id, overlay.link)" style=" width: 31%;"
                v-if="this.overlay.link != '' && this.overlay.link != null && swapItem === false && layerName === 'Static'">
                <span>Sw.Item</span>
              </button>
              <button class="btn btn-success"
                @click="SwapImage(overlay.id, overlay.source, overlay.style.type, overlay.type)"
                style=" width: 31%; margin-top: 5px;" v-if="swap === false && this.overlay.style.swap === true">
                <span>Swap</span>
              </button>
              <button class="btn btn-danger" @click="SwapItem(overlay.id, overlay.link)"
                style=" width: 31%; margin-top: 5px;"
                v-if="this.overlay.link != '' && this.overlay.link != null && swapItem === true">
                <span>Sw.With</span>
              </button>
              <!-- <button class="btn btn-success" @click="SwapImage(overlay.id, overlay.source, 'with')"
                style=" width: 31%;" v-else-if="swap === true && this.overlay.style.swap === true">
                <span>With</span>
              </button> -->
            </div>
            <div class="field-checkbox" style="flex: 50%; padding: 5px" v-if="focus == false">
              <Checkbox inputId="binary" v-model="overlay.style.swap" :binary="true"
                @input="ChangeSwap(overlay.id, $event)" />
              <label style=" padding: 5px;">Swappable</label>
              <Checkbox inputId="binary" v-model="overlay.style.blink" :binary="true" style="margin-left: 5px;"
                @input="ChangeBlink" />
              <label style="margin-left: 5px;">Blink</label>
            </div>
            <!-- <div style="flex:50%; padding: 5px" v-if="focus == false">
              <SelectButton v-model="overlay.banPick" :options="banPickList" optionLabel="value" optionValue="value"
                dataKey="value" aria-labelledby="custom" @change="ChangeBanPick(overlay.id, $event)">
              </SelectButton>
            </div> -->
            <div style="flex: 50%; padding: 5px">
              <Dropdown v-if="focus == false" v-model="overlay.link" :options="heroLinkList" :showClear="true"
                optionLabel="title" optionValue="id" placeholder="Select Link" style="width:100%"
                @change="ChangeHeroLink(overlay.id, $event)">
              </Dropdown>
            </div>
          </div>
          <div style="flex: 50%; margin-top: 5px;" v-if="overlayType == 4 && focus == false">
            <p style="margin-bottom:5px" for="inputtext">Data for Ban Pick</p>
            <Dropdown v-model="overlay.data" :options="draftPickList" :showClear="true" optionLabel="title"
              optionValue="title" placeholder="Ban Pick" style="width:100%"
              @change="ChangeDataList(overlay.id, $event)">
            </Dropdown>
          </div>
        </li>
        <li v-else-if="overlay.type == 'video'">
          <div class="field-checkbox" style="flex: 50%; padding: 5px">
            <div style="display: flex;">
              <button style="margin-right: 15px; margin-bottom: 5px" @click="Hide(overlay.id, '')">
                <i class="bi bi-eye-fill" v-if="overlay.hide == false" v-tooltip.bottom="'Hide'">
                </i>
                <i class="bi bi-eye-slash-fill" v-else v-tooltip.bottom="'Show'"></i>
              </button>
              <h1 style="margin-bottom: 10px">Video Type</h1>
            </div>
            <Dropdown v-if="focus == false" v-model="overlay.style.type" :options="videoTypeList"
              placeholder="Video Type" optionLabel="title" optionValue="id" style="width:100%;margin-bottom: 10px;"
              @change="ChangeImageType(overlay.id, $event)">
            </Dropdown>
            <vue3-simple-typeahead v-if="overlay.style.type == 0" ref="inputRef" placeholder="Select hero ..."
              :items="videoList" :minInputLength="1" @selectItem="ChangeImg(overlay.id, $event)" :itemProjection="(item) => {
                return item.title
              }">
            </vue3-simple-typeahead>
            <vue3-simple-typeahead v-else-if="overlay.style.type == 1" ref="inputRef" placeholder="Select hero tall ..."
              :items="videoTallList" :minInputLength="1" @selectItem="ChangeImg(overlay.id, $event)" :itemProjection="(item) => {
                return item.title
              }">
            </vue3-simple-typeahead>
          </div>
          <div style="">
            <button class="btn btn-primary" @click="ShowDialog('video')" style="margin-right: 5px;">
              <i class="bi bi-camera-video" style="margin-right: 5px"></i><span>Set</span>
            </button>
            <button class="btn" style="margin-right: 5px;" @click="ClearImage(overlay.id)">
              <span>Clear</span>
            </button>
            <button class="btn btn-success"
              @click="SwapImage(overlay.id, overlay.source, overlay.style.type, overlay.type)">
              <span>Swap</span>
            </button>
          </div>
          <div style="
                display: flex;
                overflow: hidden;
                align-items: center;
                justify-content: center;
                width:100%
              ">
            <div class="field-checkbox" style="flex: 40%; padding: 5px">
              <Checkbox inputId="binary" v-model="overlay.loop" :binary="true" @input="ChangeCheckbox('loop')" />
              <label style="margin-left: 10px; padding: 5px">Loop</label>
            </div>
            <div class="field-checkbox" style="flex: 60%; padding: 5px" v-if="focus == false">
              <Checkbox inputId="binary" v-model="overlay.style.swap" :binary="true"
                @input="ChangeSwap(overlay.id, $event)" />
              <label style="margin-left: 10px;">Swappable</label>
            </div>
          </div>
          <div style="flex: 50%; padding: 5px">
            <Dropdown v-if="focus == false" v-model="overlay.link" :options="heroLinkList" :showClear="true"
              optionLabel="title" optionValue="id" placeholder="Select Link" style="width:100%"
              @change="ChangeHeroLink(overlay.id, $event)">
            </Dropdown>
          </div>
        </li>
        <li v-else-if="overlay.type == 'counter'">
          <span style="">
            <button style="margin-right: 15px; margin-bottom: 5px" @click="Hide(overlay.id, '')">
              <i class="bi bi-eye-fill" v-if="overlay.hide == false" v-tooltip.bottom="'Hide'">
              </i>
              <i class="bi bi-eye-slash-fill" v-else v-tooltip.bottom="'Show'"></i>
            </button>
            <div style="display: flex">
              <div style="flex: 25%">
                <label for="input">Hrs</label>
                <input type="number" style="
                      width: 100%;
                      margin-top: 5px;
                      border-bottom: 1px solid black;
                    " v-model="overlay.hours" min="0" @keyup="
                      ChangeTimer($event.target.value, 'hours', overlay.id)
                      " oninput="this.value = 
 !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" />
              </div>
              <div style="flex: 25%">
                <label for="input">Min</label>
                <input type="number" style="
                      width: 100%;
                      margin-top: 5px;
                      border-bottom: 1px solid black;
                    " min="0" v-model="overlay.minutes" @keyup="
                      ChangeTimer($event.target.value, 'minutes', overlay.id)
                      " oninput="this.value = 
 !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" />
              </div>
              <div style="flex: 25%">
                <label for="input">Sec</label>
                <input type="number" style="
                      width: 100%;
                      margin-top: 5px;
                      border-bottom: 1px solid black;
                    " v-model="overlay.seconds" min="0" @keyup="
                      ChangeTimer($event.target.value, 'seconds', overlay.id)
                      " oninput="this.value = 
 !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" />
              </div>
            </div>
            <div style="margin-top: 10px" v-if="focus == false">
              <label for="input">Message</label>
              <input id="inputtext" type="text" v-model="overlay.message" rows="3" style="
                    width: 100%;
                    margin-top: 5px;
                    border-bottom: 1px solid black;
                  " @keyup="ChangeMessage(overlay.id)" />
            </div>
            <div class="field-checkbox" style="flex: 50%; padding: 5px">
              <Checkbox inputId="binary" v-model="overlay.clock" :binary="true" @input="ChangeClock" />
              <label style="margin-left: 10px; padding: 5px">Clock</label>
            </div>
            <div class="field-checkbox" style="flex: 50%; padding: 5px" v-if="overlay.clock == false">
              <button class="btn btn-success mr-2 px-10" :disabled="overlay.op == 'reset'"
                @click="ResetCountdown(overlay.id)">reset</button>
            </div>
            <div class="field-checkbox" style="flex: 50%; padding: 5px" v-if="overlay.clock == true">
              <button class="btn btn-primary mr-2" :disabled="overlay.op == 'start' || overlay.op == ''"
                @click="StartClock(overlay.id)">start</button>
              <button class="btn btn-danger mr-2" :disabled="overlay.op == 'stop' || overlay.op == 'reset'"
                @click="StopClock(overlay.id)">stop</button>
              <button class="btn btn-success mr-2" :disabled="overlay.op == 'reset'"
                @click="ResetClock(overlay.id)">reset</button>
            </div>
          </span>
          <div style="flex: 50%; margin-top: 5px;" v-if="overlayType == 4 && focus == false">
            <p style="margin-bottom:5px" for="inputtext">Data for Ban Pick</p>

            <Dropdown v-model="overlay.data" :options="draftPickList" :showClear="true" optionLabel="title"
              optionValue="title" placeholder="Ban Pick" style="width:100%"
              @change="ChangeDataList(overlay.id, $event)">
            </Dropdown>
          </div>
        </li>
      </ul>

    </OverlayPanel>
  </div>
  <!-- END: Content -->
  <Toast position="top-right" group="tr" />
</template>

<script>
import axios from 'axios';
import VueDragResize from "vue3-drag-resize";
import Textarea from "primevue/textarea";
import InputText from "primevue/inputtext";
import Slider from "primevue/slider";
import SelectButton from "primevue/selectbutton";
import Toast from "primevue/toast";
import Dialog from "primevue/dialog";
import Checkbox from "primevue/checkbox";
import FileUpload from "primevue/fileupload";
import Dropdown from "primevue/dropdown";
import ColorPicker from "primevue/colorpicker";
import db from "../firebase";
import { ref, set } from "firebase/database";
import Graph from "../components/Graph.vue";
import ImageCom from "../components/image.vue";
import VideoCom from "../components/video.vue";

export default {
  name: "OverlayEditorView",
  components: {
    VueDragResize,
    Textarea,
    InputText,
    Slider,
    SelectButton,
    Toast,
    Dialog,
    FileUpload,
    Checkbox,
    Dropdown,
    ColorPicker,
    Graph,
    ImageCom,
    VideoCom,
  },
  data() {
    return {
      sidebarLeft: 0,
      showSidebar: false,
      showTemplate: false,
      disSave: false,
      id: this.$route.query.data || null,
      inputs: [],
      lists: [],
      counter: 0,
      count: 0,
      lastActive: "",
      layerName: "",
      index: 1,
      image: "",
      color: "#000000",
      time: "",
      delay: 0,
      isGamePlay: null,
      showElement: false,
      draggingId: null,
      prevOffsetX: 0,
      prevOffsetY: 0,
      drag: false,
      status: 0,
      auto: 0,
      fileDialog: false,
      introDialog: false,
      startDialog: false,
      tabActive: "",
      file: [],
      files: [],
      overlay: [],
      btnClick: "",
      focus: false,
      h: 0,
      w: 0,
      x: 0,
      y: 0,
      opacity: 0,
      groupOverlay: [],
      childOverlay: [],
      showGroup: false,
      showUngroup: false,
      showCopy: false,
      showDelete: false,
      showUp: false,
      showDown: false,
      showAll: false,
      altClick: 0,
      swap: false,
      firstId: "",
      firstSource: "",
      firstType: '',
      firstImageType: 0,
      img: null,
      linkIds: [],
      multiOverlays: [],
      swapItemList: [],
      swapItem: false,
      next: null,
      link: process.env.VUE_APP_Link,
      overlayType: 0,
      stopAuto: false,
      timer: null,
      separate: 0,
      isLive: false,
      pickSlot: "",
      getApi: false,
      gameplayDataApi: [],
      resultDataApi: [],
      apiInterval: null,
      log: [],
      rotationList: [0, 90, 180, 270],
      alignList: ['Left', 'Center', 'Right'],
      positionList: ['Left', 'Right'],
      heroTypeList: [
        {
          id: 0,
          title: "Hero"
        }, {
          id: 1,
          title: "Square"
        },
        {
          id: 2,
          title: "Wide"
        },
        {
          id: 3,
          title: "Tall"
        },
        {
          id: 4,
          title: "3x2"
        },
        {
          id: 5,
          title: "item"
        },
        {
          id: 6,
          title: "Other"
        }],
      videoTypeList: [{
        id: 0,
        title: "Hero"
      }, {
        id: 1,
        title: "Tall"
      }
      ],
      imageList: [],
      imageHeadWideList: [],
      imageHeadSquareList: [],
      imageHeadTallList: [],
      imageHead3x2List: [],
      imageItemList: [],
      otherList: [],
      videoList: [],
      videoTallList: [],
      updateList: [],
      updateLinkList: [],
      audio: [],
      audioType: [],
      groupList: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10
      ],
      banPickList: [
        {
          id: 0,
          value: "Pick"
        },
        {
          id: 1,
          value: "Ban"
        }
      ],
      OverlayList: [
        {
          id: 0,
          title: "None"
        },
        {
          id: 1,
          title: "GamePlay"
        },
        {
          id: 2,
          title: "Team Fight"
        },
        {
          id: 3,
          title: "Satistic"
        },
        {
          id: 4,
          title: "Ban Pick"
        },
        {
          id: 5,
          title: "Api"
        }

      ],
      gamePlayList: [
        {
          id: 0,
          data: "",
          title: "Time"
        },
        {
          id: 1,
          data: "",
          title: "Turret Left"
        },
        {
          id: 2,
          data: "",
          title: "Turret Right"
        },
        {
          id: 3,
          data: "",
          title: "Lord Left"
        },
        {
          id: 4,
          data: "",
          title: "Lord Right"
        },
        {
          id: 5,
          data: "",
          title: "Money Left"
        },
        {
          id: 6,
          data: "",
          title: "Money Right"
        },
        {
          id: 7,
          data: "",
          title: "Score Left"
        },
        {
          id: 8,
          data: "",
          title: "Score Right"
        },
        {
          id: 9,
          data: "",
          title: "Gold Left 1"
        },
        {
          id: 10,
          data: "",
          title: "Gold Left 2"
        },
        {
          id: 11,
          data: "",
          title: "Gold Left 3"
        },
        {
          id: 12,
          data: "",
          title: "Gold Left 4"
        },
        {
          id: 13,
          data: "",
          title: "Gold Left 5"
        },
        {
          id: 14,
          data: "",
          title: "Gold Right 1"
        },
        {
          id: 15,
          data: "",
          title: "Gold Right 2"
        },
        {
          id: 16,
          data: "",
          title: "Gold Right 3"
        },
        {
          id: 17,
          data: "",
          title: "Gold Right 4"
        },
        {
          id: 18,
          data: "",
          title: "Gold Right 5"
        },
        {
          id: 19,
          data: "",
          title: "Add Gold Left"
        },
        {
          id: 20,
          data: "",
          title: "Add Gold Right"
        },
        {
          id: 21,
          data: "",
          title: "Up Gold Left 1"
        },
        {
          id: 22,
          data: "",
          title: "Up Gold Left 2"
        },
        {
          id: 23,
          data: "",
          title: "Up Gold Left 3"
        },
        {
          id: 24,
          data: "",
          title: "Up Gold Left 4"
        },
        {
          id: 25,
          data: "",
          title: "Up Gold Left 5"
        },
        {
          id: 26,
          data: "",
          title: "Up Gold Right 1"
        },
        {
          id: 27,
          data: "",
          title: "Up Gold Right 2"
        },
        {
          id: 28,
          data: "",
          title: "Up Gold Right 3"
        },
        {
          id: 29,
          data: "",
          title: "Up Gold Right 4"
        },
        {
          id: 30,
          data: "",
          title: "Up Gold Right 5"
        },
        {
          id: 31,
          data: "",
          title: "Turtle Left"
        },
        {
          id: 32,
          data: "",
          title: "Turtle Right"
        },
      ],
      dataList: [
        {
          id: 0,
          title: "Player left 1",
          data: ""
        },
        {
          id: 1,
          title: "Player left 2",
          data: ""
        },
        {
          id: 2,
          title: "Player left 3",
          data: ""
        },
        {
          id: 3,
          title: "Player left 4",
          data: ""
        },
        {
          id: 4,
          title: "Player left 5",
          data: ""
        },
        {
          id: 5,
          title: "Player right 1",
          data: ""
        },
        {
          id: 6,
          title: "Player right 2",
          data: ""
        },
        {
          id: 7,
          title: "Player right 3",
          data: ""
        },
        {
          id: 8,
          title: "Player right 4",
          data: ""
        },
        {
          id: 9,
          title: "Player right 5",
          data: ""
        },
        {
          id: 10,
          title: "Damage dealt left",
          data: ""
        },
        {
          id: 11,
          title: "Damage taken left",
          data: ""
        },
        {
          id: 12,
          title: "Damage turret left",
          data: ""
        },
        {
          id: 13,
          title: "Damage dealt right",
          data: ""
        },
        {
          id: 14,
          title: "Damage taken right",
          data: ""
        },
        {
          id: 15,
          title: "Damage turret right",
          data: ""
        }

      ],
      staticList: [
        {
          id: 1,
          title: "Total Gold Left",
          data: ""
        },
        {
          id: 2,
          title: "Total Gold Right",
          data: ""
        },
        {
          id: 3,
          title: "Gold Left 1",
          data: ""
        },
        {
          id: 4,
          title: "Gold Left 2",
          data: ""
        },
        {
          id: 5,
          title: "Gold Left 3",
          data: ""
        },
        {
          id: 6,
          title: "Gold Left 4",
          data: ""
        },
        {
          id: 7,
          title: "Gold Left 5",
          data: ""
        },
        {
          id: 8,
          title: "Gold Right 1",
          data: ""
        },
        {
          id: 9,
          title: "Gold Right 2",
          data: ""
        },
        {
          id: 10,
          title: "Gold Right 3",
          data: ""
        },
        {
          id: 11,
          title: "Gold Right 4",
          data: ""
        },
        {
          id: 12,
          title: "Gold Right 5",
          data: ""
        },
        {
          id: 13,
          title: "KDA Left 1",
          data: ""
        },
        {
          id: 14,
          title: "KDA Left 2",
          data: ""
        },
        {
          id: 15,
          title: "KDA Left 3",
          data: ""
        },
        {
          id: 16,
          title: "KDA Left 4",
          data: ""
        },
        {
          id: 17,
          title: "KDA Left 5",
          data: ""
        },
        {
          id: 18,
          title: "KDA Right 1",
          data: ""
        },
        {
          id: 19,
          title: "KDA Right 2",
          data: ""
        },
        {
          id: 20,
          title: "KDA Right 3",
          data: ""
        },
        {
          id: 21,
          title: "KDA Right 4",
          data: ""
        },
        {
          id: 22,
          title: "KDA Right 5",
          data: ""
        },
      ],
      draftPickList: [
        {
          id: 1,
          title: "Title BanPick",
          data: ""
        },
        {
          id: 2,
          title: "Arrow Left",
          data: ""
        },
        {
          id: 3,
          title: "Arrow Right",
          data: ""
        },
        {
          id: 4,
          title: "Timer",
          data: ""
        }
      ],
      apiList: [
        {
          id: 1,
          title: "image 1",
          data: ""
        },
        {
          id: 2,
          title: "image 2",
          data: ""
        },
        {
          id: 3,
          title: "image 3",
          data: ""
        },
        {
          id: 4,
          title: "image 4",
          data: ""
        },
        {
          id: 5,
          title: "image 5",
          data: ""
        },
        {
          id: 6,
          title: "image 6",
          data: ""
        },
        {
          id: 7,
          title: "image 7",
          data: ""
        },
        {
          id: 8,
          title: "image 8",
          data: ""
        },
        {
          id: 9,
          title: "image 9",
          data: ""
        },
        {
          id: 10,
          title: "image 10",
          data: ""
        },
        {
          id: 11,
          title: "name 1",
          data: ""
        },
        {
          id: 12,
          title: "name 2",
          data: ""
        },
        {
          id: 13,
          title: "name 3",
          data: ""
        },
        {
          id: 14,
          title: "name 4",
          data: ""
        },
        {
          id: 15,
          title: "name 5",
          data: ""
        },
        {
          id: 16,
          title: "name 6",
          data: ""
        },
        {
          id: 17,
          title: "name 7",
          data: ""
        },
        {
          id: 18,
          title: "name 8",
          data: ""
        },
        {
          id: 19,
          title: "name 9",
          data: ""
        },
        {
          id: 20,
          title: "name 10",
          data: ""
        },
        {
          id: 21,
          title: "kda 1 blue",
          data: ""
        },
        {
          id: 22,
          title: "kda 1 red",
          data: ""
        },
        {
          id: 23,
          title: "kda 2 blue",
          data: ""
        },
        {
          id: 24,
          title: "kda 2 red",
          data: ""
        },
        {
          id: 25,
          title: "kda 3 blue",
          data: ""
        },
        {
          id: 26,
          title: "kda 3 red",
          data: ""
        },
        {
          id: 27,
          title: "kda 4 blue",
          data: ""
        },
        {
          id: 28,
          title: "kda 4 red",
          data: ""
        },
        {
          id: 29,
          title: "kda 5 blue",
          data: ""
        },
        {
          id: 30,
          title: "kda 5 red",
          data: ""
        },
      ],
      heroLinkList: [
        {
          id: 1,
          title: "Image 1",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 2,
          title: "Image 2",
          source: null,
          type: "",
          name: "",
          disabled: false,
        },
        {
          id: 3,
          title: "Image 3",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 4,
          title: "Image 4",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 5,
          title: "Image 5",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 6,
          title: "Image 6",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 7,
          title: "Image 7",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 8,
          title: "Image 8",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 9,
          title: "Image 9",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 10,
          title: "Image 10",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 11,
          title: "item 1.1",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 12,
          title: "item 1.2",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 13,
          title: "item 1.3",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 14,
          title: "item 1.4",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 15,
          title: "item 1.5",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 16,
          title: "item 1.6",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 17,
          title: "item 2.1",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 18,
          title: "item 2.2",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 19,
          title: "item 2.3",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 20,
          title: "item 2.4",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 21,
          title: "item 2.5",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 22,
          title: "item 2.6",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 23,
          title: "item 3.1",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 24,
          title: "item 3.2",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 25,
          title: "item 3.3",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 26,
          title: "item 3.4",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 27,
          title: "item 3.5",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 28,
          title: "item 3.6",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 29,
          title: "item 4.1",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 30,
          title: "item 4.2",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 31,
          title: "item 4.3",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 32,
          title: "item 4.4",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 33,
          title: "item 4.5",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 34,
          title: "item 4.6",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 35,
          title: "item 5.1",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 36,
          title: "item 5.2",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 37,
          title: "item 5.3",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 38,
          title: "item 5.4",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 39,
          title: "item 5.5",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 40,
          title: "item 5.6",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 41,
          title: "item 6.1",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 42,
          title: "item 6.2",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 43,
          title: "item 6.3",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 44,
          title: "item 6.4",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 45,
          title: "item 6.5",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 46,
          title: "item 6.6",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 47,
          title: "item 7.1",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 48,
          title: "item 7.2",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 49,
          title: "item 7.3",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 50,
          title: "item 7.4",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 51,
          title: "item 7.5",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 52,
          title: "item 7.6",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 53,
          title: "item 8.1",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 54,
          title: "item 8.2",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 55,
          title: "item 8.3",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 56,
          title: "item 8.4",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 57,
          title: "item 8.5",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 58,
          title: "item 8.6",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 59,
          title: "item 9.1",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 60,
          title: "item 9.2",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 61,
          title: "item 9.3",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 62,
          title: "item 9.4",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 63,
          title: "item 9.5",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 64,
          title: "item 9.6",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 65,
          title: "item 10.1",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 66,
          title: "item 10.2",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 67,
          title: "item 10.3",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 68,
          title: "item 10.4",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 69,
          title: "item 10.5",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 70,
          title: "item 10.6",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 71,
          title: "spell 1",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 72,
          title: "spell 2",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 73,
          title: "spell 3",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 74,
          title: "spell 4",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 75,
          title: "spell 5",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 76,
          title: "spell 6",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 77,
          title: "spell 7",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 78,
          title: "spell 8",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 79,
          title: "spell 9",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 80,
          title: "spell 10",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 81,
          title: "ban left 1",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 82,
          title: "ban left 2",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 83,
          title: "ban left 3",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 84,
          title: "ban left 4",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 85,
          title: "ban left 5",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 86,
          title: "ban right 1",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 87,
          title: "ban right 2",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 88,
          title: "ban right 3",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 89,
          title: "ban right 4",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 90,
          title: "ban right 5",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 91,
          title: "name 1",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 92,
          title: "name 2",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 93,
          title: "name 3",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 94,
          title: "name 4",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 95,
          title: "name 5",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 96,
          title: "name 6",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 97,
          title: "name 7",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 98,
          title: "name 8",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 99,
          title: "name 9",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },
        {
          id: 100,
          title: "name 10",
          source: null,
          name: "",
          type: "",
          disabled: false,
        },

      ],
      transitionInList: [
        {
          value: "",
          icon: "cover-none.png",
        },
        {
          value: "in:wipe:right",
          icon: "cover-right.png",
        },
        {
          value: "in:wipe:left",
          icon: "cover-left.png",
        },
        {
          value: "in:wipe:up",
          icon: "cover-up.png",
        },
        {
          value: "in:wipe:down",
          icon: "cover-down.png",
        },
        {
          value: "in:wipe:top-left",
          icon: "cover-top-left.png",
        },
        {
          value: "in:wipe:top-right",
          icon: "cover-top-right.png",
        },
        {
          value: "in:wipe:bottom-left",
          icon: "cover-bottom-left.png",
        },
        {
          value: "in:wipe:bottom-right",
          icon: "cover-bottom-right.png",
        },
      ],
      transitionOutList: [
        {
          value: "",
          icon: "cover-none.png",
        },
        {
          value: "out:wipe:right",
          icon: "cover-right.png",
        },
        {
          value: "out:wipe:left",
          icon: "cover-left.png",
        },
        {
          value: "out:wipe:up",
          icon: "cover-up.png",
        },
        {
          value: "out:wipe:down",
          icon: "cover-down.png",
        },
        {
          value: "out:wipe:top-left",
          icon: "cover-top-left.png",
        },
        {
          value: "out:wipe:top-right",
          icon: "cover-top-right.png",
        },
        {
          value: "out:wipe:bottom-left",
          icon: "cover-bottom-left.png",
        },
        {
          value: "out:wipe:bottom-right",
          icon: "cover-bottom-right.png",
        },
      ],
      fontList: [
        "Abril Fatface",
        "AKbalthom Kbach",
        "Amatic SC",
        "Architects Daughter",
        "Bangers",
        "BricolageGrotesque-SemiBold",
        "Chewy",
        "Comfortaa",
        "Covered By Your Grace",
        "Courgette",
        "Cinzel",
        "Dancing Script",
        "Gloria Hallelujah",
        "Josefin Sans",
        "Kaushan Script",
        "Khmer Pen Eng",
        "Lobster",
        "Lobster Two",
        "Montez",
        "Orbitron",
        "Pacifico",
        "PLAYBILL",
        "Rokkitt",
        "Righteous",
        "Russo_One",
        "SansSerifBldFLF",
        "SansSerifBldFLFCond",
        "SansSerifBldFLFCond-Italic",
        "SansSerifBldFLF-Italic",
        "Satisfy",
        "Shadows Into Light",
        "Sigmar One",
        "Tungsten-Semibold",
        "GT Walsheim Pro Bold",
        "GT Walsheim Pro Regular",
        "CHAKRAPETCH-BOLD",
        "CHAKRAPETCH-SEMIBOLD",
        "TUNGSTEN-BOLD",
        "TUNGSTENCOND-BLACK",
        "TUNGSTENCOND-BOLD",
        "TUNGSTENCOND-SEMIBOLD",
        "TUNGSTEN-SEMIBOLD"
      ],
      items: [
        {
          label: "Add Element",
          items: [
            {
            },
            {
              label: "Add Text",
              icon: "bi bi-type",
              command: () => {
                this.inputs.push({
                  id: ++this.counter,
                  x: 500,
                  y: 150,
                  h: 50,
                  w: 150,
                  active: false,
                  state: true,
                  hide: false,
                  grid: false,
                  filter: false,
                  data: null,
                  autoSource: "",
                  type: "text",
                  source: "text here",
                  title: "text " + this.counter,
                  group: "",
                  link: null,
                  style: {
                    opacity: 100,
                    rotate: 0,
                    font: "",
                    color: "#000000",
                    size: "20",
                    time: this.time,
                    transition: "",
                    blink: false,
                    group: 0,
                    auto: false,
                    align: "Left",
                  },
                });
              },
            },
            {
              label: "Add Image",
              icon: "pi pi-image",
              command: () => {
                this.inputs.push({
                  id: ++this.counter,
                  x: 500,
                  y: 150,
                  h: 200,
                  w: 200,
                  active: false,
                  state: true,
                  hide: false,
                  grid: false,
                  filter: false,
                  type: "image",
                  source: null,
                  title: "image " + this.counter,
                  link: null,
                  banPick: "",
                  group: "",
                  style: {
                    opacity: 100,
                    rotate: 0,
                    time: this.time,
                    transition: "in:wipe:down",
                    transitionIn: "in:wipe:down",
                    transitionOut: "out:wipe:up",
                    delayIn: 0,
                    delay: 0,
                    blink: false,
                    radius: 0,
                    type: 6,
                    swap: false,
                    swapState: false,
                    group: 0,
                    auto: false,
                    gray: false,
                    animation: false,
                    introSource: null,
                    state: false,
                  },
                  heroState: {
                    pickCount: 0,
                    banCount: 0,
                    winRate: 0,
                    kill: 0,
                    death: 0,
                    assist: 0,
                    name: "",
                  }
                });
              },
            },
            {
              label: "Add Video",
              icon: "pi pi-video",
              command: () => {
                this.inputs.push({
                  id: ++this.counter,
                  x: 500,
                  y: 150,
                  h: 190,
                  w: 340,
                  active: false,
                  type: "video",
                  loop: true,
                  link: null,
                  autoplay: true,
                  muted: true,
                  hide: false,
                  state: true,
                  grid: false,
                  filter: false,
                  source: "",
                  title: "video " + this.counter,
                  group: "",
                  style: {
                    transition: "in:wipe:down",
                    transitionIn: "in:wipe:down",
                    transitionOut: "out:wipe:up",
                    delayIn: 0,
                    delay: 0,
                    opacity: 100,
                    rotate: 0,
                    time: this.time,
                    blink: false,
                    swap: false,
                    swapState: false,
                    group: 0,
                    auto: false,
                    type: 0,
                    animation: false,
                    introSource: null,
                    state: false,
                  },
                  heroState: {
                    pickCount: 0,
                    banCount: 0,
                    winRate: 0,
                    kill: 0,
                    death: 0,
                    assist: 0,
                    name: "",
                  }
                });
              },
            },
            {
              label: "Add Counter",
              icon: "pi pi-clock",
              command: () => {
                this.inputs.push({
                  id: ++this.counter,
                  x: 500,
                  y: 150,
                  h: 50,
                  w: 100,
                  active: false,
                  type: "counter",
                  hide: false,
                  state: true,
                  grid: false,
                  filter: false,
                  time: "00:00:00",
                  totalSeconds: 0,
                  hours: 0,
                  minutes: 0,
                  seconds: 0,
                  message: "{{hours}}:{{minutes}}:{{seconds}}",
                  source: "0:0:0",
                  title: "counter " + this.counter,
                  group: "",
                  clock: false,
                  op: "",
                  style: {
                    opacity: 100,
                    rotate: 0,
                    font: "",
                    color: "#000000",
                    size: "20",
                    time: this.time,
                    transition: "",
                    blink: false,
                    align: "Left",
                    group: 0,
                    auto: false,
                  },
                });
              },
            },
            {
              label: "Add Graph",
              icon: "pi pi-chart-bar",
              command: () => {
                this.inputs.push({
                  id: ++this.counter,
                  x: 500,
                  y: 150,
                  h: 50,
                  w: 200,
                  active: false,
                  state: true,
                  hide: false,
                  grid: false,
                  filter: false,
                  type: "graph",
                  source: null,
                  title: "graph " + this.counter,
                  link: null,
                  banPick: "",
                  group: "",
                  style: {
                    percent: 80,
                    opacity: 100,
                    rotate: 0,
                    time: this.time,
                    color: "#ff0000",
                    align: "Left",
                  },
                });
              },
            },
          ],
        },
      ],
    };
  },
  created: function () {
    let self = this;
    window.addEventListener("keydown", function (event) {
      if (event.ctrlKey && event.altKey && event.key == 's') {
        event.preventDefault();
        if (!self.disSave) {
          self.Save('save');
        }
      }
    });
    window.addEventListener("keydown", function (event) {
      if (event.ctrlKey && event.altKey && event.key == 'f') {
        event.preventDefault();
        if (self.overlay != '') {
          if (self.overlay.type == "image") {
            self.ClearImage(self.overlay.id);
          }
          if (self.overlay.type == "video") {
            self.ClearImage(self.overlay.id);
          }
        }
      }
    });
  },

  mounted() {
    setInterval(this.GetNow, 1000);
    this.GetImage();
    if (this.id != null) {
      var id = this.decrypt(this.id);
      this.GetOverlay(id, '');
      this.startDialog = false;
    } else {
      this.startDialog = true;
      this.layerName = "Unnamed Overlay";
      this.status = 0;
      this.auto = 0;
      this.showElement = true;
    }
    this.showTemplate = true;
  },

  watch: {
    h(value) {
      if (value == "") {
        this.h = 0;
      }
    },
    w(value) {
      if (value == "") {
        this.w = 0;
      }
    },
    x(value) {
      if (value == "") {
        this.x = 0;
      }
    },
    y(value) {
      if (value == "") {
        this.y = 0;
      }
    },

    overlayType(value) {
      if (this.time != "") {
        const token = this.$store.state.token;
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        var data = {
          id: this.decrypt(this.id),
          type: value,
        };
        axios.post("update_type", data, config).then((resp) => {
          if (resp.data.status == "success") {
            this.Toast("success", "Saved", "");
          }
        }).catch((err) => {
          this.CatchError(err);
        });
      }
    }
  },

  methods: {
    async AutoUpdateCompApi(name) {
      this.getApi = true;
      var self = this;
      var layerName = name;
      this.apiInterval = setInterval(function () { self.UpdateCompData(layerName) }, 2500);
      // this.apiInterval = setInterval(function () {console.log('adfadfas') }, 2000);

    },
    async UpdateCompData(title) {
      await this.GetGameplayApiData();
      // return false;
      var gameplay = this.gameplayDataApi;
      var kda_1_blue, kda_1_red, kda_2_blue, kda_2_red, kda_3_blue, kda_3_red, kda_4_blue, kda_4_red, kda_5_blue, kda_5_red;
      kda_1_blue = kda_1_red = kda_2_blue = kda_2_red = kda_3_blue = kda_3_red = kda_4_blue = kda_4_red = kda_5_blue = kda_5_red = "";
      var team_blue, team_red;
      team_blue = gameplay['camp_list'][0];
      team_red = gameplay['camp_list'][1];
      kda_1_blue = team_blue['player_list'][0]['kill_num'] + " / " + team_blue['player_list'][0]['dead_num'] + " / " + team_blue['player_list'][0]['assist_num'];
      kda_2_blue = team_blue['player_list'][1]['kill_num'] + " / " + team_blue['player_list'][1]['dead_num'] + " / " + team_blue['player_list'][1]['assist_num'];
      kda_3_blue = team_blue['player_list'][2]['kill_num'] + " / " + team_blue['player_list'][2]['dead_num'] + " / " + team_blue['player_list'][2]['assist_num'];
      kda_4_blue = team_blue['player_list'][3]['kill_num'] + " / " + team_blue['player_list'][3]['dead_num'] + " / " + team_blue['player_list'][3]['assist_num'];
      kda_5_blue = team_blue['player_list'][4]['kill_num'] + " / " + team_blue['player_list'][4]['dead_num'] + " / " + team_blue['player_list'][4]['assist_num'];

      kda_1_red = team_red['player_list'][0]['kill_num'] + " / " + team_red['player_list'][0]['dead_num'] + " / " + team_red['player_list'][0]['assist_num'];
      kda_2_red = team_red['player_list'][1]['kill_num'] + " / " + team_red['player_list'][1]['dead_num'] + " / " + team_red['player_list'][1]['assist_num'];
      kda_3_red = team_red['player_list'][2]['kill_num'] + " / " + team_red['player_list'][2]['dead_num'] + " / " + team_red['player_list'][2]['assist_num'];
      kda_4_red = team_red['player_list'][3]['kill_num'] + " / " + team_red['player_list'][3]['dead_num'] + " / " + team_red['player_list'][3]['assist_num'];
      kda_5_red = team_red['player_list'][4]['kill_num'] + " / " + team_red['player_list'][4]['dead_num'] + " / " + team_red['player_list'][4]['assist_num'];
      console.log(kda_1_blue, kda_1_red, kda_2_blue, kda_2_red, kda_3_blue, kda_3_red, kda_4_blue, kda_4_red, kda_5_blue, kda_5_red);
      if (title == "Cam 1" || title == "Cam 2" || title == "Cam 3" || title == "Cam 4" || title == "Cam 5") {
        const dataSourceMap = {
          "kda 1 blue": kda_1_blue,
          "kda 2 blue": kda_2_blue,
          "kda 3 blue": kda_3_blue,
          "kda 4 blue": kda_4_blue,
          "kda 5 blue": kda_5_blue,
          "kda 1 red": kda_1_red,
          "kda 2 red": kda_2_red,
          "kda 3 red": kda_3_red,
          "kda 4 red": kda_4_red,
          "kda 5 red": kda_5_red,
        };

        for (let i = 0; i < this.inputsAll.length; i++) {
          const input = this.inputsAll[i];
          const source = dataSourceMap[input.data];

          if (source) {
            input.source = source;
            input.style.time = this.time + i;

            if (input.group !== "") {
              // Find all inputs in the same group and update them
              for (let j = 0; j < this.inputs.length; j++) {
                if (this.inputs[j].id === input.group) {
                  this.LoopAddUpdate(this.inputs[j]);
                }
              }
            } else {
              this.LoopAddUpdate(input);
            }
          }
        }
        this.Save('save');
      }
    },
    async GetGameplayApiData() {

      this.getApi = true;
      const token = this.$store.state.token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      var data = {
        type: "gameplay",
      };
      this.gameplayDataApi = [];
      await axios.post("get_api", data, config).then((resp) => {
        if (resp.status == 200) {
          var data = JSON.parse(resp.data.data.data);
          this.gameplayDataApi = data;
        }
      });
    },
    async GetResultApiData() {

      this.getApi = true;
      const token = this.$store.state.token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      var data = {
        type: "result",
      };
      this.resultDataApi = [];
      await axios.post("get_api", data, config).then((resp) => {
        if (resp.status == 200) {
          var data = JSON.parse(resp.data.data.data);
          this.resultDataApi = data;
        }
      });
    },

    StopApiData() {
      this.getApi = false;
      clearInterval(this.apiInterval);
    },
    ToggleSideBar() {
      if (this.showSidebar) {
        this.showSidebar = false;
        this.sidebarLeft = 0;
      } else {
        this.showSidebar = true;
        this.sidebarLeft = 400;
      }
    },
    test() {
      axios.get("https://tracker.re-esport.com/api/hero/cici").then((resp) => {
        if (resp.status == 200) {
          console.log(resp.data);
        }
      }).catch((err) => {
        this.CatchError(err);
      });
    },
    StartClock(id) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == id) {
          this.overlay.op = 'start';
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
        }
      }
    },

    StopClock(id) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == id) {
          this.overlay.op = 'stop';
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
        }
      }
    },

    ResetClock(id) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == id) {
          this.inputsAll[i].style.time = this.time;
          this.overlay.op = 'start';
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
        }
      }
    },

    ResetCountdown(id) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == id) {
          this.inputsAll[i].style.time = this.time;
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
          this.HidePopup();
        }
      }
    },

    ChangeGroupStyle(id, evt) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == id) {
          this.inputsAll[i].style.group = evt.value;
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
          this.HidePopup();
        }
      }
    },

    LockAll() {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].state == true) {
          this.inputsAll[i].state = false;
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
        }
      }

      for (var i = 0; i < this.inputs.length; i++) {
        if (this.inputs[i].type == "group") {
          if (this.inputs[i].state == true) {
            this.inputs[i].state = false;
            this.LoopAddUpdate(this.inputs[i]);
          }
        }
      }
    },

    ClearItem() {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].type == 'image') {
          if (this.inputsAll[i].link != null) {
            this.inputsAll[i].source = "";
            if (this.inputsAll[i].group != "") {
              for (var j = 0; j < this.inputs.length; j++) {
                if (this.inputs[j].id == this.inputsAll[i].group) {
                  this.LoopAddUpdate(this.inputs[j]);
                }
              }
            } else {
              this.LoopAddUpdate(this.inputsAll[i]);
            }
          }
        }
      }

      for (var i = 0; i < this.inputs.length; i++) {
        if (this.inputsAll[i].type == 'image') {
          if (this.inputs[i].link != null) {
            if (this.inputs[i].state == true) {
              this.inputsAll[i].source = "";
              this.LoopAddUpdate(this.inputs[i]);
            }
          }
        }
      }
    },

    ChangeImg(id, evt) {
      var overlay = this.inputsAll.find(x => x.id === id);
      if (overlay) {
        overlay.source = evt.file_name;

        var fileName = evt.file_name;
        const patterns = ['Fixed/', '.webm', '_3x2.jpg', '.jpg', '_wide.jpg', '_wide', '_3x2', '_square'];

        patterns.forEach(pattern => {
          fileName = fileName.replace(pattern, '');
        });

        if (overlay.link > 80 && overlay.link <= 90) {
          this.audio.push(fileName + ".wav");
          this.audioType.push('ban');
        }

        if (overlay.link > 0 && overlay.link <= 10) {
          this.audio.push(fileName + ".wav");
          this.audioType.push('pick');
          switch (fileName) {
            case "Yi-Sun-Shin":
              fileName = "Yi Sun-shin";
              break;
            case "Chang-e":
              fileName = "Chang'e";
              break;
            case "X-Borg":
              fileName = "X.Borg";
              break;
            case "Luo-Yi":
              fileName = "Luo Yi";
              break;
            case "Popol-and-Kupa":
              fileName = "Popol";
              break;
            case "Yu-Zhong":
              fileName = "Yu Zhong";
              break;
            case "Camilla":
              fileName = "Carmilla";
              break;
            case "Zhu-Xin":
              fileName = "Zhuxin";
              break;
            case "WanWan":
              fileName = "Wanwan";
              break;
          }
          //version 2
          axios.get("https://tracker.re-esport.com/api/heroes?tournament=10&recent=true&name=" + fileName).then((resp) => {
            if (resp.status == 200) {
              var heros = resp.data;
              var hero = Object.entries(heros).find(x => x[1].name == fileName);
              if (hero) {
                overlay.heroState.kill = hero[1].recent_kill_count;
                overlay.heroState.death = hero[1].recent_dead_count;
                overlay.heroState.assist = hero[1].recent_assist_count;
                overlay.heroState.pickCount = hero[1].picked_count;
                overlay.heroState.banCount = hero[1].banned_count;
                overlay.heroState.winRate = hero[1].win_rate;
                overlay.heroState.name = fileName;

              } else {
                overlay.heroState.kill = 0;
                overlay.heroState.death = 0;
                overlay.heroState.assist = 0;
                overlay.heroState.pickCount = 0;
                overlay.heroState.banCount = 0;
                overlay.heroState.winRate = 0;
                overlay.heroState.name = "";

              }
            } else {
              overlay.heroState.kill = 0;
              overlay.heroState.death = 0;
              overlay.heroState.assist = 0;
              overlay.heroState.pickCount = 0;
              overlay.heroState.banCount = 0;
              overlay.heroState.winRate = 0;
              overlay.heroState.name = fileName;

            }
          }).catch((err) => {
            this.CatchError(err);
          });

          // version 1
          // axios.get("https://tracker.re-esport.com/api/hero/" + fileName + "/2").then((resp) => {
          //   if (resp.status == 200) {
          //     axios.get("https://tracker.re-esport.com/api/heroes/2/1").then((resps) => {
          //       if (resps.status == 200) {
          //         var heros = resps.data;

          //         var hero = Object.entries(heros).find(x => x[1].name == fileName);
          //         if (hero) {
          //           overlay.heroState.kill = hero[1].kill_count;
          //           overlay.heroState.death = hero[1].dead_count;
          //           overlay.heroState.assist = hero[1].assist_count;
          //         } else {
          //           overlay.heroState.kill = 0;
          //           overlay.heroState.death = 0;
          //           overlay.heroState.assist = 0;
          //         }
          //       } else {
          //         overlay.heroState.kill = 0;
          //         overlay.heroState.death = 0;
          //         overlay.heroState.assist = 0;
          //       }
          //     });
          //     overlay.heroState.pickCount = resp.data.picked_count;
          //     overlay.heroState.banCount = resp.data.banned_count;
          //     overlay.heroState.winRate = resp.data.win_rate;

          //   } else {
          //     overlay.heroState.pickCount = 0;
          //     overlay.heroState.banCount = 0;
          //     overlay.heroState.winRate = 0;
          //     overlay.heroState.kill = 0;
          //     overlay.heroState.death = 0;
          //     overlay.heroState.assist = 0;
          //   }
          // }).catch((err) => {
          //   this.CatchError(err);
          // });
        }
        overlay.style.blink = false;
        overlay.style.time = this.time;
        overlay.style.swapState = false;
        if (overlay.group != "") {
          var group = this.inputs.find(x => x.id === overlay.group);
          if (group.id == overlay.group) {
            this.LoopAddUpdate(group);
          }
        } else {
          this.LoopAddUpdate(overlay);
        }

        if (overlay.link != '') {
          var hero = this.heroLinkList.find(x => x.id === overlay.link);
          if (hero) {
            hero.source = evt.file_name;
            hero.name = 'Fixed/' + evt.title;
            hero.type = overlay.type;
          }
        }
        this.HidePopup();
      }
    },

    ChangeImage(id, evt) {
      var overlay = this.inputsAll.find(x => x.id === id);
      if (overlay) {
        overlay.source = evt.value;
        overlay.style.blink = false;
        overlay.style.time = this.time;
        overlay.style.swapState = false;

        // overlay.style.delay = 0;
        if (overlay.group != "") {
          var group = this.inputs.find(x => x.id === overlay.group);
          if (group) this.LoopAddUpdate(group);
        } else {
          this.LoopAddUpdate(overlay);
        }

        if (overlay.link != '') {
          var hero = this.heroLinkList.find(x => x.id === overlay.link);
          if (hero) {
            hero.source = evt.value;
            hero.name = 'Fixed/' + evt.originalEvent.target.innerText;
            hero.type = overlay.type;
          }
        }
      }
    },

    BtnPick(val) {
      switch (val) {
        case "pl":
          this.audio.push("YOUR-TEAM-IS-PICKING.wav");
          this.audioType.push('none');
          for (var i = 0; i < this.inputsAll.length; i++) {
            if (this.inputsAll[i].data == "Title BanPick") {
              this.inputsAll[i].source = "PICK";
              // this.inputsAll[i].style.color = "white";
              this.inputsAll[i].hide = false;
              this.inputsAll[i].time = this.time;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
            if (this.inputsAll[i].data == "Arrow Left") {
              this.inputsAll[i].hide = false;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
            if (this.inputsAll[i].data == "Arrow Right") {
              this.inputsAll[i].hide = true;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
            if (this.inputsAll[i].data == "Timer") {
              this.inputsAll[i].hide = false;
              this.inputsAll[i].seconds = "50";
              this.inputsAll[i].totalSeconds = 50;
              this.inputsAll[i].source = "50";
              this.inputsAll[i].time = "0:0:50";
              this.inputsAll[i].style.time = this.time;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
          }
          break;
        case "pr":
          this.audio.push("YOUR-TEAM-IS-PICKING.wav");
          this.audioType.push('none');

          for (var i = 0; i < this.inputsAll.length; i++) {
            if (this.inputsAll[i].data == "Title BanPick") {
              this.inputsAll[i].source = "PICK";
              // this.inputsAll[i].style.color = "white";
              this.inputsAll[i].hide = false;
              this.inputsAll[i].time = this.time;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
            if (this.inputsAll[i].data == "Arrow Left") {
              this.inputsAll[i].hide = true;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
            if (this.inputsAll[i].data == "Arrow Right") {
              this.inputsAll[i].hide = false;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
            if (this.inputsAll[i].data == "Timer") {
              this.inputsAll[i].hide = false;
              this.inputsAll[i].style.time = this.time;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
          }
          break;
        case "bl":
          this.audio.push("YOUR-TEAM-IS-BAN.wav");
          this.audioType.push('none');

          for (var i = 0; i < this.inputsAll.length; i++) {
            if (this.inputsAll[i].data == "Title BanPick") {
              this.inputsAll[i].source = "BAN";
              // this.inputsAll[i].style.color = "white";
              this.inputsAll[i].hide = false;
              this.inputsAll[i].time = this.time;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
            if (this.inputsAll[i].data == "Arrow Left") {
              this.inputsAll[i].hide = false;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
            if (this.inputsAll[i].data == "Arrow Right") {
              this.inputsAll[i].hide = true;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
            if (this.inputsAll[i].data == "Timer") {
              this.inputsAll[i].hide = false;
              this.inputsAll[i].seconds = "50";
              this.inputsAll[i].totalSeconds = 50;
              this.inputsAll[i].source = "50";
              this.inputsAll[i].time = "0:0:50";
              this.inputsAll[i].style.time = this.time;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
          }
          break;
        case "br":
          this.audio.push("YOUR-TEAM-IS-BAN.wav");
          this.audioType.push('none');

          for (var i = 0; i < this.inputsAll.length; i++) {
            if (this.inputsAll[i].data == "Title BanPick") {
              this.inputsAll[i].source = "BAN";
              // this.inputsAll[i].style.color = "white";
              this.inputsAll[i].hide = false;
              this.inputsAll[i].time = this.time;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
            if (this.inputsAll[i].data == "Arrow Left") {
              this.inputsAll[i].hide = true;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
            if (this.inputsAll[i].data == "Arrow Right") {
              this.inputsAll[i].hide = false;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
            if (this.inputsAll[i].data == "Timer") {
              this.inputsAll[i].hide = false;
              this.inputsAll[i].seconds = "50";
              this.inputsAll[i].totalSeconds = 50;
              this.inputsAll[i].source = "50";
              this.inputsAll[i].time = "0:0:50";
              this.inputsAll[i].style.time = this.time;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
          }
          break;
        case "ha":

          for (var i = 0; i < this.inputsAll.length; i++) {
            if (this.inputsAll[i].data == "Title BanPick") {
              this.inputsAll[i].hide = true;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
            if (this.inputsAll[i].data == "Arrow Left") {
              this.inputsAll[i].hide = true;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
            if (this.inputsAll[i].data == "Arrow Right") {
              this.inputsAll[i].hide = true;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
            if (this.inputsAll[i].data == "Timer") {
              this.inputsAll[i].hide = true;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
          }
          break;
        case "ad":
          for (var i = 0; i < this.inputsAll.length; i++) {
            if (this.inputsAll[i].data == "Title BanPick") {
              this.inputsAll[i].source = "ADJUSTMENT";
              // this.inputsAll[i].style.color = "white";
              this.inputsAll[i].hide = false;
              this.inputsAll[i].time = this.time;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
            if (this.inputsAll[i].data == "Arrow Left") {
              this.inputsAll[i].hide = true;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
            if (this.inputsAll[i].data == "Arrow Right") {
              this.inputsAll[i].hide = true;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
            if (this.inputsAll[i].data == "Timer") {
              this.inputsAll[i].hide = false;
              this.inputsAll[i].seconds = "30";
              this.inputsAll[i].totalSeconds = 30;
              this.inputsAll[i].source = "30";
              this.inputsAll[i].time = "0:0:30";
              this.inputsAll[i].style.time = this.time;
              if (this.inputsAll[i].group != "") {
                var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
                this.LoopAddUpdate(overlay);
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
          }
          break;
        case "cd":
          this.audio.push("COUNTDOWN-SOUND.wav");
          this.audioType.push('none');
          break;
        case "na":
          this.audio = [];
          this.audioType = [];
          break;
        case "l1":
          this.pickSlot = "pickL1";
          break;
        case "l2":
          this.pickSlot = "pickL2";
          break;
        case "l3":
          this.pickSlot = "pickL3";
          break;
        case "r1":
          this.pickSlot = "pickR1";
          break;
        case "r2":
          this.pickSlot = "pickR2";
          break;
        case "r3":
          this.pickSlot = "pickR3";
          break;
        case "r4":
          this.pickSlot = "pickR4";
          break;

      }
    },

    BtnGold(val) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (val == 1) {
          if (String(this.inputsAll[i].data).match(/^Gold.*$/)) {
            this.inputsAll[i].hide = true;
            if (this.inputsAll[i].group != "") {
              var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
              this.LoopAddUpdate(overlay);
            } else {
              this.LoopAddUpdate(this.inputsAll[i]);
            }
          }
          if (String(this.inputsAll[i].data).match(/^Up.*$/)) {
            this.inputsAll[i].hide = false;
            if (this.inputsAll[i].group != "") {
              var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
              this.LoopAddUpdate(overlay);
            } else {
              this.LoopAddUpdate(this.inputsAll[i]);
            }
          }
        } else {
          if (String(this.inputsAll[i].data).match(/^Gold.*$/)) {
            this.inputsAll[i].hide = false;
            if (this.inputsAll[i].group != "") {
              var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
              this.LoopAddUpdate(overlay);
            } else {
              this.LoopAddUpdate(this.inputsAll[i]);
            }
          }
          if (String(this.inputsAll[i].data).match(/^Up.*$/)) {
            this.inputsAll[i].hide = true;
            if (this.inputsAll[i].group != "") {
              var overlay = this.inputs.find(x => x.id === this.inputsAll[i].group);
              this.LoopAddUpdate(overlay);
            } else {
              this.LoopAddUpdate(this.inputsAll[i]);
            }
          }
        }
      }
    },

    ChangeImageType(id, evt) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == id) {
          this.inputsAll[i].style.type = evt.value;
          this.inputsAll[i].style.time = this.time;

          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
        }
      }
    },
    ChangeHeroLink(id, evt) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == id) {
          this.inputsAll[i].link = evt.value;
          this.inputsAll[i].style.time = this.time;

          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
        }
      }
    },
    ChangeDataList(id, evt) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == id) {
          this.inputsAll[i].data = evt.value;
          this.inputsAll[i].style.time = this.time;

          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
        }
      }
    },
    ChangeSwap(id, evt) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == id) {
          this.inputsAll[i].style.swap = evt;
          this.inputsAll[i].style.time = this.time;

          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
        }
      }
    },

    ChangeOpacity(id) {
      let delay = 500,
        self = this;

      setTimeout(() => {
        for (var i = 0; i < self.inputsAll.length; i++) {
          if (self.inputsAll[i].id == id) {
            self.inputsAll[i].style.opacity = self.overlay.style.opacity;
            self.inputsAll[i].style.time = self.time;

            if (self.inputsAll[i].group != "") {
              for (var j = 0; j < self.inputs.length; j++) {
                if (self.inputs[j].id == self.inputsAll[i].group) {
                  self.LoopAddUpdate(self.inputs[j]);
                }
              }
            } else {
              self.LoopAddUpdate(self.inputsAll[i]);
            }
          }
        }
      }, delay);
    },

    ChangePercent(id) {
      let delay = 500,
        self = this;

      setTimeout(() => {
        for (var i = 0; i < self.inputsAll.length; i++) {
          if (self.inputsAll[i].id == id) {
            self.inputsAll[i].style.percent = self.overlay.style.percent;
            self.inputsAll[i].style.time = self.time;

            if (self.inputsAll[i].group != "") {
              for (var j = 0; j < self.inputs.length; j++) {
                if (self.inputs[j].id == self.inputsAll[i].group) {
                  self.LoopAddUpdate(self.inputs[j]);
                }
              }
            } else {
              self.LoopAddUpdate(self.inputsAll[i]);
            }
          }
        }
      }, delay);
    },

    ChangeRotate(id, evt) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == id) {
          this.inputsAll[i].style.rotate = evt.value;
          this.inputsAll[i].style.time = this.time;

          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
        }
      }
    },

    ChangeAlign(id, evt) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == id) {
          this.inputsAll[i].style.align = evt.value;
          this.inputsAll[i].style.time = this.time;
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
        }
      }
    },

    ChangeBanPick(id, evt) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == id) {
          this.inputsAll[i].banPick = evt.value;
          this.inputsAll[i].style.time = this.time;
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
        }
      }
    },

    ClearImage(id) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == id) {
          this.inputsAll[i].source = '';
          this.inputsAll[i].style.blink = false;
          this.inputsAll[i].style.time = this.time;
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
          if (this.inputsAll[i].link != '') {
            for (var j = 0; j < this.heroLinkList.length; j++) {
              if (this.heroLinkList[j].id == this.inputsAll[i].link) {
                this.heroLinkList[j].source = '';
                this.heroLinkList[j].name = '';
              }
            }
          }
        }
      }
    },

    ClearIntroImage(id) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == id) {
          this.inputsAll[i].style.introSource = '';
          this.inputsAll[i].style.time = this.time;
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
        }
      }
    },

    SwapImage(id, src, image_type, type) {
      this.swap = true;
      this.firstId = id;
      this.firstSource = src;
      this.firstImageType = image_type;
      this.firstType = type;
      var overlay = this.inputsAll.find(x => x.id === id);
      if (overlay) overlay.style.delay = 0;
      this.HidePopup();
    },

    SwapItem(id, src) {
      if (this.swapItem == false) {
        var title = '';
        this.swapItemList = [];
        this.swapItem = true;
        for (var i = 0; i < this.heroLinkList.length; i++) {
          if (this.heroLinkList[i].id == src) {
            title = this.heroLinkList[i].title;
          }
        }
        for (var i = 0; i < this.heroLinkList.length; i++) {
          if (this.heroLinkList[i].title.indexOf(title.slice(0, title.length - 1)) > -1) {
            for (var j = 0; j < this.inputsAll.length; j++) {
              if (this.inputsAll[j].link != '' && this.inputsAll[j].link != null) {
                if (this.inputsAll[j].link == this.heroLinkList[i].id) {
                  this.swapItemList.push({
                    id: this.inputsAll[j].id,
                    source: this.inputsAll[j].source
                  });
                }
              }
            }
          }
        }
        this.HidePopup();
      }
      else {
        this.swapItem = false;
        var title = "";
        var idList = [];
        var newSwapItemList = [];
        this.swapItem = false;
        for (var i = 0; i < this.inputsAll.length; i++) {
          if (this.inputsAll[i].link != '' && this.inputsAll[i].link != null) {
            if (this.inputsAll[i].id == id) {
              for (var j = 0; j < this.heroLinkList.length; j++) {
                if (this.heroLinkList[j].id == this.inputsAll[i].link) {
                  title = this.heroLinkList[j].title;
                }
              }
            }
          }
        }
        for (var i = 0; i < this.heroLinkList.length; i++) {
          if (this.heroLinkList[i].title.indexOf(title.slice(0, title.length - 1)) > -1) {
            idList.push(this.heroLinkList[i].id);
          }
        }
        for (var j = 0; j < idList.length; j++) {
          for (var k = 0; k < this.inputsAll.length; k++) {
            if (this.inputsAll[k].link != '' && this.inputsAll[k].link != null) {
              if (this.inputsAll[k].link == idList[j]) {
                newSwapItemList.push(this.inputsAll[k].source);
                this.inputsAll[k].source = this.swapItemList[j].source;
                if (this.inputsAll[k].group != "") {
                  for (var j = 0; j < this.inputs.length; j++) {
                    if (this.inputs[j].id == this.inputsAll[k].group) {
                      this.LoopAddUpdate(this.inputs[j]);
                    }
                  }
                } else {
                  this.LoopAddUpdate(this.inputsAll[k]);
                }
              }
            }
          }
        }
        for (var i = 0; i < this.swapItemList.length; i++) {
          for (var j = 0; j < this.inputsAll.length; j++) {
            if (this.inputsAll[j].link != '' && this.inputsAll[j].link != null) {
              if (this.inputsAll[j].id == this.swapItemList[i].id) {
                this.inputsAll[j].source = newSwapItemList[i];
                if (this.inputsAll[j].group != "") {
                  for (var k = 0; k < this.inputs.length; k++) {
                    if (this.inputs[k].id == this.inputsAll[j].group) {
                      this.LoopAddUpdate(this.inputs[k]);
                    }
                  }
                } else {
                  this.LoopAddUpdate(this.inputsAll[j]);
                }
              }
            }
          }
        }
        this.HidePopup();
      }
    },


    ShowPopup() {
      if (this.overlay != '') {
        this.$refs.op.show(event);
        if (this.overlay.type == 'image' || this.overlay.type == 'video') {
          this.$nextTick(function () {
            this.$refs.inputRef.clearInput();
            this.$refs.inputRef.focusInput();
          });
        }
      }
      else {
        for (var i = 0; i < this.inputs.length; i++) {
          if (this.inputs[i].id == this.groupOverlay[0].id) {
            this.Hide(this.inputs[i].id, this.groupOverlay[0].id);
            this.LoopAddUpdate(this.inputs[i]);
          }
        }
      }
    },

    HidePopup() {
      this.$refs.op.hide(event);
    },

    GetNow() {
      var date = new Date();
      this.time = date.getTime();
    },

    checkMove() {
      this.deact();
    },

    async GetOverlay(val, live) {
      const token = this.$store.state.token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      var data = {
        id: val,
      };

      await axios.post("get_overlay_by_id", data, config).then((resp) => {

        this.inputs = JSON.parse(resp.data.data.component);
        this.lists = this.listsAll.reduce((res, curr) => {
          if (res[curr.style.group])
            res[curr.style.group].push(curr);
          else
            Object.assign(res, { [curr.style.group]: [curr] });

          return res;
        }, {});
        this.linkIds = resp.data.link;
        this.next = resp.data.data.next_screen;
        this.separate = resp.data.data.separate;
        this.auto = resp.data.data.auto;
        if (live != '') {
          var id = this.decrypt(localStorage.getItem("id"));
          var date = new Date();
          const time =
            date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
          set(ref(db, "overlay/"), {
            overlays: this.inputs,
            time: time,
          });
        }

        if (this.linkIds.length > 0) {
          this.LoopLink();
        }
        var id1 = this.counter,
          id2 = this.counter;
        for (var i = 0; i < this.inputs.length; i++) {
          if (this.counter < this.inputs[i].id) {
            this.counter = this.inputs[i].id;
            id1 = this.inputs[i].id;
          }
        }
        for (var i = 0; i < this.inputsAll.length; i++) {
          if (this.inputsAll[i].type == "image") {
            if (this.delay < this.inputsAll[i].style.delay) {
              this.delay = this.inputsAll[i].style.delay;
            }
          }
          if (this.counter < this.inputsAll[i].id) {
            this.counter = this.inputsAll[i].id;
            id2 = this.inputsAll[i].id;
          }
        }
        id1 > id2 ? this.counter = id1 : this.counter = id2;
        this.layerName = resp.data.data.title;
        this.status = resp.data.data.status;
        this.focus = true;
        if (resp.data.data.status == 0) {
          this.focus = false;
        }
        this.overlayType = resp.data.data.type;
        if (this.delay == 0) {
          this.showElement = true;
        }
        else {
          this.delayedShow(this.delay);
        }
      }).catch((err) => {
        this.CatchError(err);
      });
    },

    delayedShow(idx) {
      let delay = 1500 * idx,
        self = this;

      setTimeout(() => {
        self.showElement = true
      }, delay);
    },

    LoopLink() {
      for (var i = 0; i < this.heroLinkList.length; i++) {
        for (var j = 0; j < this.inputsAll.length; j++) {
          if (this.inputsAll[j].link == this.heroLinkList[i].id) {
            this.heroLinkList[i].type = this.inputsAll[j].type;
            if (this.inputsAll[j].type == 'video') {
              this.heroLinkList[i].source = this.inputsAll[j].source;
              this.heroLinkList[i].name = '';
              switch (this.inputsAll[j].style.type) {
                case 0:
                  this.heroLinkList[i].name = this.inputsAll[j].source.slice(0, -5);
                  break;
                case 1:
                  if (this.inputsAll[j].source != '' && this.inputsAll[j].source != null) {
                    this.heroLinkList[i].name = this.inputsAll[j].source.slice(0, -10);
                  }
                  break;
              }
            } else {
              this.heroLinkList[i].source = this.inputsAll[j].source;
              this.heroLinkList[i].name = '';
              switch (this.inputsAll[j].style.type) {
                case 0:
                  this.heroLinkList[i].name = this.inputsAll[j].source.slice(0, -4);
                  break;
                case 1:
                  if (this.inputsAll[j].source != '' && this.inputsAll[j].source != null) {

                    this.heroLinkList[i].name = this.inputsAll[j].source.slice(0, -11);
                  }
                  break;
                case 2:
                  if (this.inputsAll[j].source != '' && this.inputsAll[j].source != null) {

                    this.heroLinkList[i].name = this.inputsAll[j].source.slice(0, -9);
                  }
                  break;
                case 3:
                  if (this.inputsAll[j].source != '' && this.inputsAll[j].source != null) {

                    this.heroLinkList[i].name = this.inputsAll[j].source.slice(0, -9);
                  }
                  break;
                case 4:
                  if (this.inputsAll[j].source != '' && this.inputsAll[j].source != null) {

                    this.heroLinkList[i].name = this.inputsAll[j].source.slice(0, -8);
                  }
                  break;
                case 5:
                  if (this.inputsAll[j].source != '' && this.inputsAll[j].source != null) {

                    this.heroLinkList[i].name = this.inputsAll[j].source;
                  }
                  break;
              }
            }
          }
        }
      }
    },
    move(id) {
      // var index = this.inputs.findIndex((x) => x.id === id);
      // var self = this;
      // var key = id;
      // document.addEventListener("keydown", function (e) {
      //     if (self.inputs[index].id == key) {
      //       switch (e.keyCode) {
      //         case 37:
      //           self.inputs[index].x -= 1;
      //           break;
      //         case 38:
      //           if (self.inputs[index].active) self.inputs[index].y -= 1;
      //           break;
      //         case 39:
      //           if (self.inputs[index].active) self.inputs[index].x += 1;
      //           break;
      //         case 40:
      //           if (self.inputs[index].active) self.inputs[index].y += 1;
      //           break;
      //       }
      //     }
      // });
    },

    TransitionOut(next = '') {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].type == "image") {
          this.inputsAll[i].style.transition = this.inputsAll[i].style.transitionOut;
          this.inputsAll[i].style.delay = 0;
          this.inputsAll[i].style.time = this.time + i;
          if (next == 'next') {
            if (this.inputsAll[i].group != "") {
              for (var j = 0; j < this.inputs.length; j++) {
                if (this.inputs[j].id == this.inputsAll[i].group) {
                  this.LoopAddUpdate(this.inputs[j]);
                }
              }
            } else {
              this.LoopAddUpdate(this.inputsAll[i]);
            }
          }
        }
        else {
          this.inputsAll[i].hide = true;
          this.inputsAll[i].style.time = this.time + i;
          if (next == 'next') {
            if (this.inputsAll[i].group != "") {
              for (var j = 0; j < this.inputs.length; j++) {
                if (this.inputs[j].id == this.inputsAll[i].group) {
                  this.LoopAddUpdate(this.inputs[j]);
                }
              }
            } else {
              this.LoopAddUpdate(this.inputsAll[i]);
            }
          }
        }
      }
    },

    TransitionIn(next = '') {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].type == "image") {
          this.inputsAll[i].style.transition = this.inputsAll[i].style.transitionIn;
          this.inputsAll[i].style.delay = this.inputsAll[i].style.delayIn;
          this.inputsAll[i].style.time = this.time + i;
          if (next == 'next') {
            if (this.inputsAll[i].group != "") {
              for (var j = 0; j < this.inputs.length; j++) {
                if (this.inputs[j].id == this.inputsAll[i].group) {
                  this.LoopAddUpdate(this.inputs[j]);
                }
              }
            } else {
              this.LoopAddUpdate(this.inputsAll[i]);
            }
          }
        }
        else {
          this.inputsAll[i].hide = false;
          this.inputsAll[i].style.time = this.time + i;
          if (next == 'next') {
            if (this.inputsAll[i].group != "") {
              for (var j = 0; j < this.inputs.length; j++) {
                if (this.inputs[j].id == this.inputsAll[i].group) {
                  this.LoopAddUpdate(this.inputs[j]);
                }
              }
            } else {
              this.LoopAddUpdate(this.inputsAll[i]);
            }
          }
        }
      }
    },

    async Save(val) {
      this.disSave = true;
      var event_id = this.decrypt(localStorage.getItem('event_id'));
      var id = this.decrypt(localStorage.getItem('id'));
      var overlay_id = this.decrypt(this.id);
      const token = this.$store.state.token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      if (val == "save") {
        var status = this.status;
        var auto = this.auto
      }
      else if (val == "show") { // show overlay
        var status = 1;
        var auto = 0;
        this.isLive = true;
      }
      else { // hide overlay
        this.isLive = false;
        var auto = 1;

        if (this.separate == 0 || this.separate == "0") {
          this.TransitionOut();
          var status = 0;
          var date = new Date();
          var time =
            date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
          set(ref(db, "overlay/"), {
            overlays: this.inputs,
            time: time,
          });
          this.TransitionIn();
          var id = this.decrypt(localStorage.getItem('id'));

        } else {
          this.TransitionOut();
          var status = 0;
          var overlay_id = this.decrypt(this.id);
          var date = new Date();
          var time =
            date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
          set(ref(db, overlay_id + "/"), {
            overlays: this.inputs,
            time: time,
          });
          this.TransitionIn();
          var id = this.decrypt(localStorage.getItem('id'));
        }
      }
      this.deact();

      if (this.id != null) {
        var id = this.decrypt(this.id);

        var data = {
          id: id,
          title: this.layerName,
          component: JSON.stringify(this.updateList),
          status: status,
          event_id: event_id,
          type: this.overlayType,
          background: false,
          audio: this.audio,
          audio_type: this.audioType,
          pick_slot: this.pickSlot,

          // auto: this.auto
        };
        await axios.post("store_overlay", data, config).then((resp) => {

          if (resp.data.status == "success") {
            this.updateList = [];
            this.updateListLink = [];
            this.audio = [];
            this.audioType = [];
            this.pickSlot = "";
            this.GetOverlay(resp.data.data.id, '');
            if (this.linkIds != '') {
              var data = {
                ids: this.linkIds,
              }

              axios.post("get_multi_by_id", data, config).then((resp) => {
                if (resp.data.status == "success") {
                  this.multiOverlays = resp.data.data;
                  for (var i = 0; i < this.multiOverlays.length; i++) {
                    this.updateList = [];
                    var comp = JSON.parse(this.multiOverlays[i].component);
                    var overlay = this.Merge(comp);
                    var run = false;
                    for (var j = 0; j < overlay.length; j++) {
                      for (var k = 0; k < this.heroLinkList.length; k++) {
                        if (overlay[j].link == this.heroLinkList[k].id) {
                          // overlay[j].source = '';
                          if (overlay[j].type == 'video') {
                            switch (overlay[j].style.type) {
                              case 0:
                                if (this.heroLinkList[k].source != '') {
                                  if (this.heroLinkList[k].name + '.webm' != overlay[j].source) {
                                    overlay[j].source = this.heroLinkList[k].name + '.webm';
                                    overlay[j].style.time = this.time;
                                    this.LoopAddUpdateLink(overlay[j]);
                                  }
                                }
                                else {
                                  overlay[j].source = null;
                                  overlay[j].style.time = this.time;
                                  this.LoopAddUpdateLink(overlay[j]);

                                }
                                break;
                              case 1:
                                if (this.heroLinkList[k].source != '') {
                                  if (this.heroLinkList[k].name + '_tall.webm' != overlay[j].source) {
                                    overlay[j].source = this.heroLinkList[k].name + '_tall.webm';
                                    overlay[j].style.time = this.time;
                                    this.LoopAddUpdateLink(overlay[j]);

                                  }
                                } else {
                                  overlay[j].source = null;
                                  overlay[j].style.time = this.time;
                                  this.LoopAddUpdateLink(overlay[j]);

                                }
                                break;
                            }
                          }
                          else {
                            switch (overlay[j].style.type) {
                              case 0:
                                if (this.heroLinkList[k].source != '') {
                                  if (this.heroLinkList[k].name + '.jpg' != overlay[j].source) {
                                    overlay[j].source = this.heroLinkList[k].name + '.jpg';
                                    overlay[j].style.time = this.time;
                                    this.LoopAddUpdateLink(overlay[j]);

                                  }
                                } else {
                                  overlay[j].source = null;
                                  overlay[j].style.time = this.time;
                                  this.LoopAddUpdateLink(overlay[j]);

                                }
                                break;
                              case 1:
                                if (this.heroLinkList[k].source != '') {
                                  if (this.heroLinkList[k].name + '_square.jpg' != overlay[j].source) {
                                    overlay[j].source = this.heroLinkList[k].name + '_square.jpg';
                                    overlay[j].style.time = this.time;
                                    this.LoopAddUpdateLink(overlay[j]);

                                  }
                                } else {
                                  overlay[j].source = null;
                                  overlay[j].style.time = this.time;
                                  this.LoopAddUpdateLink(overlay[j]);

                                }
                                break;
                              case 2:
                                if (this.heroLinkList[k].source != '') {
                                  if (this.heroLinkList[k].name + '_wide.jpg' != overlay[j].source) {
                                    overlay[j].source = this.heroLinkList[k].name + '_wide.jpg';
                                    overlay[j].style.time = this.time;
                                    this.LoopAddUpdateLink(overlay[j]);

                                  }
                                } else {
                                  overlay[j].source = null;
                                  overlay[j].style.time = this.time;
                                  this.LoopAddUpdateLink(overlay[j]);

                                }
                                break;
                              case 3:
                                if (this.heroLinkList[k].source != '') {
                                  if (this.heroLinkList[k].name + '_tall.jpg' != overlay[j].source) {
                                    overlay[j].source = this.heroLinkList[k].name + '_tall.jpg';
                                    overlay[j].style.time = this.time;
                                    this.LoopAddUpdateLink(overlay[j]);

                                  }
                                } else {
                                  overlay[j].source = null;
                                  overlay[j].style.time = this.time;
                                  this.LoopAddUpdateLink(overlay[j]);

                                }
                                break;
                              case 4:
                                if (this.heroLinkList[k].source != '') {
                                  if (this.heroLinkList[k].name + '_3x2.jpg' != overlay[j].source) {
                                    overlay[j].source = this.heroLinkList[k].name + '_3x2.jpg';
                                    overlay[j].style.time = this.time;
                                    this.LoopAddUpdateLink(overlay[j]);

                                  }
                                } else {
                                  overlay[j].source = null;
                                  overlay[j].style.time = this.time;
                                  this.LoopAddUpdateLink(overlay[j]);

                                }
                                break;
                              case 5:
                                if (this.heroLinkList[k].source != '') {
                                  if (this.heroLinkList[k].name + '.jpg' != overlay[j].source) {
                                    overlay[j].source = this.heroLinkList[k].source;
                                    overlay[j].style.time = this.time;
                                    this.LoopAddUpdateLink(overlay[j]);

                                  }
                                } else {
                                  overlay[j].source = null;
                                  overlay[j].style.time = this.time;
                                  this.LoopAddUpdateLink(overlay[j]);

                                }
                                break;
                              default:
                                overlay[j].source = '';
                            }

                          }
                        }
                      }
                      if (j == overlay.length - 1 || k == this.heroLinkList.length - 1) {
                        run = true;
                      }
                    }
                    if (run == true && this.updateLinkList != "") {
                      var data = {
                        id: this.multiOverlays[i].id,
                        title: this.multiOverlays[i].title,
                        component: JSON.stringify(this.heroLinkList),
                        status: this.multiOverlays[i].status,
                        type: this.multiOverlays[i].type,
                        background: true,
                        event_id: event_id,
                      };
                      axios.post("store_overlay", data, config).then((resp) => {
                        if (resp.data.status == "success") {
                          this.updateList = [];
                          this.updateLinkList = [];
                          this.disSave = false;
                          this.Toast("success", "Merge Saved", "");
                        }
                      }).catch((err) => {
                        this.CatchError(err);
                      });
                    } else {
                      this.Toast("success", "Merge Saved", "");
                      this.updateList = [];
                      this.updateLinkList = [];
                      this.disSave = false;

                    }
                  }
                }
              }).catch((err) => {
                this.CatchError(err);
              });

            }
            else {
              this.disSave = false;
              this.Toast("success", "Saved", "");
            }
          } else {
            this.updateList = [];
            this.updateLinkList = [];
            this.disSave = false;
            this.Toast("error", "Fail!", resp.data.message);
          }
        }).catch((err) => {
          this.CatchError(err);
        });
      } else {
        var data = {
          title: this.layerName,
          component: JSON.stringify(this.inputs),
          status: status,
          event_id: event_id,
          type: this.overlayType,
          background: false,
        };
        await axios.post("store_overlay", data, config).then((resp) => {
          if (resp.data.status == "success") {
            this.updateList = [];
            this.updateLinkList = [];
            this.disSave = false;
            var key = this.encrypt(resp.data.data.id.toString());
            this.$router.push({
              name: "overlay-editor",
              query: { data: key },
            });
            this.id = key;
            this.Toast("success", "Saved", "");
            this.GetOverlay(resp.data.data.id, '');

          } else {
            this.disSave = false;
            this.Toast("error", "Fail!", resp.data.message);
          }
        }).catch((err) => {
          this.CatchError(err);
        });
      }
    },

    Separate() {
      var string = this.decrypt(this.id);
      var token = this.$store.state.token;
      var newToken = string + "mmm" + token;
      let routeData = this.$router.resolve({
        name: "live",
        query: { data: newToken },
      });
      var url = window.location.origin + routeData.href;
      try {
        navigator.clipboard.writeText(url);
        this.Toast("success", "Copied", "");
      }
      catch ($e) {
        this.Toast("fail", "Cannot copy", "");
      }
    },

    CatchError(err) {
      try {
        if (err.response.statusText == 'Unauthorized') {
          localStorage.removeItem('token');
          localStorage.removeItem('username');
          localStorage.removeItem('id');
          localStorage.removeItem('event_id');
          this.$router.push('login');
        } else {
          console.log(err.response.statusText);
        }
      } catch ($e) {
        console.log(err);
      }
    },

    // Get() {
    //   const token = this.$store.state.token;
    //   const config = {
    //     headers: { Authorization: `Bearer ${token}` },
    //   };
    //   var data = {
    //     id: this.decrypt(this.id),
    //   };

    //   axios.post("get_overlay_by_id", data, config).then((resp) => {

    //     this.inputs = JSON.parse(resp.data.data.component);
    //     for (var i = 0; i < this.inputsAll.length; i++) {
    //       if (this.inputsAll[i].hasOwnProperty('data')) {
    //         if (this.inputsAll[i].data != null) {
    //           this.inputsAll[i].source = this.inputsAll[i].autoSource;
    //           if (this.inputsAll[i].group != "") {
    //             for (var j = 0; j < this.inputs.length; j++) {
    //               if (this.inputs[j].id == this.inputsAll[i].group) {
    //                 this.LoopAddUpdate(this.inputs[j]);
    //               }
    //             }
    //           } else {
    //             this.LoopAddUpdate(this.inputsAll[i]);
    //           }
    //         }
    //       }
    //     }
    //   }).catch((err) => {
    //     this.CatchError(err);
    //   });
    // },

    Lock(id, group) {
      if (group == "") {
        var overlay = this.inputs.find(x => x.id === id);
        if (overlay) {
          overlay.state = !overlay.state;
          this.LoopAddUpdate(overlay);
        }
      } else {
        if (id == group) {
          var overlay = this.inputs.find(x => x.id === id);
          if (overlay) {
            overlay.state = !overlay.state;
            this.LoopAddUpdate(overlay);
          }
          var overlay = this.inputsAll.find(x => x.group === group);
          if (overlay) {
            overlay.state = !overlay.state;
          }
        } else {
          for (var j = 0; j < this.inputsAll.length; j++) {
            if (this.inputsAll[j].id == id) {
              this.inputsAll[j].state = !this.inputsAll[j].state;
              if (this.inputsAll[i].group != "") {
                for (var j = 0; j < this.inputs.length; j++) {
                  if (this.inputs[j].id == this.inputsAll[i].group) {
                    this.LoopAddUpdate(this.inputs[j]);
                  }
                }
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
          }
        }
      }
    },

    Hide(id, group) {
      if (group == "") {
        for (var i = 0; i < this.inputs.length; i++) {
          if (this.inputs[i].id == id) {
            this.inputs[i].hide = !this.inputs[i].hide;
            this.inputs[i].style.time = this.time;
            this.LoopAddUpdate(this.inputs[i]);
          }
        }
      } else {
        if (id == group) {

          for (var i = 0; i < this.inputs.length; i++) {
            if (this.inputs[i].id == id) {
              this.inputs[i].hide = !this.inputs[i].hide;
              this.LoopAddUpdate(this.inputs[i]);
            }
          }
          for (var j = 0; j < this.inputsAll.length; j++) {
            if (this.inputsAll[j].group == group) {
              this.inputsAll[j].hide = !this.inputsAll[j].hide;
              this.inputsAll[j].style.time = this.time + j;
            }
          }
        } else {
          for (var j = 0; j < this.inputsAll.length; j++) {
            if (this.inputsAll[j].id == id) {
              this.inputsAll[j].hide = !this.inputsAll[j].hide;
              this.inputsAll[j].style.time = this.time;
              if (this.inputsAll[i].group != "") {
                for (var j = 0; j < this.inputs.length; j++) {
                  if (this.inputs[j].id == this.inputsAll[i].group) {
                    this.LoopAddUpdate(this.inputs[j]);
                  }
                }
              } else {
                this.LoopAddUpdate(this.inputsAll[i]);
              }
            }
          }
        }
      }
    },

    Grid(id, group) {
      if (group == "") {
        for (var i = 0; i < this.inputs.length; i++) {
          if (this.inputs[i].id == id) {
            this.inputs[i].grid = !this.inputs[i].grid;
            this.LoopAddUpdate(this.inputs[i]);
          }
        }
      } else {
        for (var j = 0; j < this.inputsAll.length; j++) {
          if (this.inputsAll[j].id == id) {
            this.inputsAll[j].grid = !this.inputsAll[j].grid;
            if (this.inputsAll[i].group != "") {
              for (var j = 0; j < this.inputs.length; j++) {
                if (this.inputs[j].id == this.inputsAll[i].group) {
                  this.LoopAddUpdate(this.inputs[j]);
                }
              }
            } else {
              this.LoopAddUpdate(this.inputsAll[i]);
            }
          }
        }
      }
    },

    Toggle(val) {
      var li = document.getElementById(val);
      if (li.parentElement.childNodes[1].classList == "") {
        li.parentElement.childNodes[1].classList.add("side-menu__sub-open");
        li.childNodes[1].childNodes[1].childNodes[0].classList.remove(
          "bi-chevron-up"
        );
        li.childNodes[1].childNodes[1].childNodes[0].classList.add(
          "bi-chevron-down"
        );
      } else if (
        li.parentElement.childNodes[1].classList == "side-menu__sub-open"
      ) {
        li.parentElement.childNodes[1].classList.remove("side-menu__sub-open");
        li.childNodes[1].childNodes[1].childNodes[0].classList.remove(
          "bi-chevron-down"
        );
        li.childNodes[1].childNodes[1].childNodes[0].classList.add(
          "bi-chevron-up"
        );
      }
    },

    toggleBtn(event) {
      this.$refs.menu.toggle(event);
    },

    // zoomIn() {
    //   var myImg = document.getElementById("editor");
    //   if (this.index < 1.4) {
    //     this.index += 0.1;
    //     myImg.style.transform = `scale(${this.index})`;
    //     myImg.style.transformOrigin = "top left";
    //   }
    // },

    // zoomOut() {
    //   var myImg = document.getElementById("editor");
    //   if (this.index > 0.7) {
    //     this.index -= 0.1;
    //     myImg.style.transform = `scale(${this.index})`;
    //     myImg.style.transformOrigin = "top left";
    //   }
    // },

    // reset() {
    //   var myImg = document.getElementById("editor");
    //   myImg.style.transform = "scale(1)";
    //   myImg.style.transformOrigin = "top left";
    //   this.index = 1;
    // },

    clicked(id, event, group, src, type, swap, types = '') {
      this.groupOverlay = [];
      this.overlay = [];
      this.showGroup = false;
      this.showUngroup = false;

      if (this.swap == true && swap == true) {
        this.swap = false;

        if (this.firstId != id) {
          if (this.firstType === types) {
            if (this.firstType == 'image') {
              // this.LoopLink();
              for (var i = 0; i < this.inputsAll.length; i++) {
                if (this.inputsAll[i].id == this.firstId) {
                  if (this.inputsAll[i].style.swap == true) {
                    this.inputsAll[i].style.time = this.time;
                    this.inputsAll[i].style.swapState = true;
                    var source = "";
                    switch (type) {
                      case 0:
                        source = src;
                        break;
                      case 1:
                        source = src.slice(0, -11) + '.jpg';
                        break;
                      case 2:
                        source = src.slice(0, -9) + '.jpg';
                        break;
                      case 3:
                        source = src.slice(0, -9) + '.jpg';
                        break;
                      case 4:
                        source = src.slice(0, -8) + '.jpg';
                        break;
                    }
                    switch (this.firstImageType) {
                      case 0:
                        this.inputsAll[i].source = source;
                        break;
                      case 1:
                        this.inputsAll[i].source = source.slice(0, -4) + '_square.jpg';
                        break;
                      case 2:
                        this.inputsAll[i].source = source.slice(0, -4) + '_wide.jpg';
                        break;
                      case 3:
                        this.inputsAll[i].source = source.slice(0, -4) + '_tall.jpg';
                        break;
                      case 4:
                        this.inputsAll[i].source = source.slice(0, -4) + '_3x2.jpg';
                        break;

                    }
                    if (this.inputsAll[i].group != "") {
                      for (var j = 0; j < this.inputs.length; j++) {
                        if (this.inputs[j].id == this.inputsAll[i].group) {
                          this.LoopAddUpdate(this.inputs[j]);
                        }
                      }
                    } else {
                      this.LoopAddUpdate(this.inputsAll[i]);
                    }
                  }
                }
                if (this.inputsAll[i].id == id) {
                  this.inputsAll[i].style.time = this.time + 1;
                  this.inputsAll[i].style.swapState = true;
                  this.inputsAll[i].style.delay = 1;
                  var source = "";
                  switch (this.firstImageType) {
                    case 0:
                      source = this.firstSource;
                      break;
                    case 1:
                      source = this.firstSource.slice(0, -11) + '.jpg';
                      break;
                    case 2:
                      source = this.firstSource.slice(0, -9) + '.jpg';
                      break;
                    case 3:
                      source = this.firstSource.slice(0, -9) + '.jpg';
                      break;
                    case 4:
                      source = this.firstSource.slice(0, -8) + '.jpg';
                      break;
                  }
                  switch (type) {
                    case 0:
                      this.inputsAll[i].source = source;
                      break;
                    case 1:
                      this.inputsAll[i].source = source.slice(0, -4) + '_square.jpg';
                      break;
                    case 2:
                      this.inputsAll[i].source = source.slice(0, -4) + '_wide.jpg';
                      break;
                    case 3:
                      this.inputsAll[i].source = source.slice(0, -4) + '_tall.jpg';
                      break;
                    case 4:
                      this.inputsAll[i].source = source.slice(0, -4) + '_3x2.jpg';
                      break;
                  }
                  if (this.inputsAll[i].group != "") {
                    for (var j = 0; j < this.inputs.length; j++) {
                      if (this.inputs[j].id == this.inputsAll[i].group) {
                        this.LoopAddUpdate(this.inputs[j]);
                      }
                    }
                  } else {
                    this.LoopAddUpdate(this.inputsAll[i]);
                  }
                }
              }
              this.LoopLink();
            }
            else if (this.firstType == 'video') {
              for (var i = 0; i < this.inputsAll.length; i++) {
                if (this.inputsAll[i].id == this.firstId) {
                  if (this.inputsAll[i].style.swap == true) {
                    this.inputsAll[i].style.time = this.time;
                    this.inputsAll[i].style.swapState = true;
                    this.inputsAll[i].source = src;
                    if (this.inputsAll[i].group != "") {
                      for (var j = 0; j < this.inputs.length; j++) {
                        if (this.inputs[j].id == this.inputsAll[i].group) {
                          this.LoopAddUpdate(this.inputs[j]);
                        }
                      }
                    } else {
                      this.LoopAddUpdate(this.inputsAll[i]);
                    }
                  }
                }
                if (this.inputsAll[i].id == id) {
                  this.inputsAll[i].style.time = this.time + 1;
                  this.inputsAll[i].style.delay = 1;
                  this.inputsAll[i].style.swapState = true;
                  this.inputsAll[i].source = this.firstSource;

                  if (this.inputsAll[i].group != "") {
                    for (var j = 0; j < this.inputs.length; j++) {
                      if (this.inputs[j].id == this.inputsAll[i].group) {
                        this.LoopAddUpdate(this.inputs[j]);
                      }
                    }
                  } else {
                    this.LoopAddUpdate(this.inputsAll[i]);
                  }
                }
              }
              this.LoopLink();
            }
          }
          else {
            this.swap = false;
          }
        }
        else {
          this.swap = false;
        }
      }
      else {
        this.swap = false;
        if (event.shiftKey == true && event.altKey == false) {
          // shift click

          if (this.altClick != 0) {
            this.deact();
            this.altClick = 0;
          }
          if (group == "") {
            for (var h = 0; h < this.inputs.length; h++) {
              if (this.inputs[h].id == id) {
                this.inputs[h].active = !this.inputs[h].active;
              }
            }
          } else {
            for (var i = 0; i < this.inputs.length; i++) {
              if (this.inputs[i].id == group) {
                this.inputs[i].active = !this.inputs[i].active;
              }
            }
            this.groupOverlay = [];
            for (var i = 0; i < this.inputsAll.length; i++) {
              if (this.inputsAll[i].group == group) {
                this.inputsAll[i].active = !this.inputsAll[i].active;
              }
            }
          }
          var array = [];
          for (var i = 0; i < this.inputs.length; i++) {
            if (this.inputs[i].active == true) {
              array.push(this.inputs[i]);
            }
          }

          var count = 0;
          for (var j = 0; j < array.length; j++) {
            if (array[j].type == "group") {
              count++;
            }
          }
          if (count == 0) {
            if (array.length == 1) {
              this.overlay = array[0];
              if (this.inputs.length == 1) {
                this.showUp = false;
                this.showDown = false;
              } else {
                for (var i = 0; i < this.inputs.length; i++) {
                  if (this.inputs[i].id == array[0].id) {
                    if (i == 0) {
                      this.showUp = false;
                      this.showDown = true;
                    } else if (i == this.inputs.length - 1) {
                      this.showUp = true;
                      this.showDown = false;
                    } else {
                      this.showUp = true;
                      this.showDown = true;
                    }
                  }
                }
              }
              this.showCopy = true;
              this.showDelete = true;
              this.showUngroup = false;
              this.showGroup = true;
            } else {
              this.groupOverlay = array;
              this.showUp = false;
              this.showDown = false;
              this.showCopy = true;
              this.showDelete = true;
              this.showUngroup = false;
              this.showGroup = true;
            }
          } else if (count == 1) {
            this.groupOverlay = array;
            if (array.length == 1) {
              this.showUngroup = true;
              this.showGroup = false;
              this.showCopy = true;
              this.showDelete = true;
              if (this.inputs.length == 1) {
                this.showUp = false;
                this.showDown = false;
              } else {
                for (var i = 0; i < this.inputs.length; i++) {
                  if (this.inputs[i].id == array[0].id) {
                    if (i == 0) {
                      this.showUp = false;
                      this.showDown = true;
                    } else if (i == this.inputs.length - 1) {
                      this.showUp = true;
                      this.showDown = false;
                    } else {
                      this.showUp = true;
                      this.showDown = true;
                    }
                  }
                }
              }
            } else {
              this.showUngroup = false;
              this.showGroup = true;
              this.showCopy = false;
              this.showDelete = true;
              this.showDown = false;
              this.showUp = false;
            }
          } else {
            this.groupOverlay = array;
            this.showCopy = false;
            this.showDelete = true;
            this.showGroup = false;
            this.showUngroup = false;
            this.showDown = false;
            this.showUp = false;
          }
        } else if (event.altKey == true && event.shiftKey == false) {
          // alt click

          this.deact();
          if (group == "") {
            for (var i = 0; i < this.inputs.length; i++) {
              if (this.inputs[i].id == id) {
                this.inputs[i].active = true;
                this.overlay = this.inputs[i];
                this.x = this.inputs[i].x;
                this.y = this.inputs[i].y;
                this.w = this.inputs[i].w;
                this.h = this.inputs[i].h;
                this.opacity = this.inputs[i].style.opacity;
                this.showGroup = true;
                this.showUngroup = false;
                this.showCopy = true;
                this.showDelete = true;
                if (i == 0) {
                  this.showUp = false;
                  this.showDown = true;
                } else if (i == this.inputs.length - 1) {
                  this.showUp = true;
                  this.showDown = false;
                } else {
                  this.showUp = true;
                  this.showDown = true;
                }
              }
            }
          } else {
            if (id == group) {
              this.groupOverlay = [];
              for (var i = 0; i < this.inputs.length; i++) {
                if (this.inputs[i].id == id) {
                  this.groupOverlay.push(this.inputs[i]);
                  this.inputs[i].active = true;
                  this.showGroup = false;
                  this.showUngroup = true;
                  this.showCopy = true;
                  this.showDelete = true;
                  if (i == 0) {
                    this.showUp = false;
                    this.showDown = true;
                  } else if (i == this.inputs.length - 1) {
                    this.showUp = true;
                    this.showDown = false;
                  } else {
                    this.showUp = true;
                    this.showDown = true;
                  }
                }
              }
              for (var i = 0; i < this.inputsAll.length; i++) {
                if (this.inputsAll[i].group == group) {
                  this.inputsAll[i].active = true;
                  var li = document.getElementById(this.inputsAll[i].group);
                  li.classList.forEach((element) => {
                    li.parentElement.childNodes[1].classList.add(
                      "side-menu__sub-open"
                    );
                    li.childNodes[1].childNodes[1].childNodes[0].classList.remove(
                      "bi-chevron-up"
                    );
                    li.childNodes[1].childNodes[1].childNodes[0].classList.add(
                      "bi-chevron-down"
                    );
                  });
                }
              }
            } else {
              this.deact();
              this.altClick++;
              for (var j = 0; j < this.inputs.length; j++) {
                if (this.inputs[j].id == group) {
                  for (var k = 0; k < this.inputs[j].element.length; k++) {
                    if (this.inputs[j].element[k].id == id) {
                      this.inputs[j].element[k].active = true;
                      this.overlay = this.inputs[j].element[k];
                      this.x = this.inputs[j].element[k].x;
                      this.y = this.inputs[j].element[k].y;
                      this.w = this.inputs[j].element[k].w;
                      this.h = this.inputs[j].element[k].h;
                      this.opacity = this.inputs[j].element[k].style.opacity;
                      this.showGroup = false;
                      this.showUngroup = true;
                      this.showCopy = true;
                      this.showDelete = true;
                      if (this.inputs[j].element.length == 1) {
                        this.showUp = false;
                        this.showDown = false;
                      } else {
                        if (k == 0) {
                          this.showUp = false;
                          this.showDown = true;
                        } else if (k == this.inputs[j].element.length - 1) {
                          this.showUp = true;
                          this.showDown = false;
                        } else {
                          this.showUp = true;
                          this.showDown = true;
                        }
                      }
                    }
                  }
                }
              }
              for (var i = 0; i < this.inputsAll.length; i++) {
                if (this.inputsAll[i].group == group) {
                  var li = document.getElementById(this.inputsAll[i].group);
                  li.classList.forEach((element) => {
                    li.parentElement.childNodes[1].classList.add(
                      "side-menu__sub-open"
                    );
                    li.childNodes[1].childNodes[1].childNodes[0].classList.remove(
                      "bi-chevron-up"
                    );
                    li.childNodes[1].childNodes[1].childNodes[0].classList.add(
                      "bi-chevron-down"
                    );
                  });
                }
              }
            }
          }
        } else {
          // normal click
          if (!this.drag) this.deact();
          if (group == "") {
            for (var i = 0; i < this.inputs.length; i++) {
              if (this.inputs[i].id == id) {
                this.inputs[i].active = true;
                this.overlay = this.inputs[i];
                this.x = this.inputs[i].x;
                this.y = this.inputs[i].y;
                this.w = this.inputs[i].w;
                this.h = this.inputs[i].h;
                // console.log(this.overlay);
                // console.log(this.groupList);
                // console.log(this.heroLinkList);
                this.opacity = this.inputs[i].style.opacity;
                this.showGroup = true;
                this.showUngroup = false;
                this.showCopy = true;
                this.showDelete = true;
                //btn top
                if (this.inputs.length == 1) {
                  this.showUp = false;
                  this.showDown = false;
                } else {
                  if (i == 0) {
                    this.showUp = false;
                    this.showDown = true;
                  } else if (i == this.inputs.length - 1) {
                    this.showUp = true;
                    this.showDown = false;
                  } else {
                    this.showUp = true;
                    this.showDown = true;
                  }
                }
              }
            }
          } else {
            this.showGroup = false;
            this.showUngroup = true;
            this.showCopy = true;
            this.showDelete = true;
            this.overlay = [];
            this.groupOverlay = [];

            for (var i = 0; i < this.inputs.length; i++) {
              if (this.inputs[i].id == group) {
                this.inputs[i].active = true;
                this.groupOverlay.push(this.inputs[i]);
                if (this.inputs.length == 1) {
                  this.showUp = false;
                  this.showDown = false;
                } else {
                  if (i == 0) {
                    this.showUp = false;
                    this.showDown = true;
                  } else if (i == this.inputs.length - 1) {
                    this.showUp = true;
                    this.showDown = false;
                  } else {
                    this.showUp = true;
                    this.showDown = true;
                  }
                }
              }
            }
            for (var i = 0; i < this.inputsAll.length; i++) {
              if (this.inputsAll[i].group == group) {
                this.inputsAll[i].active = true;
                this.childOverlay.push(this.inputsAll[i]);
              }
            }
          }
        }
      }
    },

    deact() {
      // this.toggle();
      this.drag = false;
      this.showGroup = false;
      this.showUngroup = false;
      this.showCopy = false;
      this.showDelete = false;
      this.showUp = false;
      this.showDown = false;
      if (this.inputs != "") {
        for (var i = 0; i < this.inputs.length; i++) {
          if (this.inputs[i].active == true) {
            this.inputs[i].active = false;
            this.overlay = [];
          }
        }
        for (var j = 0; j < this.inputsAll.length; j++) {
          if (this.inputsAll[j].active == true) {
            this.inputsAll[j].active = false;
            this.overlay = [];
          }
        }
      }
    },

    deleteCom() {
      if (this.overlay != "") {
        if (this.overlay.group == "") {
          for (var i = 0; i < this.inputs.length; i++) {
            if (this.inputs[i].id == this.overlay.id) {
              var id = [];
              id.push(this.inputs[i].id);
              var deleteId = {
                type: "delete",
                array: id,
                id: this.time
              };
              this.LoopAddUpdate(deleteId);
              this.inputs.splice(i, 1);
              this.overlay = [];

            }
          }
        } else {
          for (var i = 0; i < this.inputs.length; i++) {
            if (this.inputs[i].id == this.overlay.group) {
              for (var j = 0; j < this.inputs[i].element.length; j++) {
                if (this.inputs[i].element[j].id == this.overlay.id) {
                  this.LoopAddUpdate(this.inputs[i]);
                  this.inputs[i].element.splice(j, 1);
                  this.overlay = [];
                }
              }
            }
          }
        }
      }

      if (this.groupOverlay != "") {
        for (var i = 0; i < this.inputs.length; i++) {
          for (var j = 0; j < this.groupOverlay.length; j++) {
            if (this.inputs[i].id == this.groupOverlay[j].id) {
              var id = [];
              id.push(this.inputs[i].id);
              var deleteId = {
                type: "delete",
                array: id,
                id: this.time
              };
              this.LoopAddUpdate(deleteId);
              this.inputs.splice(i, 1);

            }
          }
        }
        this.groupOverlay = [];
      }
    },

    copyCom() {
      if (this.overlay != "") {
        if (this.overlay.group == "") {
          for (var i = 0; i < this.inputs.length; i++) {
            if (this.inputs[i].id == this.overlay.id) {
              this.inputs[i].active = false;
              switch (this.inputs[i].type) {
                case "text":
                  this.inputs.push({
                    id: ++this.counter,
                    x: this.inputs[i].x + 10,
                    y: this.inputs[i].y + 10,
                    h: this.inputs[i].h,
                    w: this.inputs[i].w,
                    active: true,
                    state: this.inputs[i].state,
                    hide: this.inputs[i].hide,
                    grid: this.inputs[i].grid,
                    filter: false,
                    type: "text",
                    data: null,
                    autoSource: "",
                    source: this.inputs[i].source,
                    z: this.counter,
                    title: "text " + this.counter,
                    link: this.inputs[i].link,
                    group: "",
                    style: {
                      opacity: this.inputs[i].style.opacity,
                      rotate: this.inputs[i].style.rotate,
                      font: this.inputs[i].style.font,
                      color: this.inputs[i].style.color,
                      size: this.inputs[i].style.size,
                      time: this.time,
                      transition: this.inputs[i].style.transition,
                      blink: this.inputs[i].style.blink,
                      align: this.inputs[i].style.align,
                      group: this.inputs[i].style.group,
                    },
                  });
                  break;
                case "image":
                  this.inputs.push({
                    id: ++this.counter,
                    x: this.inputs[i].x + 10,
                    y: this.inputs[i].y + 10,
                    h: this.inputs[i].h,
                    w: this.inputs[i].w,
                    state: this.inputs[i].state,
                    grid: this.inputs[i].grid,
                    filter: false,
                    hide: this.inputs[i].hide,
                    source: this.inputs[i].source,
                    z: this.counter,
                    active: true,
                    type: "image",
                    group: "",
                    title: "image " + this.counter,
                    link: this.inputs[i].link,
                    banPick: "",
                    style: {
                      opacity: this.inputs[i].style.opacity,
                      rotate: this.inputs[i].style.rotate,
                      time: this.time,
                      transition: this.inputs[i].style.transition,
                      transitionIn: this.inputs[i].style.transitionIn,
                      transitionOut: this.inputs[i].style.transitionOut,
                      delay: this.inputs[i].style.delay,
                      delayIn: this.inputs[i].style.delayIn,
                      blink: this.inputs[i].style.blink,
                      type: this.inputs[i].style.type,
                      radius: this.inputs[i].style.radius,
                      swap: this.inputs[i].style.swap,
                      swapState: this.inputs[i].style.swapState,
                      gray: this.inputs[i].style.gray,
                      group: this.inputs[i].style.group,
                      animation: this.inputs[i].style.animation,
                      introSource: this.inputs[i].style.introSource,
                      state: this.inputs[i].style.state,
                    },
                    heroState: {
                      pickCount: 0,
                      banCount: 0,
                      winRate: 0,
                      kill: 0,
                      death: 0,
                      assist: 0,
                    },
                  });
                  break;
                case "counter":
                  this.inputs.push({
                    id: ++this.counter,
                    x: this.inputs[i].x + 10,
                    y: this.inputs[i].y + 10,
                    h: this.inputs[i].h,
                    w: this.inputs[i].w,
                    filter: false,
                    grid: this.inputs[i].grid,
                    hide: this.inputs[i].hide,
                    state: this.inputs[i].state,
                    time: this.inputs[i].time,
                    totalSeconds: this.inputs[i].totalSeconds,
                    hours: this.inputs[i].hours,
                    minutes: this.inputs[i].minutes,
                    seconds: this.inputs[i].seconds,
                    message: this.inputs[i].message,
                    source: this.inputs[i].source,
                    z: this.counter,
                    active: true,
                    clock: this.inputs[i].clock,
                    type: "counter",
                    group: "",
                    title: "counter " + this.counter,
                    style: {
                      opacity: this.inputs[i].style.opacity,
                      rotate: this.inputs[i].style.rotate,
                      font: this.inputs[i].style.font,
                      color: this.inputs[i].style.color,
                      size: this.inputs[i].style.size,
                      time: this.time,
                      transition: this.inputs[i].style.transition,
                      blink: this.inputs[i].style.blink,
                      group: this.inputs[i].style.group,
                    },
                  });
                  break;
                case "video":
                  this.inputs.push({
                    id: ++this.counter,
                    x: this.inputs[i].x + 10,
                    y: this.inputs[i].y + 10,
                    h: this.inputs[i].h,
                    w: this.inputs[i].w,
                    loop: this.inputs[i].loop,
                    autoplay: this.inputs[i].autoplay,
                    muted: this.inputs[i].muted,
                    filter: false,
                    grid: this.inputs[i].grid,
                    hide: this.inputs[i].hide,
                    state: this.inputs[i].state,
                    source: this.inputs[i].source,
                    z: this.counter,
                    active: true,
                    type: "video",
                    group: "",
                    title: "video " + this.counter,
                    style: {
                      opacity: this.inputs[i].style.opacity,
                      rotate: this.inputs[i].style.rotate,
                      type: this.inputs[i].style.type,
                      time: this.time,
                      transition: this.inputs[i].style.transition,
                      transitionIn: this.inputs[i].style.transitionIn,
                      transitionOut: this.inputs[i].style.transitionOut,
                      delay: this.inputs[i].style.delay,
                      delayIn: this.inputs[i].style.delayIn,
                      blink: this.inputs[i].style.blink,
                      swap: this.inputs[i].style.swap,
                      swapState: this.inputs[i].style.swapState,
                      group: this.inputs[i].style.group,
                      animation: this.inputs[i].style.animation,
                      introSource: this.inputs[i].style.introSource,
                      state: this.inputs[i].style.state,
                    },
                    heroState: {
                      pickCount: 0,
                      banCount: 0,
                      winRate: 0,
                      kill: 0,
                      death: 0,
                      assist: 0,
                    },
                  });
                  break;
                case "graph":
                  this.inputs.push({
                    id: ++this.counter,
                    x: this.inputs[i].x + 10,
                    y: this.inputs[i].y + 10,
                    h: this.inputs[i].h,
                    w: this.inputs[i].w,
                    active: true,
                    state: this.inputs[i].state,
                    hide: this.inputs[i].hide,
                    grid: this.inputs[i].grid,
                    filter: this.inputs[i].filter,
                    type: "graph",
                    source: this.inputs[i].source,
                    title: "graph " + this.counter,
                    link: this.inputs[i].link,
                    banPick: "",
                    group: "",
                    style: {
                      percent: this.inputs[i].style.percent,
                      opacity: this.inputs[i].style.opacity,
                      rotate: this.inputs[i].style.rotate,
                      time: this.time,
                      color: this.inputs[i].style.color,
                      align: this.inputs[i].style.align,
                    },
                  });
                  break;
              }
              console.log(this.inputs[i]);
            }
          }
        } else {
          for (var i = 0; i < this.inputs.length; i++) {
            if (this.inputs[i].id == this.overlay.group) {
              for (var j = 0; j < this.inputs[i].element.length; j++) {
                if (this.inputs[i].element[j].id == this.overlay.id) {
                  this.inputs[i].element[j].active = false;

                  if (this.inputs[i].element[j].type == "text") {
                    this.inputs.push({
                      id: ++this.counter,
                      x: this.inputs[i].element[j].x + 10,
                      y: this.inputs[i].element[j].y + 10,
                      h: this.inputs[i].element[j].h,
                      w: this.inputs[i].element[j].w,
                      active: true,
                      state: this.inputs[i].element[j].state,
                      hide: this.inputs[i].element[j].hide,
                      filter: false,
                      grid: this.inputs[i].element[j].grid,
                      type: "text",
                      source: this.inputs[i].element[j].source,
                      data: null,
                      autoSource: "",
                      z: this.counter,
                      title: "text " + this.counter,
                      group: "",
                      link: this.inputs[i].element[j].link,
                      style: {
                        opacity: this.inputs[i].element[j].style.opacity,
                        rotate: this.inputs[i].element[j].style.rotate,
                        font: this.inputs[i].element[j].style.font,
                        color: this.inputs[i].element[j].style.color,
                        size: this.inputs[i].element[j].style.size,
                        time: this.time,
                        transition: this.inputs[i].element[j].style.transition,
                        blink: this.inputs[i].element[j].style.blink,
                        group: this.inputs[i].element[j].style.group,
                      },
                    });
                  } else if (this.inputs[i].element[j].type == "image") {
                    this.inputs.push({
                      id: ++this.counter,
                      x: this.inputs[i].element[j].x + 10,
                      y: this.inputs[i].element[j].y + 10,
                      h: this.inputs[i].element[j].h,
                      w: this.inputs[i].element[j].w,
                      state: this.inputs[i].element[j].state,
                      filter: false,
                      grid: this.inputs[i].element[j].grid,
                      hide: this.inputs[i].element[j].hide,
                      source: this.inputs[i].element[j].source,
                      link: this.inputs[i].element[j].link,
                      z: this.counter,
                      active: true,
                      type: "image",
                      group: "",
                      title: "image " + this.counter,
                      banPick: "",
                      style: {
                        opacity: this.inputs[i].element[j].style.opacity,
                        rotate: this.inputs[i].element[j].style.rotate,
                        time: this.time,
                        delay: this.inputs[i].element[j].style.delay,
                        delayIn: this.inputs[i].element[j].style.delayIn,
                        transition: this.inputs[i].element[j].style.transition,
                        transitionIn: this.inputs[i].element[j].style.transitionIn,
                        transitionOut: this.inputs[i].element[j].style.transitionOut,
                        blink: this.inputs[i].element[j].style.blink,
                        type: this.inputs[i].element[j].style.type,
                        radius: this.inputs[i].element[j].style.radius,
                        swap: this.inputs[i].element[j].style.swap,
                        swapState: this.inputs[i].element[j].style.swapState,
                        group: this.inputs[i].element[j].style.group,
                        gray: this.inputs[i].element[j].style.gray,
                        animation: this.inputs[i].element[j].style.animation,
                        introSource: this.inputs[i].element[j].style.introSource,
                        state: this.inputs[i].element[j].style.state,
                      },
                      heroState: {
                        pickCount: 0,
                        banCount: 0,
                        winRate: 0,
                        kill: 0,
                        death: 0,
                        assist: 0,
                      }
                    });
                  } else if (this.inputs[i].element[j].type == "video") {
                    this.inputs.push({
                      id: ++this.counter,
                      x: this.inputs[i].element[j].x + 10,
                      y: this.inputs[i].element[j].y + 10,
                      h: this.inputs[i].element[j].h,
                      w: this.inputs[i].element[j].w,
                      loop: this.inputs[i].element[j].loop,
                      autoplay: this.inputs[i].element[j].autoplay,
                      muted: this.inputs[i].element[j].muted,
                      filter: false,
                      grid: this.inputs[i].element[j].grid,
                      hide: this.inputs[i].element[j].hide,
                      state: this.inputs[i].element[j].state,
                      source: this.inputs[i].element[j].source,
                      z: this.counter,
                      active: true,
                      type: "video",
                      group: "",
                      title: "video " + this.counter,
                      style: {
                        opacity: this.inputs[i].element[j].style.opacity,
                        rotate: this.inputs[i].element[j].style.rotate,
                        time: this.time,
                        transition: this.inputs[i].element[j].style.transition,
                        blink: this.inputs[i].element[j].style.blink,
                        delay: this.inputs[i].element[j].style.delay,
                        delayIn: this.inputs[i].element[j].style.delayIn,
                        transitionIn: this.inputs[i].element[j].style.transitionIn,
                        transitionOut: this.inputs[i].element[j].style.transitionOut,
                        swap: this.inputs[i].element[j].style.swap,
                        swapState: this.inputs[i].element[j].style.swapState,
                        group: this.inputs[i].element[j].style.group,
                        type: this.inputs[i].element[j].style.type,
                        animation: this.inputs[i].element[j].style.animation,
                        introSource: this.inputs[i].element[j].style.introSource,
                        state: this.inputs[i].element[j].style.state,
                      },
                      heroState: {
                        pickCount: 0,
                        banCount: 0,
                        winRate: 0,
                        kill: 0,
                        death: 0,
                        assist: 0,
                      }
                    });
                  } else if (this.inputs[i].element[j].type == "counter") {
                    this.inputs.push({
                      id: ++this.counter,
                      x: this.inputs[i].element[j].x + 10,
                      y: this.inputs[i].element[j].y + 10,
                      h: this.inputs[i].element[j].h,
                      w: this.inputs[i].element[j].w,
                      grid: this.inputs[i].element[j].grid,
                      hide: this.inputs[i].element[j].hide,
                      filter: false,
                      state: this.inputs[i].element[j].state,
                      time: this.inputs[i].element[j].time,
                      totalSeconds: this.inputs[i].element[j].totalSeconds,
                      hours: this.inputs[i].element[j].hours,
                      minutes: this.inputs[i].element[j].minutes,
                      seconds: this.inputs[i].element[j].seconds,
                      message: this.inputs[i].element[j].message,
                      source: this.inputs[i].element[j].source,
                      clock: this.inputs[i].element[j].clock,
                      z: this.counter,
                      active: true,
                      type: "counter",
                      group: "",
                      title: "counter " + this.counter,
                      style: {
                        opacity: this.inputs[i].element[j].style.opacity,
                        rotate: this.inputs[i].element[j].style.rotate,
                        font: this.inputs[i].element[j].style.font,
                        color: this.inputs[i].element[j].style.color,
                        size: this.inputs[i].element[j].style.size,
                        time: this.time,
                        transition: this.inputs[i].element[j].style.transition,
                        group: this.inputs[i].element[j].style.group,
                        blink: this.inputs[i].element[j].style.blink,
                      },
                    });
                  }
                  else if (this.inputs[i].element[j].type == "graph") {
                    this.inputs.push({
                      id: ++this.counter,
                      x: this.inputs[i].element[j].x + 10,
                      y: this.inputs[i].element[j].y + 10,
                      h: this.inputs[i].element[j].h,
                      w: this.inputs[i].element[j].w,
                      active: true,
                      state: this.inputs[i].element[j].state,
                      hide: this.inputs[i].element[j].hide,
                      grid: this.inputs[i].element[j].grid,
                      filter: this.inputs[i].element[j].filter,
                      type: "graph",
                      source: this.inputs[i].element[j].source,
                      title: "graph " + this.counter,
                      link: this.inputs[i].element[j].link,
                      banPick: "",
                      group: "",
                      style: {
                        percent: this.inputs[i].element[j].style.percent,
                        opacity: this.inputs[i].element[j].style.opacity,
                        rotate: this.inputs[i].element[j].style.rotate,
                        time: this.time,
                        color: this.inputs[i].element[j].style.color,
                        align: this.inputs[i].element[j].style.align,
                      },
                    });
                  }
                }
              }
            }
          }
        }
        for (var i = 0; i < this.inputs.length; i++) {
          if (this.inputs[i].id == this.counter) {
            this.LoopAddUpdate(this.inputs[i]);

            this.inputs[i].active = true;
            this.overlay = this.inputs[i];
            this.x = this.inputs[i].x;
            this.y = this.inputs[i].y;
            this.w = this.inputs[i].w;
            this.h = this.inputs[i].h;
            this.opacity = this.inputs[i].style.opacity;
          }
        }
      }

      if (this.groupOverlay != "") {
        if (this.groupOverlay.length == 1) {
          var arrayChild = [];
          for (var j = 0; j < this.inputsAll.length; j++) {
            if (this.inputsAll[j].group == this.groupOverlay[0].id) {
              this.inputsAll[j].active = false;
              arrayChild.push(this.inputsAll[j]);
            }
          }
          for (var i = 0; i < this.inputs.length; i++) {
            if (this.inputs[i].id == this.groupOverlay[0].id) {
              this.inputs[i].active = false;
              var id = ++this.counter;
              this.inputs.push({
                id: id,
                title: "group " + this.counter,
                hide: this.inputs[i].hide,
                state: this.inputs[i].state,
                filter: this.inputs[i].filter,
                type: "group",
                active: true,
                element: [],
              });
            }
          }

          for (var j = 0; j < this.inputs.length; j++) {
            if (this.inputs[j].id == id) {
              for (var k = 0; k < arrayChild.length; k++) {
                if (arrayChild[k].type == "text") {
                  this.inputs[j].element.push({
                    id: ++this.counter,
                    x: arrayChild[k].x + 10,
                    y: arrayChild[k].y + 10,
                    h: arrayChild[k].h,
                    w: arrayChild[k].w,
                    active: true,
                    state: arrayChild[k].state,
                    hide: arrayChild[k].hide,
                    filter: false,
                    grid: arrayChild[k].grid,
                    type: "text",
                    source: arrayChild[k].source,
                    z: this.counter,
                    title: "text " + this.counter,
                    data: null,
                    autoSource: "",
                    group: id,
                    link: arrayChild[k].link,
                    style: {
                      opacity: arrayChild[k].style.opacity,
                      rotate: arrayChild[k].style.rotate,
                      font: arrayChild[k].style.font,
                      color: arrayChild[k].style.color,
                      size: arrayChild[k].style.size,
                      time: this.time + this.counter,
                      transition: arrayChild[k].style.transition,
                      blink: arrayChild[k].style.blink,
                      group: arrayChild[k].style.group,
                    },
                  });
                } else if (arrayChild[k].type == "image") {
                  this.inputs[j].element.push({
                    id: ++this.counter,
                    x: arrayChild[k].x + 10,
                    y: arrayChild[k].y + 10,
                    h: arrayChild[k].h,
                    w: arrayChild[k].w,
                    state: arrayChild[k].state,
                    grid: arrayChild[k].grid,
                    hide: arrayChild[k].hide,
                    filter: false,
                    source: arrayChild[k].source,
                    link: arrayChild[k].link,
                    z: this.counter,
                    banPick: "",
                    active: true,
                    type: "image",
                    group: id,
                    title: "image " + this.counter,
                    style: {
                      opacity: arrayChild[k].style.opacity,
                      rotate: arrayChild[k].style.rotate,
                      time: this.time + this.counter,
                      transition: arrayChild[k].style.transition,
                      transitionIn: arrayChild[k].style.transitionIn,
                      transitionOut: arrayChild[k].style.transitionOut,
                      blink: arrayChild[k].style.blink,
                      type: arrayChild[k].style.type,
                      radius: arrayChild[k].style.radius,
                      delay: arrayChild[k].style.delay,
                      delayIn: arrayChild[k].style.delayIn,
                      swap: arrayChild[k].style.swap,
                      swapState: arrayChild[k].style.swapState,
                      group: arrayChild[k].style.group,
                      gray: arrayChild[k].style.gray,
                      animation: arrayChild[k].style.animation,
                      introSource: arrayChild[k].style.introSource,
                      state: arrayChild[k].style.state,
                    },
                    heroState: {
                      pickCount: 0,
                      banCount: 0,
                      winRate: 0,
                      kill: 0,
                      death: 0,
                      assist: 0,
                    }
                  });
                } else if (arrayChild[k].type == "video") {
                  this.inputs[j].element.push({
                    id: ++this.counter,
                    x: arrayChild[k].x + 10,
                    y: arrayChild[k].y + 10,
                    h: arrayChild[k].h,
                    w: arrayChild[k].w,
                    loop: arrayChild[k].loop,
                    autoplay: arrayChild[k].autoplay,
                    muted: arrayChild[k].muted,
                    filter: false,
                    grid: arrayChild[k].grid,
                    hide: arrayChild[k].hide,
                    state: arrayChild[k].state,
                    source: arrayChild[k].source,
                    z: this.counter,
                    active: true,
                    type: "video",
                    group: id,
                    title: "video " + this.counter,
                    style: {
                      opacity: arrayChild[k].style.opacity,
                      rotate: arrayChild[k].style.rotate,
                      time: this.time + this.counter,
                      transition: arrayChild[k].style.transition,
                      blink: arrayChild[k].style.blink,
                      transitionIn: arrayChild[k].style.transitionIn,
                      transitionOut: arrayChild[k].style.transitionOut,
                      delay: arrayChild[k].style.delay,
                      delayIn: arrayChild[k].style.delayIn,
                      swap: arrayChild[k].style.swap,
                      swapState: arrayChild[k].style.swapState,
                      group: arrayChild[k].style.group,
                      type: arrayChild[k].style.type,
                      animation: arrayChild[k].style.animation,
                      introSource: arrayChild[k].style.introSource,
                      state: arrayChild[k].style.state,
                    },
                    heroState: {
                      pickCount: 0,
                      banCount: 0,
                      winRate: 0,
                      kill: 0,
                      death: 0,
                      assist: 0,
                    }
                  });
                } else if (arrayChild[k].type == "counter") {
                  this.inputs[j].element.push({
                    id: ++this.counter,
                    x: arrayChild[k].x + 10,
                    y: arrayChild[k].y + 10,
                    h: arrayChild[k].h,
                    w: arrayChild[k].w,
                    filter: false,
                    grid: arrayChild[k].grid,
                    hide: arrayChild[k].hide,
                    state: arrayChild[k].state,
                    time: arrayChild[k].time,
                    totalSeconds: arrayChild[k].totalSeconds,
                    hours: arrayChild[k].hours,
                    minutes: arrayChild[k].minutes,
                    seconds: arrayChild[k].seconds,
                    message: arrayChild[k].message,
                    source: arrayChild[k].source,
                    clock: arrayChild[k].clock,
                    z: this.counter,
                    active: true,
                    type: "counter",
                    group: id,
                    title: "counter " + this.counter,
                    style: {
                      opacity: arrayChild[k].style.opacity,
                      rotate: arrayChild[k].style.rotate,
                      font: arrayChild[k].style.font,
                      color: arrayChild[k].style.color,
                      size: arrayChild[k].style.size,
                      time: this.time + this.counter,
                      transition: arrayChild[k].style.transition,
                      group: arrayChild[k].style.group,
                      blink: arrayChild[k].style.blink,
                    },
                  });
                }
                else if (arrayChild[k].type == "graph") {
                  this.inputs[j].element.push({
                    id: ++this.counter,
                    x: arrayChild[k].x + 10,
                    y: arrayChild[k].y + 10,
                    h: arrayChild[k].h,
                    w: arrayChild[k].w,
                    active: true,
                    state: arrayChild[k].state,
                    hide: arrayChild[k].hide,
                    grid: arrayChild[k].grid,
                    filter: arrayChild[k].filter,
                    type: "graph",
                    source: arrayChild[k].source,
                    title: "graph " + this.counter,
                    link: arrayChild[k].link,
                    banPick: "",
                    group: id,
                    style: {
                      percent: arrayChild[k].style.percent,
                      opacity: arrayChild[k].style.opacity,
                      rotate: arrayChild[k].style.rotate,
                      time: this.time,
                      color: arrayChild[k].style.color,
                      align: arrayChild[k].style.align,
                    },
                  });
                }
                this.deact();
              }
            }
          }
        } else {
          for (var i = 0; i < this.inputs.length; i++) {
            for (var j = 0; j < this.groupOverlay.length; j++) {
              if (this.inputs[i].id == this.groupOverlay[j].id) {
                this.inputs[i].active = false;
                if (this.inputs[i].type == "text") {
                  this.inputs.push({
                    id: ++this.counter,
                    x: this.inputs[i].x + 10,
                    y: this.inputs[i].y + 10,
                    h: this.inputs[i].h,
                    w: this.inputs[i].w,
                    active: true,
                    state: this.inputs[i].state,
                    hide: this.inputs[i].hide,
                    filter: false,
                    grid: this.inputs[i].grid,
                    type: "text",
                    source: this.inputs[i].source,
                    z: this.counter,
                    title: "text " + this.counter,
                    data: null,
                    autoSource: "",
                    group: "",
                    link: this.inputs[i].link,
                    style: {
                      opacity: this.inputs[i].style.opacity,
                      rotate: this.inputs[i].style.rotate,
                      font: this.inputs[i].style.font,
                      color: this.inputs[i].style.color,
                      size: this.inputs[i].style.size,
                      time: this.time,
                      transition: this.inputs[i].style.transition,
                      blink: this.inputs[i].style.blink,
                      group: this.inputs[i].style.group,
                    },
                  });
                } else if (this.inputs[i].type == "image") {
                  this.inputs.push({
                    id: ++this.counter,
                    x: this.inputs[i].x + 10,
                    y: this.inputs[i].y + 10,
                    h: this.inputs[i].h,
                    w: this.inputs[i].w,
                    state: this.inputs[i].state,
                    grid: this.inputs[i].grid,
                    filter: false,
                    hide: this.inputs[i].hide,
                    source: this.inputs[i].source,
                    link: this.inputs[i].link,
                    z: this.counter,
                    banPick: "",
                    active: true,
                    type: "image",
                    group: "",
                    title: "image " + this.counter,
                    style: {
                      opacity: this.inputs[i].style.opacity,
                      rotate: this.inputs[i].style.rotate,
                      time: this.time,
                      transition: this.inputs[i].style.transition,
                      transitionIn: this.inputs[i].style.transitionIn,
                      transitionOut: this.inputs[i].style.transitionOut,
                      blink: this.inputs[i].style.blink,
                      delay: this.inputs[i].style.delay,
                      delayIn: this.inputs[i].style.delayIn,
                      type: this.inputs[i].style.type,
                      radius: this.inputs[i].style.radius,
                      swap: this.inputs[i].style.swap,
                      swapState: this.inputs[i].style.swapState,
                      group: this.inputs[i].style.group,
                      gray: this.inputs[i].style.gray,
                      animation: this.inputs[i].style.animation,
                      introSource: this.inputs[i].style.introSource,
                      state: this.inputs[i].style.state,
                    },
                    heroState: {
                      pickCount: 0,
                      banCount: 0,
                      winRate: 0,
                      kill: 0,
                      death: 0,
                      assist: 0,
                    }
                  });
                } else if (this.inputs[i].type == "video") {
                  this.inputs.push({
                    id: ++this.counter,
                    x: this.inputs[i].x + 10,
                    y: this.inputs[i].y + 10,
                    h: this.inputs[i].h,
                    w: this.inputs[i].w,
                    loop: this.inputs[i].loop,
                    autoplay: this.inputs[i].autoplay,
                    muted: this.inputs[i].muted,
                    grid: this.inputs[i].grid,
                    hide: this.inputs[i].hide,
                    state: this.inputs[i].state,
                    source: this.inputs[i].source,
                    z: this.counter,
                    filter: false,
                    active: true,
                    type: "video",
                    group: "",
                    title: "video " + this.counter,
                    style: {
                      opacity: this.inputs[i].style.opacity,
                      rotate: this.inputs[i].style.rotate,
                      time: this.time,
                      transition: this.inputs[i].style.transition,
                      blink: this.inputs[i].style.blink,
                      transitionIn: this.inputs[i].style.transitionIn,
                      transitionOut: this.inputs[i].style.transitionOut,
                      delay: this.inputs[i].style.delay,
                      delayIn: this.inputs[i].style.delayIn,
                      swap: this.inputs[i].style.swap,
                      swapState: this.inputs[i].style.swapState,
                      group: this.inputs[i].style.group,
                      type: this.inputs[i].style.type,
                      animation: this.inputs[i].style.animation,
                      introSource: this.inputs[i].style.introSource,
                      state: this.inputs[i].style.state,
                    },
                    heroState: {
                      pickCount: 0,
                      banCount: 0,
                      winRate: 0,
                      kill: 0,
                      death: 0,
                      assist: 0,
                    }
                  });
                } else if (this.inputs[i].type == "counter") {
                  this.inputs.push({
                    id: ++this.counter,
                    x: this.inputs[i].x + 10,
                    y: this.inputs[i].y + 10,
                    h: this.inputs[i].h,
                    w: this.inputs[i].w,
                    grid: this.inputs[i].grid,
                    filter: false,
                    hide: this.inputs[i].hide,
                    state: this.inputs[i].state,
                    time: this.inputs[i].time,
                    totalSeconds: this.inputs[i].totalSeconds,
                    hours: this.inputs[i].hours,
                    minutes: this.inputs[i].minutes,
                    seconds: this.inputs[i].seconds,
                    message: this.inputs[i].message,
                    source: this.inputs[i].source,
                    clock: this.inputs[i].clock,
                    z: this.counter,
                    active: true,
                    type: "counter",
                    group: "",
                    title: "counter " + this.counter,
                    style: {
                      opacity: this.inputs[i].style.opacity,
                      rotate: this.inputs[i].style.rotate,
                      font: this.inputs[i].style.font,
                      color: this.inputs[i].style.color,
                      size: this.inputs[i].style.size,
                      time: this.time,
                      transition: this.inputs[i].style.transition,
                      group: this.inputs[i].style.group,
                      blink: this.inputs[i].style.blink,
                    },
                  });
                }
                else if (this.inputs[i].type == "graph") {
                  this.inputs.push({
                    id: ++this.counter,
                    x: this.inputs[i].x + 10,
                    y: this.inputs[i].y + 10,
                    h: this.inputs[i].h,
                    w: this.inputs[i].w,
                    active: true,
                    state: this.inputs[i].state,
                    hide: this.inputs[i].hide,
                    grid: this.inputs[i].grid,
                    filter: this.inputs[i].filter,
                    type: "graph",
                    source: this.inputs[i].source,
                    title: "graph " + this.counter,
                    link: this.inputs[i].link,
                    banPick: "",
                    group: "",
                    style: {
                      percent: this.inputs[i].style.percent,
                      opacity: this.inputs[i].style.opacity,
                      rotate: this.inputs[i].style.rotate,
                      time: this.time,
                      color: this.inputs[i].style.color,
                      align: this.inputs[i].style.align,
                    },
                  });
                }
              }
            }
          }
        }
      }
    },

    groupCom() {
      if (this.overlay != "") {
        ++this.counter;
        var array = [];
        var id = [];
        for (var i = 0; i < this.inputs.length; i++) {
          if (this.inputs[i].active == true) {
            this.inputs[i].group = this.counter;
            this.inputs[i].style.time = this.time + this.counter;
            this.inputs[i].active = false;
            this.inputs[i].filter = false;
            id.push(this.inputs[i].id);
            array.push(this.inputs[i]);
          }
        }
        this.inputs.push({
          id: this.counter,
          title: "group " + this.counter,
          hide: false,
          state: true,
          type: "group",
          filter: false,
          active: false,
          element: array,
        });
        for (var j = 0; j < this.inputs.length; j++) {
          if (this.inputs[j].id == this.counter) {
            this.LoopAddUpdate(this.inputs[j]);
          }
        }
        var deleteId = {
          type: "delete",
          array: id,
          id: ''
        };
        this.LoopAddUpdate(deleteId);
        for (var i = 0; i < this.inputs.length; i++) {
          for (var j = 0; j < id.length; j++) {
            if (this.inputs[i].id == id[j]) {
              this.inputs.splice(
                this.inputs.findIndex((p) => p.id == this.inputs[i].id),
                1
              );
            }
          }
        }
      }
      if (this.groupOverlay != "") {
        var count = 0,
          groupId = "",
          id = [],
          array = [];
        for (var i = 0; i < this.groupOverlay.length; i++) {
          if (this.groupOverlay[i].type == "group") {
            count++;
            groupId = this.groupOverlay[i].id;
          } else {
            id.push(this.groupOverlay[i].id);
            array.push(this.groupOverlay[i]);
          }
        }
        if (count == 0) {
          ++this.counter;
          array.forEach((element, index) => {
            element.group = this.counter;
            element.style.time = this.time + this.counter + index;
            element.active = false;
            element.filter = false;
          });
          this.inputs.push({
            id: this.counter,
            title: "group " + this.counter,
            hide: false,
            state: true,
            filter: false,
            type: "group",
            active: false,
            element: array,
          });

          for (var j = 0; j < this.inputs.length; j++) {
            if (this.inputs[j].id == this.counter) {
              this.LoopAddUpdate(this.inputs[j]);
            }
          }
          var deleteId = {
            type: "delete",
            array: id,
            id: ''
          };
          this.LoopAddUpdate(deleteId);
          for (var i = 0; i < this.inputs.length; i++) {
            for (var j = 0; j < id.length; j++) {
              if (this.inputs[i].id == id[j]) {
                this.inputs.splice(
                  this.inputs.findIndex((p) => p.id == this.inputs[i].id),
                  1
                );
              }
            }
          }
        } else if (count == 1) {
          for (var k = 0; k < this.inputs.length; k++) {
            if (this.inputs[k].id == groupId) {
              for (var l = 0; l < array.length; l++) {
                array[l].group = groupId;
                array[l].style.time = this.time;
                this.inputs[k].element.push(array[l]);
                this.LoopAddUpdate(this.inputs[k]);
              }
            }
          }
          var deleteId = {
            type: "delete",
            array: id,
            id: ''
          };
          this.LoopAddUpdate(deleteId);
          for (var i = 0; i < this.inputs.length; i++) {
            for (var j = 0; j < id.length; j++) {
              if (this.inputs[i].id == id[j]) {
                this.inputs.splice(
                  this.inputs.findIndex((p) => p.id == this.inputs[i].id),
                  1
                );
              }
            }
          }
          this.deact();
        }
      }
    },

    unGroupCom() {
      if (this.overlay != "") {
        if (this.overlay.group != "") {
          var group = this.overlay.group;
          this.overlay.group = "";
          this.inputs.push(this.overlay);
          for (var i = 0; i < this.inputs.length; i++) {
            if (this.inputs[i].id == group) {
              if (this.inputs[i].element.length > 1) {
                this.LoopAddUpdate(this.inputs[i]);
                this.inputs[i].element.splice(
                  this.inputs[i].element.findIndex(
                    (p) => p.id == this.overlay.id
                  ),
                  1
                );
              } else {
                var id = [];
                id.push(group);
                var deleteId = {
                  type: "delete",
                  array: id,
                  id: ''
                };
                this.LoopAddUpdate(deleteId);

                this.inputs.splice(
                  this.inputs.findIndex((p) => p.id == group),
                  1
                );
              }
            }
          }
        }
      }

      if (this.groupOverlay != "") {
        var count = 0;
        var groupId = "";
        for (var i = 0; i < this.groupOverlay.length; i++) {
          if (this.groupOverlay[i].type == "group") {
            groupId = this.groupOverlay[i].id;
            count++;
          }
        }
        if (count == 1) {
          for (var j = 0; j < this.groupOverlay.length; j++) {
            for (var k = 0; k < this.groupOverlay[j].element.length; k++) {
              this.groupOverlay[j].element[k].group = "";
              this.inputs.push(this.groupOverlay[j].element[k]);
              this.LoopAddUpdate(this.groupOverlay[j].element[k]);
            }
          }
          var id = [];
          id.push(groupId);
          var deleteId = {
            type: "delete",
            array: id,
            id: ''
          };
          this.LoopAddUpdate(deleteId);
          this.inputs.splice(
            this.inputs.findIndex((p) => p.id == groupId),
            1
          );
        }
        this.deact();
      }
    },

    UpCom() {
      if (this.overlay != "") {
        if (this.overlay.type != "group") {
          if (this.overlay.group == "") {
            //a overlay
            var index = this.inputs.findIndex((x) => x.id === this.overlay.id);
            this.MoveArray(this.inputs, index - 1, index);
            if (index - 1 == 0) {
              this.showUp = false;
              this.showDown = true;
            } else {
              this.showUp = true;
              this.showDown = true;
            }
            var id = [];
            id.push(this.overlay);
            var moveId = {
              type: "move",
              array: id,
              id: this.time,
              index: index - 1
            };
            this.LoopAddUpdate(moveId);

          } else {
            for (var i = 0; i < this.inputs.length; i++) {
              if (this.inputs[i].id == this.overlay.group) {
                var index = this.inputs[i].element.findIndex(
                  (x) => x.id === this.overlay.id
                );
                this.MoveArray(this.inputs[i].element, index - 1, index);
                if (index - 1 == 0) {
                  this.showUp = false;
                  this.showDown = true;
                } else {
                  this.showUp = true;
                  this.showDown = true;
                }
              }
            }
          }
        }
      }
      if (this.groupOverlay != "") {
        if (this.groupOverlay.length == 1) {
          var index = this.inputs.findIndex(
            (x) => x.id === this.groupOverlay[0].id
          );
          this.MoveArray(this.inputs, index - 1, index);
          if (index - 1 == 0) {
            this.showUp = false;
            this.showDown = true;
          } else {
            this.showUp = true;
            this.showDown = true;
          }
        }
      }
    },

    DownCom() {
      if (this.overlay != "") {
        if (this.overlay.type != "group") {
          if (this.overlay.group == "") {
            var index = this.inputs.findIndex((x) => x.id === this.overlay.id);
            this.MoveArray(this.inputs, index + 1, index);
            if (index + 1 == this.inputs.length - 1) {
              this.showDown = false;
              this.showUp = true;
            } else {
              this.showDown = true;
              this.showUp = true;
            }
            var id = [];
            id.push(this.overlay);
            var moveId = {
              type: "move",
              array: id,
              id: this.time,
              index: index + 1
            };
            this.LoopAddUpdate(moveId);
          } else {
            for (var i = 0; i < this.inputs.length; i++) {
              if (this.inputs[i].id == this.overlay.group) {
                var index = this.inputs[i].element.findIndex(
                  (x) => x.id === this.overlay.id
                );
                this.MoveArray(this.inputs[i].element, index + 1, index);
                if (index + 1 == this.inputs[i].element.length - 1) {
                  this.showDown = false;
                  this.showUp = true;
                } else {
                  this.showDown = true;
                  this.showUp = true;
                }
              }
            }
          }
        }
      }
      if (this.groupOverlay != "") {
        if (this.groupOverlay.length == 1) {
          var index = this.inputs.findIndex(
            (x) => x.id === this.groupOverlay[0].id
          );
          this.MoveArray(this.inputs, index + 1, index);
          if (index + 1 == this.inputs.length - 1) {
            this.showDown = false;
            this.showUp = true;
          } else {
            this.showDown = true;
            this.showUp = true;
          }
        }
      }
    },

    MoveArray(array, to, from) {
      array.splice(to, 0, array.splice(from, 1)[0]);
    },

    ShowDialog(val) {
      this.btnClick = val;
      this.tabActive = val;
      this.fileDialog = true;
      this.GetFile(val);
    },

    ShowIntroDialog(val) {
      this.btnClick = val;
      this.tabActive = val;
      this.introDialog = true;
      this.GetFile(val);
    },

    async GetFile(val) {
      if (val == "allfile") {
        this.tabActive = "";
        var type = "";
      } else if (val == "image") {
        this.tabActive = "image";
        var type = "image";
      } else if (val == "video") {
        this.tabActive = "video";
        var type = "video";
      }
      else if (val == "item") {
        this.tabActive = "item";
        var type = "item";
      }

      var token = this.$store.state.token;
      var config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      var data = {
        type: type,
        id: this.decrypt(this.id)
      };
      await axios.post("get_files", data, config).then((resp) => {
        if (resp.data.status == "success") {
          if (val == "allfile") {
            this.files = resp.data.data;
          } else if (val == "image") {
            this.files = resp.data.data;
          } else if (val == "video") {
            this.files = resp.data.data;
          } else if (val == "item") {
            this.files = resp.data.data;
          }
        }
      }).catch((err) => {
        if (err.response.statusText == 'Unauthorized') {
          localStorage.removeItem('token');
          localStorage.removeItem('username');
          localStorage.removeItem("id");
          this.$router.push('login');
        } else {
          console.log(err.response.statusText);
        }
      });
    },

    async GetImage() {
      var token = this.$store.state.token;
      var config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      var data = {
        type: "image",
        id: this.decrypt(this.id)
      };
      await axios.post("get_all_images", data, config).then((resp) => {
        if (resp.data.status == "success") {
          this.imageList = resp.data.hero;
          this.imageHeadSquareList = resp.data.square;
          this.imageHeadWideList = resp.data.wide;
          this.imageHeadTallList = resp.data.tall;
          this.imageHead3x2List = resp.data.threeByTwo;
          this.imageItemList = resp.data.item;
          this.otherList = resp.data.other;
          this.videoList = resp.data.video;
          this.videoTallList = resp.data.videoTall;
        }
      }).catch((err) => {
        if (err.response.statusText == 'Unauthorized') {
          localStorage.removeItem('token');
          localStorage.removeItem('username');
          localStorage.removeItem("id");
          this.$router.push('login');
        } else {
          console.log(err.response.statusText);
        }
      });
    },

    async UploadImage(event) {
      this.file = event.files;
      var form = new FormData();
      this.file.forEach((element) => {
        form.append("file[]", element);
        form.append("idx", this.decrypt(this.id));
      });
      var token = this.$store.state.token;
      var config = {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'ultipart/form-data', },
      };
      await axios.post("store_file", form, config).then((resp) => {
        if (resp.data.status == "success") {
          this.tabActive = resp.data.data;
          this.GetFile(resp.data.data);
          // this.GetImage();
          this.Toast("success", "Saved", "");
        }
      }).catch((err) => {
        if (err.response.statusText == 'Unauthorized') {
          localStorage.removeItem('token');
          localStorage.removeItem('username');
          localStorage.removeItem("id");
          this.$router.push('login');
        } else {
          console.log(err.response.statusText);
        }
      });
    },

    ChangeText(id) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == id) {
          this.inputsAll[i].source = this.overlay.source;
          this.inputsAll[i].style.time = this.time;
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
        }
      }
    },

    ChangeFontSize(id) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == id) {
          this.inputsAll[i].style.size = this.overlay.style.size;
          this.inputsAll[i].style.time = this.time;
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
        }
      }
    },

    ChangeFont(id) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == id) {
          this.inputsAll[i].style.font = this.overlay.style.font;
          this.inputsAll[i].style.time = this.time;
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
        }
      }
    },

    ChangeColor(id) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == id) {
          this.inputsAll[i].style.color = this.overlay.style.color;
          this.inputsAll[i].style.time = this.time;
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
        }
      }
    },

    ChangeBackground(id) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == id) {
          this.inputsAll[i].style.background = this.overlay.style.background;
          this.inputsAll[i].style.time = this.time;
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
        }
      }
    },

    ChangeCheckbox(type) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == this.overlay.id) {
          if (type == "loop") {
            this.inputsAll[i].loop = this.overlay.loop;
          } else if (type == "mute") {
            this.inputsAll[i].muted = this.overlay.muted;
          }
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
        }
      }
    },

    ChangeBlink() {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == this.overlay.id) {
          this.inputsAll[i].style.blink = this.overlay.style.blink;
          this.inputsAll[i].style.time = this.time;
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }

        }
      }
    },

    ChangeAnimation() {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == this.overlay.id) {
          this.inputsAll[i].style.animation = this.overlay.style.animation;
          this.inputsAll[i].style.time = this.time;
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }

        }
      }
    },
    ChangeState() {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == this.overlay.id) {
          this.inputsAll[i].style.state = this.overlay.style.state;
          this.inputsAll[i].style.time = this.time;
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }

        }
      }
    },

    ChangeClock() {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == this.overlay.id) {
          this.inputsAll[i].clock = this.overlay.clock;
          this.inputsAll[i].style.time = this.time;
          this.inputsAll[i].op = '';
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }

        }
      }
    },

    ChangeGray() {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == this.overlay.id) {
          this.inputsAll[i].style.gray = this.overlay.style.gray;
          this.inputsAll[i].style.time = this.time;
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }

        }
      }
    },

    ChangeAuto() {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == this.overlay.id) {
          this.inputsAll[i].style.auto = this.overlay.style.auto;
          this.inputsAll[i].style.time = this.time;
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }

        }
      }
    },

    ChangeMessage(id) {
      var time = ["{{hours}}", "{{minutes}}", "{{seconds}}"];
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == id) {
          var text = this.overlay.message;
          var string = "";
          for (var j = 0; j < time.length; j++) {
            switch (text.includes(time[j])) {
              case true:
                string = text.replace(time[j], this.overlay.time.split(":")[j]);
                text = string;
                this.inputsAll[i].style.time = this.time;
                if (this.inputsAll[i].group != "") {
                  for (var j = 0; j < this.inputs.length; j++) {
                    if (this.inputs[j].id == this.inputsAll[i].group) {
                      this.LoopAddUpdate(this.inputs[j]);
                    }
                  }
                } else {
                  this.LoopAddUpdate(this.inputsAll[i]);
                }
                break;
            }
          }
          this.overlay.source = string;
        }
      }
    },

    ChangeTimer(value, type, id) {
      var overlay = this.inputsAll.find(x => x.id === id);
      if (overlay) {
        switch (type) {
          case "hours":
            overlay.hours = value;
            break
          case "minutes":
            overlay.minutes = value;
            break
          case "minutes":
            overlay.minutes = value;
            break
        }

        overlay.totalSeconds =
          overlay.hours * 3600 +
          overlay.minutes * 60 +
          overlay.seconds * 1;

        overlay.time =
          overlay.hours +
          ":" +
          overlay.minutes +
          ":" +
          overlay.seconds;
        this.ChangeMessage(id);
      }
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == id) {
          if (type == "hours") {
            this.inputsAll[i].hours = value;
          } else if (type == "minutes") {
            this.inputsAll[i].minutes = value;
          } else if (type == "seconds") {
            this.inputsAll[i].seconds = value;
          }
          this.inputsAll[i].totalSeconds =
            this.inputsAll[i].hours * 3600 +
            this.inputsAll[i].minutes * 60 +
            this.inputsAll[i].seconds * 1;

          this.inputsAll[i].time =
            this.inputsAll[i].hours +
            ":" +
            this.inputsAll[i].minutes +
            ":" +
            this.inputsAll[i].seconds;
          this.ChangeMessage(id);
        }
      }
    },

    ChangePos(id, val) {
      var overlay = this.inputsAll.find(x => x.id === id);
      if (overlay) {
        switch (val) {
          case "w":
            overlay.w = Number(this.w);
            this.AddToLog('Change Width', overlay.type);
            break
          case "h":
            overlay.h = Number(this.h);
            this.AddToLog('Change height', overlay.type);
            break
          case "x":
            overlay.x = Number(this.x);
            this.AddToLog('Change X', overlay.type);
            break
          case "y":
            overlay.y = Number(this.y);
            this.AddToLog('Change Y', overlay.type);
            break
        }
        if (overlay.group != "") {
          var group = this.inputs.find(x => x.id === overlay.group);
          if (group) this.LoopAddUpdate(group);
        } else {
          this.LoopAddUpdate(overlay);
        }
      }
    },

    ChangeDelay(id) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == id) {
          this.inputsAll[i].style.delay = this.inputsAll[i].style.delayIn;
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }

        }
      }
    },

    ChangeTitle(e, val, title) {
      e.target.setAttribute("contenteditable", true);
      e.target.focus();
      var self = this;
      e.target.addEventListener("keydown", (evt) => {
        if (evt.keyCode === 13) {
          evt.preventDefault();
          if (title == "main") {
            for (var i = 0; i < self.inputs.length; i++) {
              if (val.id == self.inputs[i].id) {
                self.inputs[i].title = e.target.innerText;
                self.LoopAddUpdate(self.inputs[i]);

                e.target.blur();
                e.target.setAttribute("contenteditable", false);
              }
            }
          } else {
            for (var i = 0; i < self.inputsAll.length; i++) {
              if (val.id == self.inputsAll[i].id) {
                self.inputsAll[i].title = e.target.innerText;
                if (self.inputsAll[i].group != "") {
                  for (var j = 0; j < self.inputs.length; j++) {
                    if (self.inputs[j].id == self.inputsAll[i].group) {
                      self.LoopAddUpdate(self.inputs[j]);
                    }
                  }
                } else {
                  self.LoopAddUpdate(self.inputsAll[i]);
                }

                e.target.blur();
                e.target.setAttribute("contenteditable", false);
              }
            }
          }
        }
      });
    },

    ChangeTransition(id, val, type) {
      if (type == "in") {
        for (var i = 0; i < this.inputsAll.length; i++) {
          if (this.inputsAll[i].id == id) {
            this.inputsAll[i].style.transition = val;
            this.inputsAll[i].style.transitionIn = val;
            this.inputsAll[i].style.time = this.time;
            if (this.inputsAll[i].group != "") {
              for (var j = 0; j < this.inputs.length; j++) {
                if (this.inputs[j].id == this.inputsAll[i].group) {
                  this.LoopAddUpdate(this.inputs[j]);
                }
              }
            } else {
              this.LoopAddUpdate(this.inputsAll[i]);
            }

          }
        }
      }
      else {
        for (var i = 0; i < this.inputsAll.length; i++) {
          if (this.inputsAll[i].id == id) {
            this.inputsAll[i].style.transition = val;
            this.inputsAll[i].style.transitionOut = val;
            this.inputsAll[i].style.time = this.time;
            if (this.inputsAll[i].group != "") {
              for (var j = 0; j < this.inputs.length; j++) {
                if (this.inputs[j].id == this.inputsAll[i].group) {
                  this.LoopAddUpdate(this.inputs[j]);
                }
              }
            } else {
              this.LoopAddUpdate(this.inputsAll[i]);
            }
            var self = this,
              m = i;

            setTimeout(function () {
              self.inputsAll[m].style.transition = self.inputsAll[m].style.transitionIn;
            }, 2000
            );
          }
        }
      }
    },

    SetFile(val) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == this.overlay.id) {
          this.inputsAll[i].source = val.file_name;
          this.overlay.source = val.file_name;
          this.inputsAll[i].style.time = this.time;
          this.fileDialog = false;
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
        }
      }
    },
    SetIntroFile(val) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].id == this.overlay.id) {
          this.inputsAll[i].style.introSource = val.file_name;
          this.overlay.style.introSource = val.file_name;
          this.inputsAll[i].style.time = this.time;
          this.introDialog = false;
          if (this.inputsAll[i].group != "") {
            for (var j = 0; j < this.inputs.length; j++) {
              if (this.inputs[j].id == this.inputsAll[i].group) {
                this.LoopAddUpdate(this.inputs[j]);
              }
            }
          } else {
            this.LoopAddUpdate(this.inputsAll[i]);
          }
        }
      }
    },

    DeleteFile(id) {
      var token = this.$store.state.token;
      var config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      var data = {
        id: id,
      };
      axios.post("delete_file", data, config).then((resp) => {
        if (resp.data.status == "success") {
          this.GetFile(this.tabActive);
          this.Toast("success", "Delete Success", "");
        }
      }).catch((err) => {
        if (err.response.statusText == 'Unauthorized') {
          localStorage.removeItem('token');
          localStorage.removeItem('username');
          localStorage.removeItem("id");
          this.$router.push('login');
        } else {
          console.log(err.response.statusText);
        }
      });
    },

    dragstop(id, group, val) {
      this.drag = false;
      if (group == "") {
        var overlay = this.inputs.find(x => x.id === id);
        if (overlay) {
          overlay.h = val.height;
          overlay.w = val.width;
          overlay.x = val.left;
          overlay.y = val.top;
          this.LoopAddUpdate(overlay);
        }
      } else {

        for (var j = 0; j < this.inputsAll.length; j++) {
          if (this.inputsAll[j].group == group && this.inputsAll[j].id == id) {
            this.inputsAll[j].h = val.height;
            this.inputsAll[j].w = val.width;
            this.inputsAll[j].x = val.left;
            this.inputsAll[j].y = val.top;
            if (this.inputsAll[j].group != "") {
              for (var k = 0; k < this.inputs.length; k++) {
                if (this.inputs[k].id == this.inputsAll[j].group) {
                  this.LoopAddUpdate(this.inputs[k]);
                }
              }
            } else {
              this.LoopAddUpdate(this.inputsAll[j]);
            }
          }
        }
      }

      this.draggingId = null;
      this.prevOffsetX = 0;
      this.prevOffsetY = 0;
    },

    resize(id, group, val) {
      if (this.drag) return;
      if (group == "") {
        for (var i = 0; i < this.inputs.length; i++) {
          if (this.inputs[i].id == id) {
            this.inputs[i].h = val.height;
            this.inputs[i].w = val.width;
            this.inputs[i].x = val.left;
            this.inputs[i].y = val.top;
            this.LoopAddUpdate(this.inputs[i]);

          }
        }
      } else {
        for (var j = 0; j < this.inputsAll.length; j++) {
          if (this.inputsAll[j].group == group && this.inputsAll[j].id == id) {
            this.inputsAll[j].h = val.height;
            this.inputsAll[j].w = val.width;
            this.inputsAll[j].x = val.left;
            this.inputsAll[j].y = val.top;
            if (this.inputsAll[j].group != "") {
              for (var k = 0; k < this.inputs.length; k++) {
                if (this.inputs[k].id == this.inputsAll[j].group) {
                  this.LoopAddUpdate(this.inputs[k]);
                }
              }
            } else {
              this.LoopAddUpdate(this.inputsAll[j]);
            }

          }
        }
      }
    },

    Toast(state, sum, det) {
      this.$toast.add({
        severity: state,
        summary: sum,
        detail: det,
        group: "tr",
        life: 500,
      });
    },

    encrypt(src) {
      const passphrase = "Overlay";
      return this.$CryptoJS.AES.encrypt(src, passphrase).toString();
    },

    decrypt(src) {
      if (src != null) {
        const passphrase = "Overlay";
        const bytes = this.$CryptoJS.AES.decrypt(src, passphrase);
        const originalText = bytes.toString(this.$CryptoJS.enc.Utf8);
        return originalText;
      } else {
        return src;
      }
    },

    containsObject(obj, list) {
      var i;
      for (i = 0; i < list.length; i++) {
        if (list[i].id === obj.id) {
          return true;
        }
      }

      return false;
    },

    LoopAddUpdate(overlay) {
      if (this.updateList != '') {
        if (this.containsObject(overlay, this.updateList)) {
          var index = this.updateList.findIndex((obj => obj.id == overlay.id));
          this.updateList[index] = overlay;
        }
        else {
          this.updateList.push(overlay);
        }
      } else {
        this.updateList.push(overlay);
      }
    },

    LoopAddUpdateLink(overlay) {
      if (this.updateLinkList != '') {
        if (this.containsObject(overlay, this.updateLinkList)) {
          var index = this.updateLinkList.findIndex((obj => obj.id == overlay.id));
          this.updateLinkList[index] = overlay;
        }
        else {
          this.updateLinkList.push(overlay);
        }
      } else {
        this.updateLinkList.push(overlay);
      }
    },

    AddToLog(message, type) {
      var current = new Date(),
        timestamp = current.getDate() + "/"
          + (current.getMonth() + 1) + "/"
          + current.getFullYear() + " "
          + current.getHours() + ":"
          + current.getMinutes() + ":"
          + current.getSeconds(),
        status = false;
      if (this.status == 1) status = true;
      var text = timestamp + "|" + message + "|" + type + "|" + this.layerName + "|" + status;
      this.log.push(text);
    },

    Merge(input) {
      let inputNGroup = input.filter((input) => input.type !== "group");
      let array = input.filter((input) => input.type === "group");
      let inputWGroup = [];
      array.forEach((element) => {
        element.element.forEach((el) => {
          inputWGroup.push(el);
        });
      });
      return inputNGroup.concat(inputWGroup);
    },
  },

  computed: {
    inputsAll() {
      if (this.inputs != "") {
        let inputNGroup = this.inputs.filter((input) => input.type !== "group");
        let array = this.inputs.filter((input) => input.type === "group");
        let inputWGroup = [];
        array.forEach((element) => {
          element.element.forEach((el) => {
            inputWGroup.push(el);
          });
        });
        return inputNGroup.concat(inputWGroup);
      }
      else {
        return [];
      }
    },
    listsAll() {
      if (this.inputs != "") {
        let inputNGroup = this.inputs.filter((input) => input.style.group > 0);
        return inputNGroup;
      }
      else {
        return [];
      }
    },

  },
};
</script>

<style lang="scss" scoped>
img.logo {
  object-fit: cover;
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
  cursor: pointer;
  //transition: .5s;

}

div.side-menu--active {
  background-color: rgba(187, 187, 187, 0.514);
}

div.background {
  // background-image: url(/public/screenshot.png);
  background-image: repeating-linear-gradient(#ccc 0 1px, transparent 1px 100%),
    repeating-linear-gradient(90deg, #ccc 0 1px, transparent 1px 100%);
  background-size: 20px 20px;
  scale: 0.9;
  transform-origin: top left;
}

div.empty_img {
  background-image: repeating-linear-gradient(#ccc 0 1px, transparent 1px 100%),
    repeating-linear-gradient(90deg, #ccc 0 1px, transparent 1px 100%);
}

button:disabled,
button[disabled] {
  color: #808080;
}

.p-button.p-component {
  width: 25%;
}

.nav.nav-tabs .nav-item .nav-link.active {
  border-bottom-color: #164e63;
  background: #164e6338;
}

.image-fit>video {
  position: absolute;
  height: 129.19px;
  width: 100%;
  // object-fit: cover;
}

.hide {
  opacity: 30% !important;
}

.line {
  text-decoration: line-through;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.rotate0 {
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.rotate90 {
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate180 {
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate270 {
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

#color1 {
  -webkit-appearance: none;
  padding: 0;
  border: none;
  border-radius: 20px;
  width: 40px;
  height: 40px;
}

#color1::-webkit-color-swatch {
  border: none;
  border-radius: 20px;
  padding: 0;
}

#color1::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 20px;
  padding: 0;
}

.overrides {
  --transition__duration: 1s;
  /* default: 2.5s */
  --transition__easing: linear;
  /* default: cubic-bezier(.25, 1, .30, 1) */
  --transition__delay: var(--delay);
  /* default: 0 */



}

.grayClass {
  filter: grayscale(100%);
}

.p-dropdown {
  width: 14rem;
}

.image-item {
  img {
    width: 17px;
    margin-right: 0.5rem;
  }
}

@keyframes blinkingBackground {
  0% {
    opacity: 80%;
  }

  25% {
    opacity: 10%;
  }

  50% {
    opacity: 80%;
  }

  75% {
    opacity: 10%;
  }

  100% {
    opacity: 80%;
  }
}

@keyframes blinkingCom {
  0% {
    opacity: 100%;
  }

  25% {
    opacity: 40%;
  }

  50% {
    opacity: 100%;
  }

  75% {
    opacity: 40%;
  }

  100% {
    opacity: 100%;
  }
}

.blinking {
  animation: blinkingBackground 2s infinite;
}

.comActive {
  animation: blinkingCom 2s infinite;

}

.image {
  background-color: rgba(187, 187, 187, 0.514) !important;
}

.nav.nav-tabs .nav-item .nav-link.active {
  border: 0px;
}

.percentage {
  background-color: var(--graph);
  width: var(--percents);
}


@for $i from 1 through 100 {
  .percentage-#{$i} {
    &:after {
      $value: (
        $i * 1%
      );
    width: $value;
  }
}
}

.move {
  animation-name: move;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes move {
  0% {
    width: 0%;
  }
}

@keyframes top-down {

  // 0% { transform: scale(1, 0.1); opacity: 0.1; }
  //0% { transform: scale(1, 0); opacity: 0; }
  //10% { transform: scale(1, 0.1); opacity: 0.2; }
  //20% { transform: scale(1, 0.2); opacity: 0.3; }
  //30% { transform: scale(1, 0.3); opacity: 0.4; }
  //40% { transform: scale(1, 0.4); opacity: 0.5; }
  //50% { transform: scale(1, 0.5); opacity: 0.6; }
  //60% { transform: scale(1, 0.6); opacity: 0.7; }
  //70% { transform: scale(1, 0.7); opacity: 0.8; }
  //80% { transform: scale(1, 0.8); opacity: 0.9; }
  //90% { transform: scale(1, 0.9); opacity: 1; }
  //100% { transform: scale(1, 1); opacity: 1; }
  from {
    // object-fit: cover; /* we can also use "none" here, it will give the same result */
    // padding: 10px;
    background-image: url(../assets/logo.png);
    // padding: 10px; /* control the thickness of the gradient "border" */
    // background: repeating-linear-gradient(45deg, #FF6B6B 0 10px, #4ECDC4 0 20px);
    mask:
      /* 1st layer */
      linear-gradient(#000 0 0),

      /* 2nd layer */
      linear-gradient(180deg, #000 50%, #0000 0) content-box 50% 50% / 200% 200% no-repeat,

      /* 3rd layer */
      linear-gradient(360deg, #000 50%, #0000 0) content-box 50% 50% / 200% 200% no-repeat;

    mask-composite: exclude, add;
    // background: #F07818;
  }

  // padding: 0;
  to {
    mask-position: 0% 0%, 100% 100%;
    // clip-path: circle(100% at 50% 50%);
    // background: #F07818;


  }
}

@keyframes left-right {

  // 0% { transform: scale(1, 0.1); opacity: 0.1; }
  0% {
    transform: scale(0, 1);
    opacity: 0;
  }

  10% {
    transform: scale(0.1, 1);
    opacity: 0.2;
  }

  20% {
    transform: scale(0.2, 1);
    opacity: 0.3;
  }

  30% {
    transform: scale(0.3, 1);
    opacity: 0.4;
  }

  40% {
    transform: scale(0.4, 1);
    opacity: 0.5;
  }

  50% {
    transform: scale(0.5, 1);
    opacity: 0.6;
  }

  60% {
    transform: scale(0.6, 1);
    opacity: 0.7;
  }

  70% {
    transform: scale(0.7, 1);
    opacity: 0.8;
  }

  80% {
    transform: scale(0.8, 1);
    opacity: 0.9;
  }

  90% {
    transform: scale(0.9, 1);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}


[transition-style="in:custom:left-right"] {
  --transition__duration: 1s;
  /* default: 2.5s */
  --transition__easing: linear;
  /* default: cubic-bezier(.25, 1, .30, 1) */
  --transition__delay: var(--delay);
  animation-name: left-right;
}

[transition-style="in:custom:top-down"] {
  --transition__duration: 2s;
  /* default: 2.5s */
  --transition__easing: linear;
  /* default: cubic-bezier(.25, 1, .30, 1) */
  --transition__delay: var(--delay);
  animation-name: top-down;
}
</style>